/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useState,useEffect} from "react";
import { FaCopy,FaKey,FaCog, FaFile, FaCodeBranch} from 'react-icons/fa';
import { TbRefresh } from 'react-icons/tb';
import {OverlayTrigger,Tooltip,Alert, Button} from "react-bootstrap";
import {Switch,FormControlLabel } from '@material-ui/core';
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import { useHistory } from "react-router-dom";
import UpgradeSubscriptionPage from "../../apiv3/UpgradeSubscriptionPage";
import moment from "moment-timezone";

export const ApiSettings = (props) => {
    // console.log(props.userinfo.id,"ApiSettingsApiSettings")
    const [value, setValue] = React.useState('');
    const [checked,setChecked] = React.useState();
    const [userdata,setUserData] = useState()
    const [credentailgen,setCredentialGen] = useState(false)
    const [showsubscription,setShowSubscription] = useState(false)
    useEffect(() => {
        if(props.userinfo){
            axios({
                method: "get",
                url: `${NODE_API_URL}/INTL/fetch-iframeorapi`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
            }).then(supreq => {
                if(supreq && supreq.data && supreq.data.data){
                    // setFeedLoader(false)
                    setUserData(supreq.data.data)
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    },[value])
    function handleChange(event) {
        // console.log(event.target.checked,"CHECKEDDDDD")
        axios({
            method: "POST",
            url: NODE_API_URL + "/INTL/onOff-Test-Mode",
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: {
              "test_mode":event.target.checked?"ON":"OFF"
            },
          }).then((res) => {
            if (res && res.data && res.data.success) {
                setValue(res.data.msg)
                if(res.data.msg ==="Test mode Deactivated"){
                    setChecked(false)
                }else{
                    setChecked(true)
                }
                
            }
          }).
          catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    const GenerateCredentails = () => {
        axios({
            method: "POST",
            url: NODE_API_URL + "/INTL/mail-ForTest-ModeAPI",
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
          }).then((res) => {
            if (res && res.data) {
                setValue(res.data)
                setCredentialGen(true)
            }
          }).
          catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    const GenerateLiveCredentails = () => {
        axios({
            method: "POST",
            url: NODE_API_URL + "/INTL/generate-NewToken",
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
          }).then((res) => {
            if (res && res.data) {
                setValue(res.data)
            }
          });
    }
    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        // console.log(result,"addDays")
        return result.toISOString();
      }
      function dateFormate(date){
        let formattedDate = "";
        if (date) {
            let dateObj = new Date(date);
            dateObj = new Date(dateObj.getTime() - (330 * 60 * 1000))
            const year = dateObj.getFullYear();
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObj.getDate()).slice(-2);
            const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            formattedDate = `${year}-${month}-${day} ${timeString}`;
        }
        return formattedDate;
    }
    const getActive = (date) => {
        let newdate = new Date().getTime()
        let userdate = new Date(date).getTime()
        let status = `<span class="label label-success label-pill label-inline px-5">Active</span>`
        if(userdate <= newdate){
            status = `<span class="label label-danger label-pill label-inline px-5">InActive</span>`
        }
        return status
    }
    const history = useHistory()
    return (
        <>
        {showsubscription?<UpgradeSubscriptionPage {...props}/>:<div className="row">
            {userdata?<>
            <div className="col-12 mb-4 setting-title">{props.language.SETTING_API_SETTING}</div>
            <div className="col-md-12 text-right">
                <button className="btn btn-light text-dark"  onClick={() => history.push({
                        pathname: "/diamondfeed"
                        // state: "apisetting",
                        // state: decompress(res.data.ProductDetails),
                    })}><FaCodeBranch className="font-size-h5 mr-2" />Rule Page</button>
                    {/* <button className="btn font-weight-bold btn-square btn-primary px-10 mr-2" onClick={() => setShowSubscription(true)}>Upgrade Plan</button> */}
            </div>
            <div className="col-12">
                {checked === true?<Alert variant="success">
                Test mode Activated, Click on generate credentials to generate test-mode API key and test-mode login.
                </Alert>:""}
                {checked === false?<Alert variant="danger">
                    Test mode Deactivated
                </Alert>:""}
                {credentailgen?<Alert variant="success">
                Test API key generated, and login credentials has been sent to your registered email id.
                </Alert>:""}
            </div>
            <div className="col-12 mt-5 mb-4 font-weight-bold"> <FaKey className="mr-2" /> {props.language.SETTING_API_KEYDETIAL} </div>
            <div className="col-12">
                <table className="table table-bordered">
                    <tr className="bg-gray-100">
                        <th className="text-center">{props.language.SETTING_API_CREATEDON}</th>
                        <th className="text-center">{props.language.SETTING_API_TYPE}</th>
                        <th className="text-center">{props.language.SETTING_API_KEY}</th>
                        <th className="text-center">{props.language.SETTING_API_STATUS}</th>
                        <th className="text-center">{props.language.SETTING_API_EXPIRY}</th>
                    </tr>
                    <tr className="">
                        <td className="text-center align-middle">
                            {userdata.api_key ?
                                // dateFormate(userdata.ap_created_date)
                                 userdata.ap_created_date ? moment.tz(userdata.ap_created_date, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"
                            :""}
                        </td>
                        <td className="text-center align-middle">Live</td>
                        <td className="text-center align-middle">
                            {userdata.api_live_mode === 1?<div>
                                {userdata.api_key?<>{userdata.api_key}<span className="ml-2 apiIcon">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Copy API
                                            </Tooltip>
                                        }
                                    >
                                        <FaCopy className="cursor-pointer"
                                        onClick={() => navigator.clipboard.writeText(userdata.api_key)}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Re-generate API
                                            </Tooltip>
                                        }
                                    >
                                        <TbRefresh className="ml-2 cursor-pointer"
                                        onClick={() => GenerateLiveCredentails()}/>
                                    </OverlayTrigger>
                                </span></>:<Button color="primary" onClick={() => GenerateLiveCredentails()}>Generate Credentials</Button>}
                                
                                
                           </div>:<span class="font-weight-bold">Once Testing is completed, Please contact to your sales administrator to Activate your Live API Key</span>}
                            
                            {/* <button className="btn btn-primary btn-sm">Generate API Key</button> */}
                            {/* <button className="btn btn-primary btn-sm">Generate Credentials</button> */}
                            {/* <div>
                                0b26c454d2722a85bf0f1e44d8cd34886d6928d8cd3fcfd42a93cdf76a91e16f
                                <span className="ml-2 apiIcon">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Copy API
                                            </Tooltip>
                                        }
                                    >
                                        <FaCopy className="cursor-pointer"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Re-generate API
                                            </Tooltip>
                                        }
                                    >
                                        <TbRefresh className="ml-2 cursor-pointer"/>
                                    </OverlayTrigger>
                                </span>
                           </div> */}
                        </td>
                        <td className="text-center align-middle">
                        {userdata.api_live_mode === 0?<span class="label label-danger label-pill label-inline px-5">InActive</span>:userdata.api_key?userdata.api === 0?<span class="label label-danger label-pill label-inline px-5">Expired</span>:<span class="label label-success label-pill label-inline px-5">Active</span>:<span class="label label-danger label-pill label-inline px-5">InActive</span>}

                            {/* <span class="label label-danger label-pill label-inline px-5">Expired</span> */}
                        </td>
                        <td className="text-center align-middle"></td>
                    </tr>
                    <tr>
                        <td className="text-center align-middle">
                            {userdata.api_test_key ? 
                            // dateFormate(userdata.aptest_created_date)
                            userdata.aptest_created_date ? moment.tz(userdata.aptest_created_date, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"
                            :""}
                        </td>
                        <td className="text-center align-middle">Test</td>
                        <td className="text-center align-middle">
                            {/* <button className="btn btn-primary btn-sm">Generate API Key</button> */}
                            {/* <button className="btn btn-primary btn-sm">Generate Credentials</button> */}
                           {userdata.api_test_mode === "ON"?<div>
                                {userdata.api_test_key?<>{userdata.api_test_key} <span className="ml-2 apiIcon">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Copy API
                                            </Tooltip>
                                        }
                                    >
                                        <FaCopy className="cursor-pointer"
                                        onClick={() => navigator.clipboard.writeText(userdata.api_test_key)}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Re-generate API
                                            </Tooltip>
                                        }
                                    >
                                        <TbRefresh className="ml-2 cursor-pointer"
                                        onClick={() => GenerateCredentails()}
                                        />
                                    </OverlayTrigger>
                                </span></>:<Button color="primary" onClick={() => GenerateCredentails()}>Generate Credentials</Button>}
                           </div>:""}
                        </td>
                        <td className="text-center align-middle">
                            {userdata.api_test_mode === "OFF"?<span class="label label-danger label-pill label-inline px-5">InActive</span>:userdata.api_test_key?userdata.api === 0?<span class="label label-danger label-pill label-inline px-5">Expired</span>:<div dangerouslySetInnerHTML={{__html:getActive(addDays(userdata.aptest_created_date,6))}}></div>:<span class="label label-danger label-pill label-inline px-5">InActive</span>}
                            {/* <span class="label label-success label-pill label-inline px-5">Active</span> */}
                            {/* <span class="label label-danger label-pill label-inline px-5">InActive</span> */}
                            {/* <span class="label label-danger label-pill label-inline px-5">Expired</span> */}
                        </td>
                        <td className="text-center align-middle">
                            {userdata.api_test_key ? 
                                // dateFormate(addDays(userdata.aptest_created_date,6))
                                userdata.aptest_created_date ? moment.tz(addDays(userdata.aptest_created_date,6), props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"
                            :""}
                        </td>
                    </tr>
                </table>
            </div>
            <div className="col-12 d-md-flex justify-content-between align-items-center mt-5">
                <span className="font-weight-bold"> <FaCog className="mr-2" /> {props.language.SETTING_API_KEYDETIAL}</span>
                <div className="">
                    <FormControlLabel
                        className='m-0'
                        labelPlacement="start"
                        // value={value}
                        // onChange={handleChange}
                        control={
                            <Switch
                            checked={userdata.api_test_mode === "ON"?true:false}
                            onClick={handleChange}
                            value={userdata.api_test_mode}
                                color="primary"
                            />
                        }
                        label={props.language.SETTING_API_ACTIVATE_TEST}
                    />
                </div>
            </div>
            {props.feedsetting.api_plan === "API_V2"?<div className="col-md-12 mb-3">
                {props.language.SETTING_API_MSG1} <a href={process.env.REACT_APP_ENV === "production"?"https://testapi.diamondsoncall.com":"https://testapi.diamondsoncall.com"} target="_blank" rel="noreferrer">{process.env.REACT_APP_ENV === "production"?"https://testapi.diamondsoncall.com":"https://testapi.diamondsoncall.com"}</a> {props.language.SETTING_API_MSG2}.<br />
                <a href={process.env.REACT_APP_ENV === "production"?"https://testmode.diamondsoncall.com":"https://testmode.diamondsoncall.com"} target="_blank" rel="noreferrer">{process.env.REACT_APP_ENV === "production"?"https://testmode.diamondsoncall.com":"https://testmode.diamondsoncall.com"}</a> {props.language.SETTING_API_MSG3} <br />{props.language.SETTING_API_MSG4}
            </div>:<div className="col-md-12 mb-3">
                {props.language.SETTING_API_MSG1} <a href={process.env.REACT_APP_ENV === "production"?"https://api.testrun3.diamondsoncall.com":"https://api.staging.testrun3.diamondsupload.com"} target="_blank" rel="noreferrer">{process.env.REACT_APP_ENV === "production"?"https://api.testrun3.diamondsoncall.com":"https://api.staging.testrun3.diamondsupload.com"}</a> {props.language.SETTING_API_MSG2}.<br />
                <a href={process.env.REACT_APP_ENV === "production"?"https://testrun3.diamondsoncall.com":"https://staging.testrun3.diamondsupload.com"} target="_blank" rel="noreferrer">{process.env.REACT_APP_ENV === "production"?"https://testrun3.diamondsoncall.com":"https://staging.testrun3.diamondsupload.com"}</a> {props.language.SETTING_API_MSG3} <br />{props.language.SETTING_API_MSG4}
            </div>}
            <div className="col-12 border-top mt-5 mb-4 font-weight-bold pt-3"> <FaFile className="mr-2"/> {props.language.SETTING_API_DOC} </div>
            <div className="col-12">
                {props.language.SETTING_API_MSG5}
            </div>
            <div className="col-12">
                {props.userinfo?.api_plan === "API_V2 " ?
                    <Link to={{ pathname:'/api-documentation' }}>{props.language.SETTING_API_MSG6}</Link>
                    :
                    <Link to={{ pathname:'/api-documentation-v3' }}>{props.language.SETTING_API_MSG6}</Link>
                }
            </div>
            {props.feedsetting.api_plan?<div className="col-12 font-weight-bold">
                Your Subscription is Active with <strong>{props.feedsetting.api_plan}</strong> Plan.
            </div>:<div className="col-12 font-weight-bold text-danger">
                Please Contact with your sales person for Upgrading Plans.
            </div>}
            </>:""}
        </div>}
        </>
    );
};
