import React ,{useEffect,useRef}from "react";
import { Field } from "formik";

export const Clarity = ({ language, formik, clarityErr,setClarityErr,clarityData }) => {

    const errorEleMsgClarity = useRef(null);

    useEffect(() => {
  
        if(clarityErr){

            errorEleMsgClarity.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    },[clarityErr])
    // const clarityData = [
    //     {
    //         id: 1,
    //         clarity: "VVS",
    //     },
    //     {
    //         id: 2,
    //         clarity: "VS",
    //     },
    //     {
    //         id: 3,
    //         clarity: "SI1",
    //     },
    //     {
    //         id: 4,
    //         clarity: "SI2-SI3",
    //     },
    // ];

    // const labClarityData = [
        
    //     {
    //         id: 2,
    //         clarity: "VS",
    //     },
    //     {
    //         id: 3,
    //         clarity: "SI1",
    //     }
        
    // ];

    return (
        <>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <p className="font-size-lg font-weight-bold mb-0">
                        {language.CLARITY_CLARITY}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap mt-3 d-flex">
                    {clarityData.length !==0 ?clarityData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            {/* <Field type="radio" name="Clarity" id={`${item.clarity}_clarity`} value={item.clarity} onClick={(e) => e.target.value === formik.values.Clarity?formik.setFieldValue("Clarity",""):formik.setFieldValue("Clarity",e.target.value)} checked={formik.values.Clarity === item.clarity}/> */}
                            <Field
                                type="radio"
                                name="clarities"
                                id={`${item.clarity}_clarity`}
                                value={item.id}
                                onClick={(e) =>
                                    e.target.value === formik.values.clarities
                                        ? formik.setFieldValue("clarities", "")
                                        : formik.setFieldValue(
                                            "clarities",
                                            e.target.value
                                        )
                                }
                                checked={formik.values.clarities == item.id}
                                onChange={(e) => {
                                    e.target.value !== ""?setClarityErr(false):setClarityErr(true)
                                    // formik.setFieldValue("carat_value", "");
                                    // formik.setFieldValue("pcs_value", "");
                                }}
                            />
                            <div className="parcelBox selectBox">
                                <span>{item.clarity}</span>
                            </div>
                        </label>
                    )):null}
                    {formik.touched.clarities && formik.errors.clarities ? (
                        <div className="invalid-feedback">
                            {formik.errors.clarities}
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {clarityErr ? (
                        <div className="text-danger "ref={errorEleMsgClarity}>please select clarity</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
