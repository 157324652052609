import React ,{useRef,useEffect}from "react";
import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Color = ({ language, formik, colorErr, setColorErr,colorData }) => {

    const errorEleMsgColor = useRef(null);

    useEffect(() => {
  
        if(colorErr){

            errorEleMsgColor.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    },[colorErr])

    // const colorData = [
    //     {
    //         id: 1,
    //         color: "D-E-F",
    //     },
    //     {
    //         id: 2,
    //         color: "G-H",
    //     },
    //     // {
    //     //     id: 3,
    //     //     color: "I-J",
    //     // },
    //     // {
    //     //     id: 4,
    //     //     color: "K-L-M",
    //     // },
    // ];
    return (
        <>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <p className="font-size-lg font-weight-bold mb-0">
                        {language.CARAT_COLOR}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap mt-3 d-flex">
                    {colorData.length !==0 ? colorData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field
                                type="radio"
                                name="color"
                                id={`${item.color}_color`}
                                value={item.id}
                                onClick={(e) =>
                                    e.target.value === formik.values.color
                                        ? formik.setFieldValue("color", "")
                                        : formik.setFieldValue(
                                              "color",
                                              e.target.value
                                          )
                                }
                                checked={formik.values.color == item.id}
                                onChange={(e) => {
                                    e.target.value !== ""? setColorErr(false) : setColorErr(true)
                                    // formik.setFieldValue("carat_value", "");
                                    // formik.setFieldValue("pcs_value", "");
                                }}
                            />
                            <div className="parcelBox selectBox">
                                <span>{item.color}</span>
                            </div>
                        </label>
                    )):null}
                </div>
                <div className="col-12">
                    {formik.touched.color && formik.errors.color ? (
                        <div className="invalid-feedback">
                            {formik.errors.color}
                        </div>
                    ) : null}
                    {/* {formik.values.color ==='' ? (
                    <div className="text-danger">
                        please select color
                    </div>
                ) : null} */}
                    {colorErr ? (
                        <div className="text-danger" ref={errorEleMsgColor}>please select color</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
