import React, { useState,useEffect,useRef} from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
// import { GetDiamondDB,StoreDiamondDB } from "../../_helpers/IndexedDB";
import {Modal,Button,OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { FaCalendarAlt,FaInfoCircle } from "react-icons/fa";
import { CopyUrlAlert } from "../Popup/CopyUrlAlert";

import {toAbsoluteUrl} from "../../_helpers";
import { NODE_API_URL,BASE_URL} from "../../../env_config";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Paper,
    Tabs,
    Tab,
    Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import { BsLightningFill } from "react-icons/bs";
// import { FaExternalLinkAlt} from "react-icons/fa";
// import VideoIframe from "./VideoIframe";
// import { SwalWarn,SwalError } from "../Popup";
import DetailSkeleton from "./DetailSkeleton"
import { BuyDiamond } from "../Popup/BuyDiamond";
import { ImageSharePopup } from "../widgets/tiles/ImageSharePopup";
import { ImageExpandPopupCommon } from "../widgets/tiles/ImageExpandPopupCommon";
import { VideoExpandPopupCommon } from "../widgets/tiles/VideoExpandPopupCommon";
import CertificateLink from "../CertificateLink";
import SVG from "react-inlinesvg";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function DetailPageHC({conversionrate,symbol,language,symbolname,userinfo,logStoredRef}){
    // console.log(conversionrate,"DETAILSS")
    const [value, setValue] = React.useState(0); // for tab
    const {OrderId} = useParams()
    // const [diamondsearch,setDiamondSearch] = useState([])
    // const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
    // const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    // const [alertMessage,setAlertMessage] = useState()
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);
    // const [confid,setConfId] = useState("")
    // const [supstatus,setSupStatus] = useState(null)
    // const [swalerror,setSwalError] = useState(false)
    // const [errormsg,setErrorMsg] = useState("")
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(false)
    // const swalWarnOpenfn = (props) => {
    //     setSwalWarnOpen(true)
    // }

    // const holdWarn = () => {
    //     // setSemiAprroveModal(true)
    //     setSwalWarnOpen(true)
    //     setAlertMessage("This stone already in your hold order")
    // }

    // const closeSwal = (props) => {
    //     // props.onHide()
    //     setSwalWarnOpen(false)
    // }

    function handleTabChange(event, newValue) {
        setValue(newValue);
      }

    useEffect(() => {
        setLoader(true)
        const Order_id = OrderId.split('-')[1]

        const finalData = {
            "order_id":Order_id,
            "loginLogId":Cookies.get("_LogId"),
            "conversionrate":conversionrate,
            "curr":symbolname,
        }
        axios({
            method: "get",
            url: `${NODE_API_URL}/INTL/detailHoldOrConfirm`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params: finalData,
        }).then(async(res) => {
            setLoader(false)

            if(res && res.data && res.data.data){
                setData(res.data.data)
                // console.log(data, res.data)
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[OrderId])
    // const [updatewishlist,setUpdateWishlist] = useState()


    // console.log(wishlistdata,"wishlistdata")
    // const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    
      // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------

    
    function handleClose(event, reason) {
        setSuccessOpen(false)
        setOpen(false);
        // setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }
  
    // const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }



    const [diamondselected,setDiamondSelected] = useState([])
    // const [buypopup,setBuyPopUp] = useState(false)
    const [buyholdpopup,setBuyHoldPopUp] = useState(false)
    const [showimage,setShowImage] = useState(false)
    // console.log(showimage,":showimage")
    // const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)

 
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    // const BuyDiamondBtn = (selected) => {
    //     // console.log(selected,"BuyDiamond")
    //     if(selected.length === 0){
    //         alert("Please Select Diamonds")
    //         return
    //     }
    //     let temptotalcarat = 0
    //     let temptotalinrcar = 0
    //     let temptotalourprice = 0
    //     if(data){
    //         temptotalcarat += data.carat
    //         temptotalourprice += data.ourPriceExchange
    //         temptotalinrcar += data.ourRateExchange
    //     }
    //     let selectedvalues = [
    //         {
    //             ...data,
    //             temptotalcarat:temptotalcarat,
    //             temptotalourprice:temptotalourprice,
    //             temptotalinrcar:temptotalinrcar,
    //             OurPrice:data.ourPriceExchange,
    //             OurRate:data.ourRateExchange
    //         }
    //     ]
    //     setDiamondSelected(selectedvalues)
    //     setBuyPopUp(true)
    //     setBuyPopUpInr(temptotalourprice/temptotalcarat)
    //     setBuyPopUpTotal(temptotalourprice)
    //     setDiamondcount(diamondcount)
    //     // console.log(selectedvalues,"selectedvalues")
    //   }
      const BuyDiamondHoldBtn = (selected) => {
        
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.carat
            temptotalourprice += data.ourPriceExchange
            temptotalinrcar += data.ourRateExchange
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.ourPriceExchange,
                OurRate:data.ourRateExchange,
                shippingdays:data.shippingDays,
                "OurPrice(USD)":data.ourPriceExchange,
                "USD/CT":data.ourRateExchange,
                diamond_type:data.diamondType === "L"?"L":"N",
                conform_good_id:data.conformGoodId
            }
        ]
        setDiamondSelected(selectedvalues)
        setBuyHoldPopUp(true)
        setBuyPopUpInr(data.ourRateExchange)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(1)
        // console.log(selectedvalues,"selectedvalues")
      }
    //   const HoldDiamond = (selected) => {
    //     // console.log(selected,"BuyDiamond")
    //     if(selected.length === 0){
    //         alert("Please Select Diamonds")
    //         return
    //     }
    //     // console.log(selected,"selected")
    //     let temptotalcarat = 0
    //     let temptotalinrcar = 0
    //     let temptotalourprice = 0
    //     if(data){
    //         temptotalcarat += data.C_Weight
    //         temptotalourprice += data.our_price
    //         temptotalinrcar += data.our_rate
    //     }
    //     let selectedvalues = [
    //         {
    //             ...data,
    //             temptotalcarat:temptotalcarat,
    //             temptotalourprice:temptotalourprice,
    //             temptotalinrcar:temptotalinrcar,
    //             OurPrice:data.our_price,
    //             OurRate:data.our_rate,
    //             discount_main:data.our_discount
    //         }
    //     ]
    //     setDiamondSelected(selectedvalues)
    //     setHoldPopUp(true)
    //     setBuyPopUpInr(temptotalourprice/temptotalcarat)
    //     setBuyPopUpTotal(temptotalourprice)
    //     setDiamondcount(diamondcount)
    //     // console.log(selectedvalues,"selectedvalues")
    //   }
    //   const ShareBtn = () => {
    //     navigator.clipboard.writeText(window.location.href)
    //     setShowImage(true)
    //   }
      function numberWithCommas(x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
return (
        <>
        {loader ? <DetailSkeleton/> :
            data.length !==0 ?
                <div className={`container product_detail ${ data.diamondType === "L" ? "labTab" : "natTab" }`}>
                    {/* <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} />  */}
                    {/* <SwalError show={swalerror} message={errormsg} onClose={() => setSwalError(false)} responseOnOk={() => setSwalError(false)}/> */}
                    <BuyDiamond show={buyholdpopup} onHide={() => setBuyHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} diamondcount={diamondcount} totalourprice={buypopupctotal} language={language}/>  
                    <ImageSharePopup show={showimage} onClose={() => setShowImage(false)} src={window.location.href} msg={"Stone URL Copied"}/>
                    
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-body">
                            <div className="row">
                                {/* data: {JSON.stringify(data)} */}
                                {/* <div className="col-12">
                                    <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {language.DETAIL_GOTORESULT}</span>
                                </div> */}
                                <div className="col-md-6 d-flex flex-lg-nowrap flex-wrap justify-content-center">
                            
                            <div className="detail_img_box mt-4 order-lg-2 ribbon ribbon-top ribbon-left">
                                {data.diamondType === "N" || data.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                                {data.image ? (
                                    <img
                                        className="img-fluid"
                                        alt={data.shape}
                                        src={data.image}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/${data.shape ? data.shape.toLowerCase() : ""}-no.png`
                                                )
                                            }}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid"
                                        alt="shape"
                                        src={toAbsoluteUrl(
                                            `/media/shape/${data.shape.toLowerCase()}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                    />
                                )}
                            </div>
                            <ul className="img_thumb_box mt-4 ml-5 pl-0 d-flex d-lg-block order-lg-1">
                                <ImageExpandPopupCommon data={data}/>
                                <VideoExpandPopupCommon  data={data} userinfo={userinfo}/>
                                {data.heart ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openHeartModal()}>
                                        {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/> */}
                                        <SVG  width="40" src={toAbsoluteUrl("/media/General/heartImage.svg")} />
                                    </li>
                                    
                                ):('')}
                                {data.arrow ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openArrowModal()}>
                                        {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/> */}
                                        <SVG  width="40" src={toAbsoluteUrl("/media/General/arrowImage.svg")} />
                                    </li>
                                ):('')}
                                {data.asset ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openAssetModal()}>
                                        <SVG  width="40" src={toAbsoluteUrl("/media/General/assetIcon.svg")} />
                                        {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/> */}
                                    </li>
                                ):('')}
                            </ul>
                        </div>
                                <div className="col-md-6">
                                    <div className="row">
                                    <div className="col-12 my-3 d-flex justify-content-start align-items-center">
                                    <div className=" border-right pr-4 mr-4" >
                                        {data.lab}:{data.certificate ? (
                                            <>
                                                
                                                <CertificateLink Certificate_link={data.Certi_link} lab={data.lab} certificate={data.certificate} hideLabName withExternalLinkIcon className={`mx-2 text-dark text-left`} />
                                                <CopyUrlAlert text={data.certificate}/>
                                            </>

                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="">{language.MUI_STOCKNO}: <div className=" d-inline-block">{data.diamondType}-{data.stockID}</div>
                                        <span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {
                                                        data.availability === 'Memo' || data.availability === 'Hold'
                                                            ? `Business Process`
                                                            : data.availability === "Guaranteed"
                                                            ? `Guaranteed`
                                                            : data.availability === "Not Available"
                                                            ? `Not Available`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `Subject to Prior sale`
                                                            : `Unknown`
                                                        }
                                                    </Tooltip>
                                                }
                                            >
                                                <img
                                                    className="img-fluid availIco ml-2"
                                                    alt={data.availability}
                                                    src={toAbsoluteUrl(`/media/svg/${
                                                        data.availability === 'Memo' || data.availability === 'Hold'
                                                            ? `availability_primary.svg`
                                                            : data.availability === "Guaranteed"
                                                            ? `availability_success.svg`
                                                            : data.availability === "Not Available"
                                                            ? `availability_danger.svg`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `availability_warning.svg`
                                                            : `availability_blank_white.svg`
                                                    }`)}
                                                />
                                            
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                   
                                    {/* <span className=""> 
                                        {language.SEARCHLIST_LOCATION}: {data.location ? data.location : "-"}
                                        {data.location ? (
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={<Tooltip id="tooltip">{data.location}</Tooltip>}
                                            >
                                                <img
                                                    className="img-fluid flagIco mx-4"
                                                    alt={data.location}
                                                    src={toAbsoluteUrl(`/media/map/${data.location.toLowerCase()}.png`)}
                                                />
                                            </OverlayTrigger>
                                        ) : (
                                            null
                                        )}
                                    </span> */}
                                    
                                </div>
                                <div className="col-10 align-self-center">
                                    <span className="text-dark font-weight-bold text-capitalize font-size-h3">
                                        <span className="pr-2 font-size-h2">
                                            {data.shape}
                                        </span>
                                        <span className="pr-2 font-size-h2">
                                            {data.carat.toFixed(2)}
                                        </span>
                                        <span className="pr-2 font-size-h2">{data.color}</span>
                                        <span className="pr-2 font-size-h2">{data.clarity}</span>
                                        <span className="pr-2 font-size-h2">{data.cut}</span>
                                        <span className="pr-2 font-size-h2">{data.polish}</span>
                                        <span className="pr-2 font-size-h2">{data.symmetry}</span>
                                        <span className="pr-2 font-size-h2">{data.fluorescence}</span>
                                    </span>
                                </div>
                                <div className="col-12 advTag mb-4">
                                    <>
                                        {/* shade */}
                                        {renderShadeLabel(data.shade)}
                                
                                        { data.milky && data.milky.toLowerCase() === "no milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            NO MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.milky && data.milky.toLowerCase() === "luster low" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Luster Low
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.milky && data.milky.toLowerCase() === "light milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            LIGHT MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.milky && data.milky.toLowerCase() === "milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                                {/* eyeclean */}
                                                { data.eyeClean && data.eyeClean.toLowerCase() === "yes" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            100% Eye Clean
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        { data.eyeClean && data.eyeClean.toLowerCase() === "no" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            No Eye Clean
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        {/* growthtype */}
                                        { data.diamondType === "L" && (data.growthType && data.growthType === "CVD") ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            CVD
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        { data.diamondType === "L" && (data.growthType && data.growthType === "HPHT") ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            HPHT
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        
                                        { (data.diamondType === "L" ) && (data.lab_treat && data.lab_treat.toLowerCase() === "as grown") ? 
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            As Grown
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                                </OverlayTrigger>
                                            </span>
                                        : 
                                            null
                                        }
                                        {/* natural treated */}
                                        { (data.diamondType === "N" || data.diamondType === "W") && (data.treatment && data.treatment.toLowerCase() !== "none")  ? 
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Treated
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                                </OverlayTrigger>
                                            </span>
                                        : 
                                            null
                                        }
                                    </>
                                </div>
                                <div className="col-12 mt-4 d-flex">
                                    {!data.supplierName ? (""):(
                                       <>
                                            <span className="font-size-sm mr-5"><span className="text-dark font-size-sm  text-capitalize">{language.MUI_SUPPLIER}:</span><span className="text-capitalize">{data.supplierName}</span ></span>
                                            <span className=""> 
                                                {language.SEARCHLIST_LOCATION}: {data.location ? data.location : "-"}
                                                {data.location ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        className="text-capitalize"
                                                        overlay={
                                                            <Tooltip id="tooltip">{data.location}</Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="flagIco mr-1"
                                                            alt={data.location}
                                                            src={toAbsoluteUrl(
                                                                `/media/map/${data.location.toLowerCase()}.png`
                                                            )}
                                                            onError={e => {
                                                                e.currentTarget.src =toAbsoluteUrl(
                                                                    `/media/map/other.png`
                                                                    )
                                                                }}
                                                        />
                                                    </OverlayTrigger>
                                                ) : (
                                                    null
                                                )}
                                            </span>
                                       </>
                                    )}
                                </div>
                                <div className="col-12 mt-4 d-flex">
                                    <span className="font-size-lg text-dark-50"><span className="font-size-sm  text-capitalize">{language.MUI_LISTPRICE}: </span>${data.listPrice}</span>
                                    <span className="font-size-lg text-dark-50 ml-8"><FaCalendarAlt className="text-danger mr-2" /><span className="font-size-sm  text-dark text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {data.shippingDays}{" "}{language.MUI_DAYS} </>}</span>
                                </div>
                                        {data.canbuy?<>
                                            <div className="col-12 mt-4">
                                        <div className="row">
                                            {!data.supplierName ? (""):(
                                                <div className="col-5">
                                                    <div className="price_border_right"></div>
                                                    <div className="px-0">
                                                        <h5 className="font-weight-bold">
                                                            {language.MUI_SUPPLIERPRICE}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Price Provideed by Supplier
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                            </OverlayTrigger>
                                                        </h5>
                                                        <hr className="w-25 ml-0 my-2"/>
                                                        <div className={`d-flex small ${(data.supplierDiscount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.supplierDiscount}% {(data.supplierDiscount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                        {
                                                            symbol !=="$" ? <>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            ${numberWithCommas(parseFloat((data.supplierPrice)))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="h1">
                                                                <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat((data.supplierPrice * conversionrate)))}</>
                                                            </span>
                                                                </OverlayTrigger>
                                                            </> :
                                                            <span className="h1">
                                                                <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat((data.supplierPrice * conversionrate)))}</>
                                                            </span>
                                                        } 
                                                        {
                                                            symbol !=="$" ? <>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            ${numberWithCommas(parseFloat(data.supplierRate))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat((data.supplierRate * conversionrate)))}</span></span>
                                                                </OverlayTrigger>
                                                            </> :
                                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat((data.supplierRate * conversionrate)))}</span></span>
                                                        } 
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-5">
                                                <div className="px-0">
                                                    <h5 className="font-weight-bold">
                                                        {language.MUI_OURPRICE}
                                                        {!data.supplierName ? (
                                                                ""
                                                            ):(
                                                                <>
                                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                                        {props => (
                                                                            <Tooltip id="overlay-example" {...props}>
                                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Overlay>
                                                                </>
                                                            )}
                                                    </h5>
                                                    <hr className="w-25 ml-0 my-2"/>
                                                    <div className={`d-flex small ${(data.ourDiscount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.ourDiscount}% {(data.ourDiscount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                   
                                                    { symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(parseFloat(data.ourPriceExchange))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="h1">
                                                                    <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat(data.ourPriceExchange * conversionrate))}</>
                                                                </span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="h1">
                                                            <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat(data.ourPriceExchange * conversionrate))}</>
                                                        </span>
                                                    } 
                                                    { symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(parseFloat(data.ourRateExchange))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat(data.ourRateExchange * conversionrate))}</span></span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat(data.ourRateExchange * conversionrate))}</span></span>
                                                    }  
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            <div className="col-12 mt-4 d-flex">
                                                <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold"  onClick={() =>   BuyDiamondHoldBtn([data.id]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>

                                            </div>
                                        </> :("")}
                                        {/* <hr className="w-100 mt-5" />
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <h4 className="text-black mb-3">{language.DETAIL_HAVEQUESTION} </h4>
                                                    <p className="mb-3">{language.DETAIL_WRITEUS} <a href="mailto:inquiry@diamondsoncall.com" className="text-primary"> inquiry@diamondsoncall.com </a> </p>
                                                    <button type="button" className="btn btn-light-primary px-5" data-toggle="modal" data-target="#SendInquiry">{language.DETAIL_SENDINQUIRY}</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card bg-white card-custom card-sticky gutter-b">
                <div className="card-header justify-content-center mb-3 min-card-header-height p-0">
                    <div className="card-title m-0">
                        <Paper position="static" className="shadow-none">
                            <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                <Tab label={language.DETAIL_DIAMONDDETAILS} />
                                <Tab label={language.MUI_PARAM_DETAILS} />
                                <Tab label={language.MUI_ADDITIONALDETAILS}/>
                            </Tabs>
                        </Paper>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            {value === 0 && (
                                <TabContainer >
                                    <div className="row">
                                         <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_STOCKID} </div>
                                                <div className="col-6 "> {OrderId.split('-')[0] === "N" ? "N" :"L"}-{data.stockID ? data.stockID : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_SHAPE} </div>
                                                <div className="col-6 ">  {data.shape ? data.shape : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CARAT} </div>
                                                <div className="col-6 ">  {data.carat ? data.carat.toFixed(2) : "-"} </div>
                                            </div>
                                           
                                            { (data.color ==="fancy") ? (
                                                <>
                                                    <div className="font-size-h5 text-dark font-weight-bold mb-3 ">Fancy Color Details </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_INTENSITY}</div>
                                                        <div className="col-6 ">  {data.fancy_intensity ? data.fancy_intensity : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_OVERTONE}</div>
                                                        <div className="col-6 ">  {data.fancy_overtone ? data.fancy_overtone : "-"} </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-6">{language.SEARCHLIST_COLOR}</div>
                                                        <div className="col-6 ">  {data.fancy_color ? data.fancy_color : "-"} </div>
                                                    </div>
                                                </>
                                            ):(
                                                <div className="row mb-2">
                                                    <div className="col-6"> {language.SEARCHLIST_COLOR} </div>
                                                    <div className="col-6 ">  {data.color ? data.color : "-"} </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CLARITY} </div>
                                                <div className="col-6 ">  {data.clarity ? data.clarity : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CUT} </div>
                                                <div className="col-6 ">  {data.cut ? data.cut : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_POL} </div>
                                                <div className="col-6 ">  {data.polish ? data.polish : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_SYMM} </div>
                                                <div className="col-6 ">  {data.symmetry ? data.symmetry : "-"} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_FLOU}</div>
                                                <div className="col-6 ">  {data.fluorescence ? data.fluorescence : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_LAB}</div>
                                                <div className="col-6 ">  {data.lab ? data.lab : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_CERTIFICATE}</div>
                                                <div className="col-6 ">
                                                    
                                                    <CertificateLink Certificate_link={data.Certi_link} lab={data.lab} certificate={data.certificate} hideLabName className={`mx-2 text-dark font-size-sm`} />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_LOCATION}</div>
                                                <div className="col-6 ">  {data.location ? data.location : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHGRID_SHIPPINGDAYS}</div>
                                                <div className="col-6 ">  {data.shippingDays ? <> {data.shippingDays}{" "}{language.MUI_DAYS} </> : "-"} </div>
                                            </div>
                                        </div>
                                    </div >

                                </TabContainer>
                            )}
                            {value === 1 && (
                                <TabContainer >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_MEASUREMENT}</div>
                                                <div className="col-6 ">  {data.length?.toFixed(2)} * {data.width?.toFixed(2)} * {data.height?.toFixed(2)}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_TABLE} %</div>
                                                <div className="col-6 ">  {data.tablePercent ? `${data.tablePercent}%` : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_DEPTH} %</div>
                                                <div className="col-6 ">  {data.depthPercent ? `${data.depthPercent}%` : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_CROWN}</div>
                                                <div className="col-6 ">  {data.crownHeight ? `${data.crownHeight}%` : "-"}, {data.crownAngle ? `${data.crownAngle}°` : "-"}  </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_PAVILION}</div>
                                                <div className="col-6 ">  {data.pavilionHeight ? `${data.pavilionHeight}%` : "-"}, {data.pavilionAngle ? `${data.pavilionAngle}°` : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_GIRDLE}</div>
                                                <div className="col-6 ">   {data.girdle ? data.girdle : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_GIRDLECONDTION}</div>
                                                <div className="col-6 ">   {data.girdleCondition ? data.girdleCondition : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_GIRDLETHICK}</div>
                                                <div className="col-6 ">   {data.girdle_thick ? data.girdle_thick : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_GIRDLETHIN}</div>
                                                <div className="col-6 ">   {data.girdle_thin ? data.girdle_thin : "-"}  </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_CULET}</div>
                                                <div className="col-6 ">   {data.culet ? data.culet : "-"}  </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </TabContainer>
                            )}
                            {value === 2 && (
                                <TabContainer >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.MUI_SHADE} </div>
                                                <div className="col-6 ">  {data.shade ? data.shade : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.MUI_MILKY} </div>
                                                <div className="col-6 ">  {data.milky ? data.milky : "-"} </div>
                                            </div>
                                            <div className="row mb-2"> 
                                                <div className="col-6"> {language.MUI_EYECLEAN} </div>
                                                <div className="col-6 ">  {data.eyeClean ? data.eyeClean : "-"} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {data.diamondType === "L" ? 
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                        <div className="col-6 ">  {data.growthType ? data.growthType : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                        <div className="col-6 ">  {data.lab_treat === "Treated"?"Post Growth Treatment": data.lab_treat?data.lab_treat : "-"} </div>
                                                    </div>
                                                </>
                                            :   
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                        <div className="col-6 ">  {data.origin ? data.origin : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_BRAND} </div>
                                                        <div className="col-6 ">  {data.brand ? data.brand : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                        <div className="col-6 ">  {data.treatment ? data.treatment : "-"} </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2"> 
                                                <div className="col-6"> {language.MUI_KEY} </div>
                                                <div className="col-6 ">  {data.keyToSymbol ? data.keyToSymbol : "-"} </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabContainer>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
                    
                    
                    {/* <VideoIframe
                        showVideo={showVideo}
                        handleClose={handleClose}
                        // src={data.diamondType === "L"?"L":"N" ? `https://pro360video.com/video.php?refno=${data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}` }
                        src={data.diamondType === "L" ? `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}`:`https://pro360video.com/video.php?refno=${data.Certi_NO}`   }
                        // src={data.ext_video || ""}
                        data={data}                           
                    /> */}
                    <Modal
                        show={showHeartImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_heart} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showArrowImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_arrow} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showAssetImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_asset} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={handleClose}
                            variant="error"
                            message="Stone already in tracklist"
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={successOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={handleClose}
                            variant="success"
                            message="Stone tracklisted successfully"
                        />
                    </Snackbar>
                </div>
            :
                <div className="card custom-card gutter-b">
                    <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                        <div className="text-center">
                            <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                            <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>No Records Found</strong></div>
                            {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
