import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { MockDocTableColumn } from './Columns'; 
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronRight, FaChevronDown } from "react-icons/fa";
import  {ExpandedDetails} from "./expandDetail/ExpandedDetails"
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import axios from 'axios';
import InvoiceSkeleton from'./InvoiceSkeleton';
import Cookies from "js-cookie";
export const Invoice = (props) => {
    // const [CustomizeTable, setCustomizeTable] = useState(false);
    // const handleClose = () => setCustomizeTable(false);
    const [loader,setLoader] = useState(true)
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails data={row.row.original} language={props.language} userinfo={props.userinfo}/>
            </>
        ),[]
      )
    
    const columns = useMemo(()=> MockDocTableColumn(props.language,props.userinfo),[] )
    // const data = useMemo(()=> StockAPI,[])
    const [data,setData] = useState([])
    useEffect(() => {
        
        axios({
            method: "GET",
            url: `${NODE_API_URL}/INTL/invoice-listing`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        }).then((res) => {
            setLoader(false)
            if (res && res.data) {
                if(!res.data.success){
                    setData([])
                }
                else{
                    setData(res.data.data)
                }
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
        })
    },[])
    console.log(data,"datadata")
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        state:{  pageIndex, pageSize },
    } = useTable({
        columns,
        data
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //            <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaChevronDown className="text-success"  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand"/>}
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )
    return (
        <>
        
        {!loader ?
        <div>
            <div className="card card-custom card-sticky">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        {data.length} {props.language.INVOICE_INVOICES}
                    </div>
                </div>
                <div className="card-body bg-white" style={{minHeight:"50px"}}>
                    <div className="table-responsive w-100">
                        <table className="table table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups && headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ) || null)}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.length > 0 && page.map((row,i) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={i}>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </React.Fragment>
                                    )
                                }) || <tr><td className="text-center bg-gray-200" colSpan="100%">No records found</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer bg-white border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
            
        </div> :<InvoiceSkeleton />}
        
        </>
    )
}