import React, { useMemo, useEffect,useState } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { searchResultColumn } from "./SearchColumn";
import { searchResultColumnWithId } from "./SearchColumnWithId";
import { CheckBox } from '../../../tableFilter/CheckBox';
import { FaSortDown,FaSortUp} from "react-icons/fa";
import Cookies from "js-cookie";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";
// import axios from "axios"
// import { NODE_API_URL } from "../../../../../env_config";
import { Skeleton } from "@material-ui/lab";
import { BASE_URL, NODE_API_URL } from "../../../../../env_config";
import axios from "axios";

export const SearchResult = ({userinfo, setSearchResultData, resultData,dimondType,language,conversionrate,symbol,symbolname}) => {
    const [stoneData, setStoneData] = useState([]);

    useEffect(() => {
        setStoneData(resultData.length !== 0 ? [resultData] : [])
    }, [resultData]);
// console.log(stoneData)
    useEffect(() => {
        if(stoneData.length !== 0){
            if (resultData.length !== 0 && resultData.parcel_id) {
                // console.log("searchbyid")
            } else {
                const finalData=`
                    {
                        "shape":"${stoneData[0]?.c_Shape}",
                        "orderType":"${stoneData[0]?.type}",
                        "clarities":"${stoneData[0]?.clarities}",
                        "color":"${stoneData[0]?.color}",
                        "weight":"${stoneData[0]?.carat}",
                        "pieces":"${stoneData[0]?.pcs}",
                        "curr":"${symbolname}",
                        "curr_rate":"${conversionrate}",
                        "diamondType":"${dimondType}",
                        "mmFrom":"${stoneData[0]?.mm_from}",
                        "mmTo":"${stoneData[0]?.mm_to}",
                        "sieveFrom":"${stoneData[0]?.sieve_from}",
                        "sieveTo":"${stoneData[0]?.sieve_to}",
                        "loginLogId":${Cookies.get("_LogId")}
                    }`
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/INTL/parcel-search-log`,
                    headers: {
                        "Content-Type": "application/json",
                         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                    },
                    data: finalData,
                })
                .then(function(res) {
                    if(res && res.data){
                        // console.log(res.data)
                  }
                })
                .catch(error => {
                    if(error?.response?.data?.message === "UnAuthorized!"){
                        var cookies = document.cookie.split(";");
                        for (var i = 0; i < cookies.length; i++) {
                            if(cookies[i] && !cookies[i].includes("view")){
                            var equals = cookies[i].indexOf("=");
                            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                        }
                    
                    window.location.replace(`${BASE_URL}/logout`);
                    }
                })
            }

        }
    }, [stoneData]);

    // const columns = useMemo(() =>  {
    //     if(resultData){
    //         searchResultColumnWithId(language,conversionrate,symbol)
    //     }else{
    //         searchResultColumn(language,conversionrate,symbol)
    //     }
    // }, []);

    const columns = useMemo(() => {
        if (resultData.length !== 0 && resultData.parcel_id) {
            return searchResultColumnWithId(language, conversionrate, symbol);
        } else {
            return searchResultColumn(language, conversionrate, symbol,dimondType);
        }
    }, [resultData, language, conversionrate, symbol]);
//    console.log(resultData)
    
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        selectedFlatRows,
        state: { selectedRowIds },
        // setGlobalFilter,
        // allColumns,
        // getToggleHideAllColumnsProps,
        // state: { 
        //     globalFilter,
        // },
    } = useTable(
        {
            columns,
            data:stoneData,
            initialState: {
                selectedRowIds: 'selection'
              }
        },
        
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id:'selection',
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    ...columns,
                ];
            });
        }
        );
        setSearchResultData(selectedFlatRows)
    return (
        <>
            <div className="card card-custom shadow-none">
                {/* <div className="card-header">
                    <div className="card-title">
                        Search Result
                    </div>
                </div> */}
                <div className="card-body px-5 pt-5 pb-0">
                    <div className="table-responsive custom-scrollbar rounded w-100">
                        <table className="table  table-hover parcel_table m-0" {...getTableProps()} >
                            <thead className={`custom_datatable_head ${dimondType === "PN" ? "bg-primary-o-60" : "bg-success-o-60"} `}>
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center text-nowrap align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {stoneData.length !== 0 ? page.map((row) => {
                                    prepareRow(row)
                                    const isSelected = selectedRowIds[row.id];
                                    return(
                                        <React.Fragment key={row.id}>
                                            <tr {...row.getRowProps()} key={row.id} className={`${isSelected ? 'bg-success-o-30' : 'bg-white'}`}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </React.Fragment>
                                    )
                                }) :
                                <tr className="bg-white parcel-skeleton">
                                    <td><Skeleton  className="mx-auto" variant="text" width={15} /></td>
                                    <td><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                    <td><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                    <td>
                                        <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                        <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                    </td>
                                    {Array.from(Array(2), (e, i) => {
                                        return (
                                            <td key={i}><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                        );
                                    })}
                                    {Array.from(Array(2), (e, i) => {
                                        return (
                                            <td key={i}>
                                                <Skeleton className="mx-auto" variant="text" width={80} height={20} />
                                                <Skeleton className="mx-auto" variant="text" width={80} height={10} />
                                            </td>
                                        );
                                    })}
                                    <td><Skeleton  className="mx-auto" variant="text" width={60} /></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </>
    );
};
