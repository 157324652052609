import React ,{useEffect,useState} from "react";
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import axios from "axios";
import { toAbsoluteUrl } from "../../../_helpers";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Tab, Alert, Nav } from "react-bootstrap";
import PersonalInfo from "./profileView/PersonalInfo"
import CompanyInfo from "./profileView/CompanyInfo"
// import ShippingInfo from "./profileView/ShippingInfo"
import UploadLogo from "../KycUpdate/FormFields/UploadLogo";
import { CiUser } from "react-icons/ci";
// import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LiaShippingFastSolid } from "react-icons/lia";
import ShippingAddress from "./ShippingAddress";
import Cookies from "js-cookie"
import ProfileEdit from "./ProfileEditPopup";
import { AddEditShipping } from "./AddEditShipping";
import SweetAlert from 'react-bootstrap-sweetalert';
export default function ProfileView({userinfo,setEdit,language,isprofilecomplete,setConversionCookies,conversioncookies}) {
    const [warnMsg,setWarnMsg] = useState('')
    const [profileinfo,setProfileInfo] = useState(userinfo)
    const [showEditShipping, setShowEditShipping] = useState(false);
    const [rejectedcomment,setRejectedComment] = useState(null)
    const [show, setShow] = useState(false);
    const [editShipping,setEditShipping] = useState({})
    const [shippingaddress,setShippingAddress] = useState([])
    const [mount,setMount] = useState(false)
    useEffect(() => {
        if(userinfo.kyc_status === "KYC_PEND" || userinfo.kyc_status === "KYC_REJ"|| userinfo.kyc_status === "KYC_EDITED"){
            axios({
                method: "GET",
                url: NODE_API_URL + "/INTL/getKycPendingData",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
              }).then((res) => {
                if (res && res.data && res.data.success) {
                    // console.log(res.data.data.isprofileapproved,"success")
                    setProfileInfo(res.data.data)
                    if(userinfo.kyc_status === "KYC_REJ"){
                        setRejectedComment(res.data.data.reject_comment_cus)
                    }
                }
              }).
              catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
    },[])
    const SetEdit = () => {
        if(userinfo.kyc_status === "KYC_APPR" && !userinfo.shippingaddress){
            setWarnMsg("Please Provide Shipping Address")
            return
        }
        setEdit(true)
    }
    return (
        <>
          {warnMsg && (
                <SweetAlert
                    warning
                    title={"Warning!"}
                    onConfirm={()=>setWarnMsg(false)}
                    confirmBtnText="Ok"
                    // cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    // cancelBtnBsStyle="danger"
                >
                    {warnMsg}
                </SweetAlert>
            )}  
            <Tab.Container id="profileTab" defaultActiveKey="personal">
                <div className="row">
                    <div className="col-md-4 text-center" >
                        <div className='sticky-top'style={{top:"132px"}}>
                            <div className="profileLogoBox mx-auto">
                                {userinfo.company_logo?
                                    <img
                                        alt="profile"
                                        className="img-fluid profileLogo rounded-circle bg-light p-2"
                                        width="125px"
                                        src={process.env.REACT_APP_ENV === "production"?`https://assets.diamondsoncall.com/assets/documents/customer_logo/${userinfo.company_logo}`:`https://alpha.diamondsupload.com/assets/customer_logo/${userinfo.company_logo}`}
                                    /> 
                                :
                                    <img
                                        alt="profile"
                                        className="img-fluid profileLogo rounded-circle bg-light p-2"
                                        width="125px"
                                        src={toAbsoluteUrl(`/media/General/usericon.png`)}
                                    />
                                }
                            </div>
                            <div className="font-size-h4 mt-5 text-capitalize font-weight-bold">
                                {userinfo.cname}
                            </div>
                            <div className="font-size-h6 text-muted mt-1 text-capitalize ">
                                <FaMapMarkerAlt className="mr-2" />
                                {userinfo.city}, {userinfo.country}
                            </div>
                            <UploadLogo/>
                            <div className="mt-10">
                            {userinfo.kyc_status === "KYC_PEND" ?(
                                <Alert className="mb-0" variant="warning">
                                     Your updated KYC is under review, please wait for approval.
                                </Alert>
                            ) : userinfo.kyc_status === "NO_KYC" ? (
                                <Alert className="mb-0" variant="danger">
                                     Your KYC not completed
                                </Alert>
                            ) : userinfo.kyc_status === "KYC_APPR" ? (
                                <Alert className="mb-0" variant="success">
                                     Your KYC Approved
                                </Alert>
                            ) : userinfo.kyc_status === "KYC_REJ" ? (
                                <Alert className="mb-0" variant="danger">
                                     Your KYC Rejected 
                                    { rejectedcomment ? <div className="font-italic font-weight-bold font-size-lg">{rejectedcomment}</div> : ""}
                                </Alert>
                            ): userinfo.kyc_status === "KYC_EDITED" ? (
                                <Alert className="mb-0" variant="warning">
                                     Your updated KYC is under review, please wait for approval.
                                </Alert>
                            )  
                            :( "")}
                             {userinfo.kyc_status === "KYC_APPR" && !userinfo.shippingaddress?<Alert className="mb-0" variant="danger">
                                    Please Provide Shipping Address
                                </Alert>:""}   
                            </div>
                            <Nav variant="pills" className="flex-column mt-md-10 mt-3 profile_nav custom_nav">
                                <Nav.Item>
                                    <Nav.Link className={`${showEditShipping ? "disabled":""}`} eventKey={`${!showEditShipping ? "personal":""}`}><CiUser className="mr-2"/>{language.PROFILEVIEW_PROFILE_INFO  }</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="company"><HiOutlineBuildingOffice2 className="mr-2"/>{language.PROFILEVIEW_COMPANY_INFORMATION}</Nav.Link>
                                </Nav.Item> */}
                                {userinfo.kyc_status !== "KYC_PEND"?<Nav.Item>
                                    <Nav.Link eventKey="shipping"><LiaShippingFastSolid className="mr-2"/>Manage {language.PROFILEVIEW_SHIPPING_INFORMATION}</Nav.Link>
                                </Nav.Item>:""}
                            </Nav>
                        </div>
                    </div>
                    <div className="col-md-8 profile-card bg-white rounded p-8">
                        <Tab.Content>
                            <Tab.Pane eventKey="personal">
                                <div>
                                    <div className="border-bottom d-flex justify-content-between pb-3 mb-5">
                                        <h3>{language.PROFILEVIEW_PROFILE_INFO}</h3>
                                        <button className="btn btn-sm btn-secondary" onClick={() => SetEdit()}>{language.PROFILE_EDIT}</button>
                                    </div>
                                    <div className="custom-scrollbar" style={{maxHeight:"68vh",overflowX:"hidden"}}>
                                        <PersonalInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/>
                                        <CompanyInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/>
                                    </div>
                                </div>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="company">
                            </Tab.Pane> */}
                            <Tab.Pane eventKey="shipping">
                                {showEditShipping?
                                    <AddEditShipping data={userinfo} setShowEditShipping={setShowEditShipping} setEditShipping={setEditShipping} editShipping={editShipping} status={showEditShipping} shippingaddress={shippingaddress} setMount={setMount} mount={mount} setConversionCookies={setConversionCookies} conversioncookies={conversioncookies}/>
                                :
                                    <ShippingAddress setShippingAddress={setShippingAddress} shippingaddress={shippingaddress} setEditShipping={setEditShipping} editShipping={editShipping} userinfo={profileinfo} setShowEditShipping={setShowEditShipping} contactbookuserinfo={userinfo} language={language} setMount={setMount} mount={mount}/>
                                }
                                {/* <ShippingInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
            <ProfileEdit show={show} onClose={()=>setShow(false)}  language={language} isprofilecomplete={isprofilecomplete} userinfo={userinfo} />
        </>
    );
}
