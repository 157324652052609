import React from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DatePickerField, PhoneInput, SelectField } from "../FormFields";
import MuiUploader from '../FormFields/MuiUploader';
import { FaInfoCircle } from "react-icons/fa";
import { OverlayTrigger,Tooltip } from "react-bootstrap";

const nature_of_business_data = [
    {
      value: 'jewelry retailer',
      label: 'jewelry retailer'
    },
    {
      value: 'jewelry manufacture',
      label: 'jewelry manufacture'
    },
    {
      value: 'other',
      label: 'other'
    }
  ];

  const state_data = [
    {
      value: 'Andhra Pradesh',
      label: 'Andhra Pradesh'
    },
    {
      value: 'Arunachal Pradesh',
      label: 'Arunachal Pradesh'
    },
    {
      value: 'Assam',
      label: 'Assam'
    },
    {
      value: 'Bihar',
      label: 'Bihar'
    },
    {
      value: 'Chhattisgarh',
      label: 'Chhattisgarh'
    },
    {
      value: 'Goa',
      label: 'Goa'
    },
    {
      value: 'Gujarat',
      label: 'Gujarat'
    },
    {
      value: 'Haryana',
      label: 'Haryana'
    },
    {
      value: 'Himachal Pradesh',
      label: 'Himachal Pradesh'
    },
    {
      value: 'Jammu & Kashmir',
      label: 'Jammu & Kashmir'
    },
    {
        value: 'Jharkhand',
        label: 'Jharkhand'
    },
    {
        value: 'Karnataka',
        label: 'Karnataka'
    },
    {
      value: 'Kerala',
      label: 'Kerala'
    },
    {
      value: 'Madhya Pradesh',
      label: 'Madhya Pradesh'
    },
    {
        value: 'Maharashtra',
        label: 'Maharashtra'
    },
    {
        value: 'Manipur',
        label: 'Manipur'
    },
    {
        value: 'Meghalaya',
        label: 'Meghalaya'
    },
    {
        value: 'Mizoram',
        label: 'Mizoram'
    },
    {
        value: 'Nagaland',
        label: 'Nagaland'
    },
    {
      value: 'Orissa',
      label: 'Orissa'
    },
    {
      value: 'Punjab',
      label: 'Punjab'
    },
    {
      value: 'Rajasthan',
      label: 'Rajasthan'
    },
    {
      value: 'Sikkim',
      label: 'Sikkim'
    },
    {
      value: 'Tamil Nadu',
      label: 'Tamil Nadu'
    },
    {
      value: 'Telengana',
      label: 'Telengana'
    },
    {
      value: 'Tirupati',
      label: 'Tirupati'
    },
    {
      value: 'Uttar Pradesh',
      label: 'Uttar Pradesh'
    },
    {
      value: 'Uttarakhand',
      label: 'Uttarakhand'
    },
    {
      value: 'West Bengal',
      label: 'West Bengal'
    }
  ];
export default function CompanyInfoForm(props) {
    const {
        formField: {
            company_name,
            address1,
            logo,
            billing_company_name,
            address2,
            address3,
            city,
            state,
            zipcode,
            country,
            telephone,
            fax,
            invoice_email,
            website,
            comp_reg_no,
            comp_reg_no_file,
            tax_id,
            nature_of_business,
            business_est_date,
            name_of_partner,
            contact_of_partner,
            passport_id,
            passport_id_file,
        },
        setSecondFile,
        setFirstFile,
        FirstFile,
        setThirdFile,
        setFieldValue,
        values,
        setCompanyRegName,
        setPassportFileName
    } = props;
    const SelectDate = (value) => {
        setFieldValue("business_est_date",value)

    }
console.log(values)
    const sameAs = () => {
        setFieldValue("billing_company_name",values.company_name)

    }
    return (
        <React.Fragment>
            <Typography className="mb-10" variant="h4" align="center"s>
                {props.language.PROFILEVIEW_COMPANY_INFORMATION}
            </Typography>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <InputField
                        name={company_name.name}
                        label={props.language.PROFILE_COMPANY_NAME}
                        fullWidth
                        disabled={true}
                    />
                </Grid>
               
                <Grid item xs={12} sm={7}>
                    <Grid container alignItems="center" spacing={1}>
                        <div className="text-left mt-6 ml-4">
                            <InputLabel className="text-dark font-size-h6 font-weight-bold">
                                {props.language.PROFILE_COMPANY_ADDRESS}
                            </InputLabel>
                            <hr className="w-75 ml-0 mt-2 mb-5"/>
                        </div>
                        <Grid className="text-right" item xs={12} sm={12}>
                            <InputField
                                name={billing_company_name.name}
                                label={props.language.PROFILE_BILLING_COMPANY_NAME}
                                fullWidth
                            />
                            <span className="text-primary mb-2 cursor-pointer text-right mr-4" onClick={()=>sameAs()}>Same as company name</span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={address1.name}
                                label={props.language.PROFILE_COMPANY_ADDRESS1}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={address2.name}
                                label={props.language.PROFILE_COMPANY_ADDRESS2}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={address3.name}
                                label={props.language.PROFILE_COMPANY_ADDRESS3}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={city.name}
                                label={props.language.PROFILE_CITY}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <SelectField
                               name={state.name}
                               label={props.language.PROFILE_STATE}
                               data={state_data}
                               fullWidth
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <InputField
                                name={state.name}
                                label={props.language.PROFILE_STATE}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField
                                name={zipcode.name}
                                label={props.language.PROFILE_ZIP}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={country.name}
                                label={props.language.PROFILE_COUNTRY}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                    {/* <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_TELEPHONE}
                    </InputLabel> */}
                    <PhoneInput name={telephone.name} label={props.language.PROFILE_TELEPHONE} fullWidth onChange={(value) => setFieldValue("telephone",value)} />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField name={fax.name} label={props.language.PROFILE_FAX} fullWidth />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={invoice_email.name}
                        label={props.language.PROFILE_INVOICE_EMAIL}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={website.name}
                        label={props.language.PROFILE_WEBSITE}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={comp_reg_no.name}
                        label={props.language.PROFILE_COMPREGNO}
                        fullWidth
                    />
                </Grid>
                
                <Grid className="mui-uploader" item xs={12} sm={7}>
                    <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_COMPREGNO_DOC}
                    </InputLabel>
                    <MuiUploader
                        name={comp_reg_no_file.name}
                        label={comp_reg_no_file.label}
                        fullWidth
                        setThirdFile={setThirdFile}
                        filefield={"reg_no"}
                        setFieldValue={props.setFieldValue}
                        setCompanyRegName={setCompanyRegName}
                        values={values}
                    />
                   
                </Grid>
                <Grid item xs={12} sm={7}>
                    <SelectField
                        name={nature_of_business.name}
                        label={props.language.PROFILE_NOB}
                        data={nature_of_business_data}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        type="date"
                        name={business_est_date.name}
                        label={props.language.PROFILE_BED}
                        fullWidth
                        onChange={(e) => SelectDate(e.target.value)}
                        value={values.business_est_date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid className="position-relative" item xs={12} sm={7}>
                    <InputField
                        name={tax_id.name}
                        label={props.language.PROFILE_TAX}
                        fullWidth
                    />
                    {/* <span style={{position:"absolute",top:"28px",right:"28px"}}>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip > Importer's Reference Number </Tooltip>} >
                            <FaInfoCircle className="font-size-h5 text-black-50"/>
                        </OverlayTrigger>
                    </span> */}
                </Grid>
                <Grid item xs={12} sm={7}>
                    {/* <hr className="w-100" />
                    <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_OWNER_DETAILS}
                    </InputLabel> */}
                    <div className="text-left mt-6 ml-4">
                            <InputLabel className="text-dark font-size-h6 font-weight-bold">
                                {props.language.PROFILE_OWNER_DETAILS}
                            </InputLabel>
                            <hr className="w-25 ml-0 mt-2 mb-5"/>
                        </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={name_of_partner.name}
                        label={props.language.PROFILE_OWNER_NOP}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField name={passport_id.name} label={props.language.PROFILE_IDENTITY_NUMBER} fullWidth />
                </Grid>
                <Grid className="mui-uploader" item xs={12} sm={7}>
                    <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_IDENTITY_PROOF}
                    </InputLabel>
                    <MuiUploader
                        name={passport_id_file.name}
                        label={passport_id_file.label}
                        data={passport_id_file}
                        FirstFile={FirstFile}
                        filefield={"passport_id_file"}
                        setFirstFile={setFirstFile}
                        setPassportFileName={setPassportFileName}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                    
                </Grid>
                <Grid item xs={12} sm={7}>
                    {/* <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_OWNER_CN_DPP}
                    </InputLabel> */}
                    <PhoneInput name={contact_of_partner.name} label={props.language.PROFILE_OWNER_CN_DPP} fullWidth onChange={(value) => setFieldValue("contact_of_partner",value)}/>
                </Grid>
                
                
            </Grid>
        </React.Fragment>
    );
}
