/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useState} from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import CertificateLink from "../CertificateLink";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};


export const DiamondDetails = (props) => {
    const [data] =  useState(props.data)
    const history = useHistory();

    return (
        <>
            <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll w-100"
                style={{
                    maxHeight: "450px",
                    position: "relative",
                }}
            >
                { data.map((data)=>(
                    <div key={data.stockID} className="d-flex mb-4 pb-4 px-4 border-bottom justify-content-between">
                        <div className="d-flex">
                            <div className="text-center p-0">
                                {data.diamondType === "PN" || data.diamondType === "PL" ?(
                                    <img
                                        className="img-fluid mr-2"
                                        alt={data.shape}
                                        src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${data.shape.toLowerCase()}.svg`)}
                                        width="50px"
                                        onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/svg_shape/parcel/other.svg`
                                            )
                                        }}
                                    />
                                ):data.image ? (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={data.shape}
                                        src={data.image}
                                        width="100px"
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/${data.shape ? data.shape.toLowerCase() : ""}-no.png`
                                                )
                                            }}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={data.shape}
                                        src={toAbsoluteUrl(
                                            `/media/shape/${data.shape && data.shape.toLowerCase()}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                        width="100px"
                                        style={{ backgroundColor: "#f4f4f4" }}
                                    />
                                )}
                            </div>
                            {data.diamondType === "PN" || data.diamondType === "PL"?
                            <div className="pl-2 p-0 text-left">
                                <div className={`mb-1 ${data.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${data.diamondType}_diamond mr-3 small`}>
                                {data.diamondType}
                            </span>
                            <span className={` cursor-pointer small ml-2`}>
                                {data.diamondType}-{data.stockID}
                            </span>
                            <span className={` text-capitalize cursor-pointer small ml-2`}>
                                By: {data.parcelType}
                            </span>
                            <span className={` cursor-pointer small ml-2`}>
                            {data.parcelLength?data.parcelLength.toFixed(2):"" }-{data.parcelWidth?data.parcelWidth.toFixed(2):"" } MM
                            </span>
                        </div>
                        <span className="text-dark font-weight-bold d-block">
                        {data.shape}{" "}
                            {data.weight?data.weight.toFixed(2):""}Ct.{" "}
                            {data.parcelPieces}Pcs.{" "}
                            {data.color}{" "}
                            {data.clarity}{" "}
                        </span>
                                <div className="mt-3">
                                    <span className="font-weight-bold mr-3 ">{props.language.SEARCHLIST_LOCATION}:</span> {data.country}
                                    
                                </div>
                               
                            </div>:<div className="pl-2 p-0 text-left">
                                <div className={`mb-1 ${data.diamondType === "N" ? "natTab" : "labTab"}`}>
                                    <span className={`${ data.diamondType === "N" ? data.isfancy ? "F" : "W" : data.isfancy ? "LF" : "L"  }_diamond mr-3 small`}>
                                        {data.diamondType === "N" || data.diamondType === "W" ? data.isfancy  ? "NF" : "N" : data.isfancy ? "LF" : "L"}
                                    </span>
                                    <span onClick={() => history.push({ pathname: `/detail/${data.diamondType === "N" ? "N" : "L"}-${data.stockID}` })} className={` cursor-pointer small ml-2`}>
                                        {data.diamondType === "N" ? "N" : "L"}-{data.stockID}
                                    </span>
                                    <CertificateLink Certificate_link={data.Certi_link} lab={data.Lab} certificate={data.Certi_NO}  className={`mx-2 text-dark small`} />
                                </div>
                                <span className="text-dark font-weight-bold d-block">
                                    {data.shape}{" "}
                                    {data.weight?.toFixed(2)}{" "}
                                    {data.color}{" "}
                                    {data.clarity}{" "}{data.cut}{" "}{data.polish}{" "}{data.symmetry}{" "}
                                    {data.fluorescence}
                                </span>
                                <div className="mt-3">
                                    <span className="font-weight-bold mr-3 ">{props.language.SEARCHLIST_LOCATION}:</span> {data.country}
                                    
                                    <img
                                        className="flagIco mr-1"
                                        alt={data.country}
                                        src={toAbsoluteUrl(
                                            `/media/map/${data.country.toLowerCase()}.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src =toAbsoluteUrl(
                                                `/media/map/other.png`
                                                )
                                            }}
                                    />
                       
                                </div>
                                {/* <div className="mt-3">
                                    <span className="font-weight-bold mr-3">Delivery Days:</span> {data.shipping} days
                                </div> */}
                            </div>}
                            
                        </div>
                        <div>
                            <div className="text-right">
                                <p className="text-muted mb-2">$/CT</p>
                                <p className="text-dark font-weight-bold">${data["ourPriceExchange"]}</p>
                            </div>
                            <div className="text-right">
                                <p className="text-muted mb-1">{props.language.SEARCHGRID_TOTAL}</p>
                                <p className="text-dark font-weight-bold">${data["ourRateExchange"]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </PerfectScrollbar>
            <div className="mt-3"> {data.length > 3 ? <span className=" font-weight-bold">Scroll for more</span>: ""} </div>
        </>
    );
};
