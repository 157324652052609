/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Scrollspy from 'react-scrollspy'
import { FaDownload } from 'react-icons/fa';
import { KTCodeExample} from "../controls";
import { toAbsoluteUrl } from "../../_helpers";
// import { BASE_URL } from "../../../env_config";


export const ApiDocumentationEU = ({language}) => {


    return (
        <div className="container-fluid py-8 bg-white">
            <div className="row">
                <div className="col-12 d-md-flex justify-content-between mb-5">
                    <span className="font-size-h4 font-weight-bold">API Documentation</span>
                    {/* <a href={`https://assets.diamondsoncall.com/assets/API_Documentation_EU.pdf`} className="btn btn-secondary"><FaDownload className="mr-2"/>Download Document</a> */}
                </div>
                <div className="col-12">
                    <div className="row api-doc-section">
                        <div className="col-md-4 col-lg-3 col-xl-2 border-right">
                            <div className="sticky-top" style={{top:'100px'}}>
                                <Scrollspy className="profile-scrollspy" items={ ['Introduction', 'Authentication', 'Diamond-result', 'Diamond-Detail', 'Check-Availability', 'Hold', 'Confirm', 'Errors', 'Test-API'] } currentClassName="is-current">
                                    <li><a href="#Introduction">Introduction</a></li>
                                    <li><a href="#Authentication">Authentication</a></li>
                                    <li><a href="#Diamond-result">Diamond Result</a></li>
                                    <Scrollspy className="profile-scrollspy ml-8" items={ ['Natural-lab-Diamond', 'parameters', 'filter', 'sorting', 'fancy-color'] } >
                                        <li><a href="#Natural-lab-Diamond">Natural and Lab-grown Diamond</a></li>
                                        <li><a href="#parameters">Parameters</a></li>
                                        <li><a href="#filter">Filter</a></li>
                                        <li><a href="#sorting">Sorting</a></li>
                                        <li><a href="#fancy-color">Fancy Color</a></li>
                                        <li><a href="#lab-diamond">Lab Diamond</a></li>
                                    </Scrollspy>
                                    <li><a href="#Diamond-Detail">Diamond Detail</a></li>
                                    <li><a href="#Check-Availability">Check Availability</a></li>
                                    <li><a href="#Hold">Hold</a></li>
                                    <li><a href="#Confirm">Confirm Diamond</a></li>
                                    <li><a href="#Count">Count API</a></li>
                                    <li><a href="#Errors">Errors</a></li>
                                    <li><a href="#Test-API">Test API</a></li>
                                </Scrollspy>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10 mt-3">
                            <section id="Introduction">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace d-inline-block"></div>Introduction</h3>
                                <div className="">
                                    <p className="mb-2">The Diamonds On Call API is organized around <strong>REST</strong>. Our API has predictable resource-oriented URLs, accepts&gt;form-encoded request bodies, returns <strong>JSON-encoded</strong> responses, and uses standard HTTP response codes, authentication, and verbs.</p>
                                    <p className="mb-2">You can activate test mode using API Setting, which does not affect your live data or interact with suppliers. The API key you use to <strong>authenticate</strong> the request determines whether the request is an active mode or test mode.</p>
                                    <p className="mb-1"><strong>Base URL: </strong> <a href="https://api.eu.diamondsoncall.com/feed" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed</a></p>
                                    <p className="mb-2"><strong>Base Test URL: </strong> <a href="https://api.testrun3.diamondsoncall.com/feed" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed</a></p>
                                    <p className="mb-2">The Diamonds on call API Docs demonstrate using curl to interact with the API over HTTP.</p>
                                    <p className="mb-2 font-size-h6 text-primary">(USE TEST API URL TO TEST THE API WHILE SETTING UP) </p>
                                </div>
                            </section>
                            <section className='mt-10' id="Authentication">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace d-inline-block"></div>Authentication</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>The Diamonds On Call API uses API keys to authenticate requests. You can view and manage your API Key using the API setting under Diamond Feed Centre.</p>
                                        <p className="mb-2"> Once you generate the Key, you will need to generate the token using the URL below and key.</p> 
                                        <p className="mb-1"> <strong>URL:</strong> <a href="https://api.eu.diamondsoncall.com/EUR/liveAPILogin" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/EUR/liveAPILogin</a></p>
                                        <p className="mb-2"> <strong>Test URL:</strong> <a href="https://api.testrun3.diamondsoncall.com/TESTMODE/testAPILogin" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/TESTMODE/testAPILogin</a></p>
                                        <p> Your API key and token carry many privileges, so be sure to keep them secure. The secret API key and token should not be shared in publicly accessible areas such as GitHub, client-side code, and so forth. We at Diamonds on Call allow you to keep the API key secure by allowing you to generate new keys by clicking on Generate key under the API setting, also you can generate a new token every time you run the authentication process</p>
                                        <p> Authentication to the API is performed via <strong>HTTP Basic Auth</strong>. Provide your API key as the basic auth username value. You do not need to provide a password.</p>
                                        <p>All API requests must be made over <strong>HTTPS</strong>. Calls made over plain HTTP will fail. API requests without authentication will also fail.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={AuthenticationInput} beforeCodeTitle="Authorization Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={AuthenticationResponse} beforeCodeTitle="Authorization Response Code" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section  className='mt-10' id="Diamond-result">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace d-inline-block"></div>Diamond-result</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <section  className='mt-10' id="Natural-lab-Diamond">
                                            <h4 className="mb-5 border-bottom text-success"><div className="anchorspace d-inline-block"></div>Natural & Lab-Grown Diamonds</h4>
                                            <p>The Diamond API is divided into two parts Natural Diamond API and Lab Grown Diamonds API. Both APIs can be fetched through the following URL using the POST Method.</p>
                                            <p className="mb-1"><strong className="text-warning">Natural Diamond: </strong> <a href="https://api.eu.diamondsoncall.com/feed/naturaldiamonds" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/naturaldiamonds</a></p>
                                            <p className="mb-3"><strong className="text-warning">Lab Grown Diamond: </strong><a href="https://api.eu.diamondsoncall.com/feed/labgrowndiamonds" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/labgrowndiamonds</a></p>
                                            <p className="mb-1"><strong className="text-primary">Natural Diamond: </strong> <a href="https://api.testrun3.diamondsoncall.com/feed/naturaldiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/naturaldiamonds</a></p>
                                            <p className="mb-3"><strong className="text-primary">Lab Grown Diamond: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/labgrowndiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/labgrowndiamonds</a></p>
                                            <p>These API will give you results for all diamonds, including all fancy shapes and fancy colors. API works in pagination format, where you will get 100 stones on each page, and one can get to next page by putting page number in the request body. API is designed in such a way to help you sort and filter all the necessary fields to provide you an accurate response.</p>
                                        </section>
                                        <section  className='mt-10' id="parameters">
                                            <h4 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Parameters</h4>
                                            <p>Following are the parameters for the <b>INPUTS</b></p>
                                            {/* <code className="api-doc_para">"stock_id"</code>
                                            <p className="m-0">Example Inputs: 1243xx, 3252xx4</p>
                                            <p className="mb-2">You can request multiple stock id in a single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            {/* <code className="api-doc_para">"certificate_no"</code>
                                            <p className="m-0">Example Inputs : 3435623456,2352523454</p>
                                            <p className="mb-2">You can request multiple certificates numbers in single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            <code className="api-doc_para">"Shape"</code>
                                            <p className="mb-2"><em><span className="text-success">Accepted Inputs:</span> : Round,Radiant,Princess,Pear,Oval,Marquise,Heart,Emerald,Cushion,Asscher,Square Radiant,Trilliant,Cushion modified,Triangle.</em><br /> You can request multiple shapes in a single run (optional)
                                            </p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Color"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span> D, E, F, G, H, I, J, K, L, M, N, OP, QR, ST, UV, WX, YZ</em></p>
                                            <p className="mb-2">You can request multiple colors in a single run (mandatory)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Clarity"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span> FL, IF, VVS1, VVS2, VS1, VS2, SI1, SI2, I1, I2</em></p>
                                            <p className="mb-2">You can request multiple Clarity in a single run (mandatory)</p>
                                            <hr className="w-100" />
                                            {/* <code className="api-doc_para">"carat"</code>
                                            <p className="m-0">Example Inputs: 1.05-1.05, 2.00-9.99, 0.70-0.75</p>
                                            <p className="mb-2">Carat need to be called in range even you request for specific carat size <br /> You can request multiple carat ranges in a single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            <code className="api-doc_para">"Cut"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span > ID, EX, VG, GD, FR, PR</em></p>
                                            <p className="mb-2">You can request multiple cut in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Polish"</code>
                                            <p className="m-0"> <em><span className="text-success">Accepted Inputs:</span>EX, VG, GD, FR, PR</em></p>
                                            <p className="mb-2">You can request multiple polish in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Symmetry"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span> EX, VG, GD, FR, PR</em></p>
                                            <p className="mb-2">You can request multiple symmetries in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Fluorescence"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span> NON, SLT, VSLT, FNT, MED, STG, VST</em></p>
                                            <p className="mb-2">You can request multiple fluorescence in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Lab"</code>
                                            <p className="m-0"> <em><span className="text-success">Accepted Inputs:</span> GIA, HRD, IGI, AGS, GCAL</em></p>
                                            <p className="mb-2">You can request multiple labs in a single run (optional)</p>
                                            {/* <hr className="w-100" />
                                            <code className="api-doc_para">"Shade"</code>
                                            <p className="m-0">Accepted Inputs: None, Brown, Green, Grey, Black, Blue, Pink, Light Brown, Light Green, Mix tinge</p>
                                            <p className="mb-2">You can request multiple shade in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"Milky"</code>
                                            <p className="m-0">Accepted Inputs: No Milky, Light Milky, Milky</p>
                                            <p className="mb-2">You can request multiple Milky in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"eye_clean"</code>
                                            <p className="m-0">Accepted Inputs: Yes, No</p>
                                            <p className="mb-2">No will include results for Not Eye Clean & also for No Data Available<br /> You can request one option in a single run (optional)</p>
                                            <hr class="w-100" />
                                            <code class="api-doc_para">"currency"</code>
                                            <p class="m-0">Accepted Inputs: USD,HKD,AUD,EUR,CAD,CNY,GBP,NZD,JPY,CEF</p>
                                            <p class="mb-2">You can request one option in a single run (optional)<br />If you do not input anything default currency is USD</p> */}
                                        </section>
                                        <section  className='mt-10' id="filter">
                                            <h4 className="mb-5 border-bottom text-warning"><div className="anchorspace d-inline-block"></div>Filters</h4>
                                            <p className="bold m-1 text-primary"><u> <b>Carat</b></u></p>
                                            <code className="api-doc_para">"min_carat" "max_carat"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs:</span> min_carat=0.18 max_carat = 5.62</em></p>
                                            <p className="mb-2">Carat need to be called in two different variable for 'min_carat' for Minimum carat size and 'max_carat' for maximum carat size</p>
                                            <hr className="w-100" />
                                            <p className="bold m-1 text-primary"><u><b>Total Price: Filter</b></u></p>
                                            <code className="api-doc_para">"total_price_from" "total_price_to"</code>
                                            <p className="m-0"><em><span className="text-success">Example Input:</span> total_price_from =2000, total_price_to =3000</em></p>
                                            <p className="mb-2">This will give you result for all diamonds between 2000EU$ & 3000EU$ total price in the currency you have selected while setting up the rules.</p>
                                            <hr className="w-100" />
                                            <p className="bold m-1 text-primary"><u><b>Ratio: Filter</b></u></p>
                                            <code className="api-doc_para">"ratiomin" "ratiomax"</code>
                                            <p className="m-0"><em> <span className="text-success">Example Input:</span>ratiomin =1.00, ratiomax =1.50</em></p>
                                            <p className="mb-2">This will give you result for all diamonds with ratio between 1.00 to 1.50</p>
                                            <hr className="w-100" />
                                            <p className="bold m-1 text-primary"><u><b>Table & Depth Percentage: Filter</b></u></p>
                                            <code className="api-doc_para">"tablemin" "tablemax"</code><br/>
                                            <code className="api-doc_para mt-1">"depthmin" "depthmax"</code>
                                            <p className="m-0 mt-2"> 
                                                <em><span className="text-success">Example Input:</span><br />
                                                tablemin=56, tablemax=58</em><br />
                                                <em>depthmin=60, depthmax=62</em>
                                            </p>
                                            <p className="mb-2">This will give you result for all diamonds with table percentage between 56% & 58% & depth percentage 60% to 62%. (optional)</p>
                                            <hr className="w-100" />
                                            {/* <p className="bold m-0"><u>Measurements (MM) : Filter</u></p>
                                            <code className="api-doc_para">"length_min" "length_max"</code>
                                            <code className="api-doc_para">"width_min" "width_max"</code>
                                            <code className="api-doc_para">"depth_min" "depth_max"</code>
                                            <p className="m-0">Example Input: length_min =5.5, length_max =5.6</p>
                                            <p className="mb-2">This will give you result for all diamonds with measurement length between 5.5MM & 5.6MM.<br />You can request one option for each in a single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            {/* <p className="bold m-0"><u>Table & Depth Percentage : Filter</u></p>
                                            <code className="api-doc_para">"table_per_from" "table_per_to"</code>
                                            <code className="api-doc_para">"depth_per_from" "depth_per_to"</code>
                                            <p className="m-0">Example Input : table_per_from=56, table_per_to=58</p>
                                            <p className="mb-2">This will give you result for all diamonds with table percentage between 56% & 58%<br />You can request one option for each in single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            {/* <p className="bold m-0"><u>Crown - Pavilion Angle & Height : Filter</u></p>
                                            <code className="api-doc_para">"crown_angle_from" "crown_angle_to"</code><br />
                                            <code className="api-doc_para">"crown_height_from" "crown_height_to"</code><br />
                                            <code className="api-doc_para">"pavilion_angle_from" "pavilion_angle_to"</code><br />
                                            <code className="api-doc_para">"pavilion_height_from" "pavilion_height_from"</code>
                                            <p className="m-0">Example Input : crown_height_from=13.5, crown_height_to=158</p>
                                            <p className="mb-2">This will give you result for all diamonds with Crown height between 13.5% & 15%<br />You can request one option for each in single run (optional)</p>
                                            <hr className="w-100" /> */}
                                            {/* <p className="bold m-0"><u>IMAGE & VIDEO: FILTER</u></p>
                                            <code className="api-doc_para">"image_video"</code>
                                            <code className="api-doc_para">"depth_per_from" "depth_per_to"</code>
                                            <p className="m-0">Example Input : table_per_from=56, table_per_to=58</p>
                                            <p className="mb-2">To filter image and video, use this parameter<br />
                                            only video enter <strong>"1"</strong><br />
                                            only image enter <strong>"2"</strong><br />
                                            both image videos enter <strong>"3"</strong><br />
                                            image or video, whichever available enter <strong>"4"</strong><br />
                                            You can request one option for each in single run (optional)<br />
                                            (if you do not input anything, you will get all results without any filter)</p> */}
                                        </section>
                                        <section  className='mt-10' id="sorting">
                                            <h4 className="mb-5 border-bottom text-waning"><div className="anchorspace d-inline-block"></div>Sorting</h4>
                                            <p>These input parameters help you sort specific parameters like Carat, Color, Clarity, Total Price & Ratio in ascending (asc) or descending (desc) manner.</p>
                                            <code className="api-doc_para">"Sort_field"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs :</span> carat, color, clarity, price, ratio</em></p>
                                            <p className="mb-2">You can request one option in a single run (optional)</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"sort_order"</code>
                                            <p className="m-0"><em><span className="text-success">Accepted Inputs</span > : asc, desc</em></p>
                                            <p className="mb-2">You can request one option in a single run (optional)</p>
                                            <p className="m-0 mt-3"> 
                                                <em><span className="text-success">Example Input:</span><br />
                                                sort_field = color</em><br />
                                                <em>sort_order = desc</em>
                                                <em>The above input will give you result of color in descending order.</em>
                                            </p>
                                        </section>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={NaturalLabInput} beforeCodeTitle="Natural White Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={NaturalLabResponse} beforeCodeTitle="RESPONSE DATA" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <section  className='mt-10' id="fancy-color">
                                            <h4 className="mb-5 border-bottom text-warning"><div className="anchorspace d-inline-block"></div>Fancy color</h4>
                                            <p>To request fancy color, please pass the "<em><b>yes</b></em>" parameter as below.</p>
                                            {/* <code className="api-doc_para">"fancy_color_diamond"</code> = Yes
                                            <p className="mb-2">once you pass the above parameter, you will be able to pass input for the below parameters</p>
                                            <hr className="w-100" /> */}
                                            <code className="api-doc_para">"fancy_color"</code>
                                            <p className="mb-2">Yellow, Pink, Blue, Red, Green, Purple, Orange, Violet, Gray, Black, Brown, Champagne, Cognac, Chameleon, White</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"fancy_intensity"</code>
                                            <p className="mb-2">faint, very light, light, fancy light, fancy, fancy dark, fancy intense, fancy vivid, fancy deep</p>
                                            <hr className="w-100" />
                                            <code className="api-doc_para">"fancy_overtone"</code>
                                            <p className="mb-0">none, yellow, yellowish, pink, pinkish, blue, blueish, red, reddish, green, greenish, purple, purplish, orange, orangy, violet, violetish, gray, grayish, black, brown, brownish, champagne, cognac, chameleon, white</p>
                                            <p className="mb-2">You can request multiple options in a single run (optional)</p>
                                            <hr className="w-100" />
                                        </section>
                                    </div>
                                    <div className="col-md-6 mt-15">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={FancyColorInput} beforeCodeTitle="Natural Fancy Color - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={FancyColorResponse} beforeCodeTitle="Fancy Color Sample Response Data" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <section  className='mt-10' id="lab-diamond">
                                            <h4 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Lab Diamond</h4>
                                            <p>Similar to above data for natural diamonds, Lab Grown diamonds have same parameters , filters & sorting Blow is the sample data.</p>
                                            <hr className="w-100" />
                                        </section>
                                    </div>
                                    <div className="col-md-6 mt-15">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={LabDiamondInput} beforeCodeTitle="Lab Grown White - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={LabDiamondResponse} beforeCodeTitle="Lab Grown White - Sample Response" classname="response-code"></KTCodeExample>
                                            <KTCodeExample jsCode={LabFancyColorInput} beforeCodeTitle="Lab Grown Fancy Color - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={LabFancyColorResponse} beforeCodeTitle="Lab Grown Fancy Color - Sample Response" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Diamond-Detail">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace d-inline-block"></div>Diamond Detail</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="mb-1"> <strong>URL:</strong> <a href="https://api.eu.diamondsoncall.com/feed/diamonddetail" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/diamonddetail</a></p>
                                        <p className="mb-1"> <strong>Test URL:</strong> <a href="https://api.testrun3.diamondsoncall.com/feed/diamonddetail" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/diamonddetail</a></p>
                                        <p>The use of this API is to Fetch the Full Details of the diamond. It provides you all the extra parameters, Heart & arrow images and aset images.</p>
                                        <p>You can use the same API for natural or Lab Grown Diamond.</p>
                                        <p>You can get data using either a Certificate number or stock ID.</p>
                                        <p className="callout callout-danger font-weight-bold">Note: You can fetch up to 25 diamonds at a time.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={diamondDetailsInput} beforeCodeTitle="Diamond Details - Sample Input Code " classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={diamondDetailsResponse} beforeCodeTitle="Diamond Details - Sample Response" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Check-Availability">
                                <h3 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Check Availability</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong><a href="https://api.eu.diamondsoncall.com/feed/checkavailability" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/checkavailability</a> </p>
                                        <p><strong>Test URL: </strong> <a href="https://api.eu.diamondsoncall.com/feed/checkavailability" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/checkavailability</a> </p>
                                        <p>This API will allow you to check the availability of the diamond.</p>
                                        <p>In Response, you will also get Carat, Price per carat & total price. So you can verify the details before selling the diamond to your customer.</p>
                                        <p>You can request check availability API either using Certificate No. or Stock ID.</p>
                                        <p>You can request multiple diamonds in single a run.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={CheckAvailabilityInput} beforeCodeTitle="Check Availability - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={CheckAvailabilityResponse} beforeCodeTitle="Check Availability - Sample Response" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Hold">
                                <h3 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Hold Diamond</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p><strong>URL: </strong> <a href="https://api.eu.diamondsoncall.com/feed/holddiamonds" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/holddiamonds</a></p>
                                        <p><strong>Test URL: </strong> <a href="https://api.testrun3.diamondsoncall.com/feed/holddiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/holddiamonds</a></p>
                                        <p> Hold Diamond API will allow you to hold diamonds directly through API with certificate number or stock id.</p>
                                        <p>You can request to hold multiple diamonds in a single run. You can request to hold with either Stock ID or Certificate Number</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={HoldInput} beforeCodeTitle="Hold Diamonds - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={HoldResponse} beforeCodeTitle="Hold Diamonds - Sample Response" classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Confirm">
                                <h3 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Confirm Diamond</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                    <p><strong>URL: </strong> <a href="https://api.eu.diamondsoncall.com/feed/confirmdiamonds" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/confirmdiamonds</a></p>
                                    <p><strong>Test URL: </strong> <a href="https://api.testrun3.diamondsoncall.com/feed/confirmdiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/confirmdiamonds</a></p>
                                        <p>Confirm Diamond API will allow you to confirm diamonds directly through API with certificate number or stock id. </p>
                                        <p>You can request to confirm multiple diamonds in a single run. You can request to confirm with either Stock ID or Certificate Number</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={ConfirmInput} beforeCodeTitle="Confirm Diamonds  - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={ConfirmResponse} beforeCodeTitle="Confirm Diamonds - Sample Response " classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Count">
                                <h3 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Count API</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                    <p><strong>URL: </strong> <a href="https://api.eu.diamondsoncall.com/feed/diamondcount" target="_blank" rel="noreferrer noopener">https://api.eu.diamondsoncall.com/feed/diamondcount</a></p>
                                    <p><strong>Test URL: </strong> <a href="https://api.testrun3.diamondsoncall.com/feed/diamondcount" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/diamondcount</a></p>
                                        <p>Diamonds Count API will allow to get total number of stones is API Input code of Natural or Lab Grown Diamonds. This API is used when you want to show total number of diamond count when your customer searches on website. </p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sticky-top" style={{top:'100px'}}>
                                            <KTCodeExample jsCode={CountApiInput} beforeCodeTitle="Count API  - Sample Input Code" classname="input-code"></KTCodeExample>
                                            <KTCodeExample jsCode={CountApiResponse} beforeCodeTitle="Count API - Sample Response " classname="response-code"></KTCodeExample>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Errors">
                                <h3 className="mb-5 border-bottom"><div className="anchorspace d-inline-block"></div>Errors</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Diamonds On Call Diamond Feed uses conventional HTTP response codes to indicate the success or failure of an API request. </p>
                                        <p>In general: Codes in the 2xx range indicate success. Codes in the 4xx range indicate an error that failed & Codes in the 5xx range indicate an error with Diamonds On Call's servers. </p>
                                        <table className="table table-responsive-sm  table-bordered">
                                            <tbody>
                                                <tr className="text-white bg-primary">
                                                    <th className="text-left align-middle text-white">Error Codes</th>
                                                    <th className="text-left align-middle text-white">Description</th>
                                                </tr>
                                                <tr>
                                                    <td className="text-left align-middle  bg-primary-o-30 border-primary"><b>200 - OK</b></td>
                                                    <td className="text-left align-middle  bg-primary-o-30 border-primary"><div className="py-2">Everything worked fine.</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left align-middle border-primary"><b>400 - Bad Request</b></td>
                                                    <td className="text-left align-middle border-primary"><div className="py-2">The request was unacceptable, often due to missing a required parameter.</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left align-middle  bg-primary-o-30 border-primary"><b>401 - Unauthorized</b></td>
                                                    <td className="text-left align-middle  bg-primary-o-30 border-primary"><div className="py-2">No valid API key provided.</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left align-middle border-primary"><b>404 - Not Found</b></td>
                                                    <td className="text-left align-middle border-primary"><div className="py-2">The requested resource doesn't exist.</div></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left align-middle border-primary bg-primary-o-30"><b>500, 502, 503, 504 - Server Errors</b></td>
                                                    <td className="text-left align-middle border-primary bg-primary-o-30"><div className="py-2">Something went wrong on Diamonds On Call&rsquo;s end. </div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p><b>Following are the error messages displayed with the description for all API.</b> </p>
                                        <table className="table table-responsive-sm table-bordered ">
                                            <thead >
                                                <tr className="text-white bg-primary">
                                                    <th className="text-left text-white">Error Types </th>
                                                    <th className="text-left text-white">Description </th>
                                                    <th className="text-left text-white">Error Messages Displayed </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left border-primary bold bg-primary-o-30">Authentication Failed</td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        When API key is not added by the customer<br/>
                                                        <code>{`{"apikey":"312645XXXX" }`}</code>
                                                    </td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        <code>
                                                            {`{
                                                                "Success": false,
                                                                "Message": "Please enter api key."
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bold border-primary">Authentication Failed</td>
                                                    <td className="text-left border-primary">When the token is missing </td>
                                                    <td className="text-left border-primary">
                                                        <code>
                                                            {`{
                                                                "Message": "Unauthenticated."
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-primary-o-30 border-primary"></td>
                                                    <td className="text-left border-primary bg-primary-o-30"> When API key is invalid / Re-generate / Delete </td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        <code>
                                                            {`{
                                                                "Success": false,
                                                                "Error": "Unauthorized"
                                                            }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-primary"></td>
                                                    <td className="text-left border-primary"> When The API key doesn't have permissions to perform the request. </td>
                                                    <td className="text-left border-primary">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Error": "Your API temporarily suspended. 
                                                            Please contact to your sales representative."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left border-primary bold bg-primary-o-30">Common Errors</td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        When the certificate number or stock Id requested is empty<br /><br />
                                                        <code> {`array(Certi_No' => '',StockID' => '')`} </code>
                                                    </td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Message": " Please enter either certificate number or stock id."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-primary"></td>
                                                    <td className="text-left border-primary">
                                                        When you request both Certificate number and Stock ID<br /> <br />
                                                        <code>{` array('Certi_No' => '3242t52asd','StockID' => '11322123')`}</code>
                                                    </td>
                                                    <td className="text-left border-primary">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Message": "Please enter either certificate number or stock id."
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left border-primary bold bg-primary-o-30">Something went wrong</td>
                                                    <td className="text-left border-primary bg-primary-o-30">If during the process of requesting data, suddenly you lose connection or it stops for any other reason; it will show something went wrong. </td>
                                                    <td className="text-left border-primary bg-primary-o-30">
                                                        <code>
                                                        {`{
                                                            "Success": false,
                                                            "Status": fail,

                                                            "Message": "Something Went Wrong"
                                                        }`}
                                                        </code>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                            <section className='mt-10' id="Test-API">
                                <h3 className="mb-5 border-bottom text-primary"><div className="anchorspace d-inline-block"></div>Test API</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5 className="bold mb-3 text-primary">Activate Test Mode</h5>
                                        <p>You can Activate the test mode under API Setting, as marked in the image below. Once you activate the test mode, it will display you test API key for you to test. Test API key will expire in 7 days.</p>
                                        <figure className="text-center">
                                            <img src={toAbsoluteUrl("/media/General/api_setting_ss.jpg")} alt="api_setting_ss" border="0" className="mx-auto" />
                                            {/* <figcaption className="pt-1"><i></i></figcaption> */}
                                        </figure>
                                        <h5 className="bold mb-3">Use following URL for API testing</h5>
                                        <p className="mb-1"><strong>Authorization: </strong> <a href="https://api.testrun3.diamondsoncall.com/TESTMODE/testAPILogin?apikey=2" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/TESTMODE/testAPILogin?apikey=2</a></p>
                                        <p className="mb-1"><strong>Natural Diamonds: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/naturaldiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/naturaldiamonds</a></p>
                                        <p className="mb-1"><strong>Lab Grown Diamonds: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/labgrowndiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/labgrowndiamonds</a></p>
                                        <p className="mb-1"><strong>Confirm Diamonds: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/confirmdiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/confirmdiamonds</a></p>
                                        <p className="mb-1"><strong>Hold Diamonds: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/holddiamonds" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/holddiamonds</a></p>
                                        <p className="mb-1"><strong>Check Availability: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/checkavailability" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/checkavailability</a></p>
                                        <p className="mb-4"><strong>Diamond Detail: </strong><a href="https://api.testrun3.diamondsoncall.com/feed/diamonddetail" target="_blank" rel="noreferrer noopener">https://api.testrun3.diamondsoncall.com/feed/diamonddetail</a></p>
                                        <h5 className="bold mb-3 text-primary">Get Testing Credentials</h5>
                                        <p>To check the data for test API, Click on “GET CREDETIALS” under API setting, this will send you email for credentials for login at <a href="https://testrun3.diamondsoncall.com/" target="_blank" rel="noopener noreferrer">https://testrun3.diamondsoncall.com/</a>. This will help you to see your hold and confirm diamonds when you process through Testing API.</p>
                                        <h5 className="bold mb-3">Deactivate Test Mode</h5>
                                        <p>You can deactivate test API using same button in API setting, as marked in image above. Once you deactivate test API, you test API key and login credentials for testing will not work. You can activate again to regenerate the same. </p>
                                        
                                    </div>
                                    
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const AuthenticationInput = `
    curl --location ' https://api.eu.diamondsoncall.com/EUR/liveAPILogin' \\
    --header 'Content-Type: application/json' \\
    --data '{
        "apikey": "cae2f60b5016503106abbdf802xxxxxxxxxxxxx7c330632f2c78e3e7f"
    }'
`;

const AuthenticationResponse = `
    {
        "success": true,
        "email": "testapicustomeremail@gmail.com",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRvYzAyamFtZXNAZ21haWwuY29tIiwidXNlclxxxxxxxxxxxxxxxxlkIjoxOTUsImFwaV9wbGFuIjoiQmFzaWMiLCJpYXQiOjE2OTY4NTYxNjd9.c9aPicErBJxxxxxxxxxQWSHWRIOyJhhGU"
    }
`;

const NaturalLabInput = `
curl --location 'https://api.eu.diamondsoncall.com/feed/naturaldiamonds' \\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkXxxxxxxX3BsYW4iOiJCYXNpYyIsImlhdCI6MTY5NzAxNTkxMn0.BhI6DtuneGxf4xEPMTDatvTXpyeurRKB1g83NsdQUAM' \\
--form 'page="1"' \\
--form 'shape="Round,Radiant,Princess,Pear,Oval,Marquise,Heart,Emerald,Cushion,Asscher,Square Radiant"' \\
--form 'min_carat="0.18"' \\
--form 'max_carat="0.20"' \\
--form 'color="D,E,F,G,H,I,J,K,L,M,N,OP"' \\
--form 'clarity="FL,IF,VVS1,VVS2,VS1,VS2,SI1,SI2"' \\
--form 'cut="EX,ID,VG,GD,FR,PR"' \\
--form 'polish="EX,ID,VG,GD,FR,PR"' \\
--form 'symmetry="EX,ID,VG,GD,FR,PR"' \\
--form 'fluorescence="NON,STG,FNT,MED"' \\
--form 'lab="GIA,HRD,IGI,AGS,GCAL"' \\
--form 'total_price_from="0"' \\
--form 'total_price_to="10000"' \\
--form 'depthmin="0"' \\
--form 'depthmax="70"' \\
--form 'tablemin="0"' \\
--form 'tablemax="70"' \\
--form 'ratiomin="1"' \\
--form 'ratiomax="2"' \\
--form 'sort_field="carat"' \\
--form 'sort_order="desc"'

`;

const NaturalLabResponse = `
{
    "success": true,
    "message": "DATA FOUND",
    "currentPage": 1,
    "perPage": 100,
    "data": [
        {
            "STOCK_ID": 11082242,
            "Shipping_Days": 6,
            "SHAPE": "MARQUISE",
            "CARAT": 0.2,
            "COLOR": "I",
            "CLARITY": "VVS2",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "VG",
            "FLUORESCENCE": "FNT",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=6335246620",
            "WIDTH": 3.12,
            "LENGTH": 5.88,
            "DEPTH": 1.84,
            "DEPTH_PER": 59,
            "TABLE_PER": 61,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "6335246620",
            "PRICE_PER_CTS": 1299,
            "TOTAL_PRICE": 260,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "Yes",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "Faceted",
            "KEY_TO_SYMBOL": "Feather",
            "RATIO": 1.88,
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/video.php?refno=6335246620"
        },
        {
            "STOCK_ID": 11866627,
            "Shipping_Days": 6,
            "SHAPE": "PEAR",
            "CARAT": 0.2,
            "COLOR": "F",
            "CLARITY": "SI2",
            "CUT": "",
            "POLISH": "VG",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "NON",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=1473532833",
            "WIDTH": 3.21,
            "LENGTH": 4.96,
            "DEPTH": 2.1,
            "DEPTH_PER": 65.4,
            "TABLE_PER": 58,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "1473532833",
            "PRICE_PER_CTS": 1011,
            "TOTAL_PRICE": 202,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "0",
            "GIRDLE_CONDITION": "Faceted",
            "KEY_TO_SYMBOL": "Knot, Feather, Crystal",
            "RATIO": 1.55,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/1473532833.jpg",
            "VIDEO": "https://pro360video.com/video.php?refno=1473532833"
        }
    ]
}
`;

const FancyColorInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/naturaldiamonds ' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkXxxxxxxX3BsYW4iOiJCYXNpYyIsImlhdCI6MTY5NzAxNTkxMn0.BhI6DtuneGxf4xEPMTDatvTXpyeurRKB1g83NsdQUAM' \\
    --form 'page="1"' \\
    --form 'shape="Round,Radiant,Princess,Pear,Oval,Marquise,Heart,Emerald,Cushion,Asscher"' \\
    --form 'min_carat="0.18"' \\
    --form 'max_carat="0.20"' \\
    --form 'color="D,E,F,G,H,I,J,K,L,M,N,OP,QR,ST,UV,WX,YZ"' \\
    --form 'clarity="FL,IF,VVS1,VVS2,VS1,VS2,SI1,SI2,SI3,I1,I2,I3"' \\
    --form 'cut="EX,ID,VG,GD,FR,PR"' \\
    --form 'polish="EX,ID,VG,GD,FR,PR"' \\
    --form 'symmetry="EX,ID,VG,GD,FR,PR"' \\
    --form 'fluorescence="NON,STG,FNT,MED,SLT,VST,VSLT"' \\
    --form 'lab="GIA,HRD,IGI,AGS,GCAL"' \\
    --form 'total_price_from="0"' \\
    --form 'total_price_to="10000"' \\
    --form 'depthmin="0"' \\
    --form 'depthmax="70"' \\
    --form 'tablemin="0"' \\
    --form 'tablemax="70"' \\
    --form 'ratiomin="1"' \\
    --form 'ratiomax="2"' \\
    --form 'fancy_color_diamond="yes"' \\
    --form 'fancy_intensity="faint,very light,light,fancy light,fancy,fancy dark,fancy intense,fancy vivid,fancy deep"' \\
    --form 'fancy_overtone="none,yellow,yellowish,pink,pinkish,blue,blueish,red,reddish,green,greenish,purple,purplish,orange,orangy,violet,violetish,gray,grayish,black,brown,brownish,champagne,cognac,chameleon,white"' \\
    --form 'fancy_color="yellow,pink,blue,red,green,purple,orange,violet,grey,black,brown,champagne,cognac,chameleon,white"' \\
    --form 'sort_field="carat"' \\
    --form 'sort_order="desc"'

`;

const FancyColorResponse = `
{
    "success": true,
    "message": "DATA FOUND",
    "currentPage": 1,
    "perPage": 100,
    "data": [
        {
            "STOCK_ID": 11046773,
            "Shipping_Days": 6,
            "SHAPE": "PEAR",
            "CARAT": 0.2,
            "CLARITY": "SI1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "STG",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=2225939868",
            "WIDTH": 3.23,
            "LENGTH": 4.51,
            "DEPTH": 1.89,
            "DEPTH_PER": 58.7,
            "TABLE_PER": 62,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "2225939868",
            "PRICE_PER_CTS": 10250,
            "TOTAL_PRICE": 2050,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.4,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/2225939868.jpg",
            "VIDEO": "https://pro360video.com/video.php?refno=2225939868",
            "FANCY_INTENSITY": "FANCY VIVID",
            "FANCY_OVERTONE": "YELLOW",
            "FANCY_COLOR": "ORANGE"
        },
        {
            "STOCK_ID": 11509055,
            "Shipping_Days": 4,
            "SHAPE": "OVAL",
            "CARAT": 0.19,
            "CLARITY": "VS2",
            "CUT": "",
            "POLISH": "VG",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "NON",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=6224799002",
            "WIDTH": 3.17,
            "LENGTH": 4.18,
            "DEPTH": 1.84,
            "DEPTH_PER": 57.9,
            "TABLE_PER": 65,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "6224799002",
            "PRICE_PER_CTS": 10253,
            "TOTAL_PRICE": 1948,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "",
            "COUNTRY": "HONGKONG",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.32,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/6224799002.jpg",
            "VIDEO": "https://pro360video.com/video.php?refno=6224799002",
            "FANCY_INTENSITY": "FANCY LIGHT",
            "FANCY_OVERTONE": "PURPLISH",
            "FANCY_COLOR": "PINK"
        },
        {
            "STOCK_ID": 11509059,
            "Shipping_Days": 4,
            "SHAPE": "PEAR",
            "CARAT": 0.19,
            "CLARITY": "VVS2",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "MED",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=5222910018",
            "WIDTH": 3.07,
            "LENGTH": 4.36,
            "DEPTH": 1.94,
            "DEPTH_PER": 63.3,
            "TABLE_PER": 62,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "5222910018",
            "PRICE_PER_CTS": 25553,
            "TOTAL_PRICE": 4855,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "Yes",
            "COUNTRY": "HONGKONG",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.42,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/5222910018.jpg",
            "VIDEO": "https://pro360video.com/video.php?refno=5222910018",
            "FANCY_INTENSITY": "FANCY",
            "FANCY_OVERTONE": "ORANGY",
            "FANCY_COLOR": "PINK"
        }
    ]
}

`;

const LabDiamondInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/labgrowndiamonds ' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkXxxxxxxX3BsYW4iOiJCYXNpYyIsImlhdCI6MTY5NzAxNTkxMn0.BhI6DtuneGxf4xEPMTDatvTXpyeurRKB1g83NsdQUAM' \\
    --form 'page="1"' \\
    --form 'shape="Round,Radiant,Princess,Pear,Oval,Marquise,Heart,Emerald,Cushion,Asscher,Square Radiant,Trilliant,Cushion modified,Triangle"' \\
    --form 'min_carat="0.18"' \\
    --form 'max_carat="2.00"' \\
    --form 'color="D,E,F,G,H,I,J,K,L,M,N,OP,QR,ST,UV,WX,YZ"' \\
    --form 'clarity="FL,IF,VVS1,VVS2,VS1,VS2,SI1,SI2,SI3,I1,I2,I3"' \\
    --form 'cut="EX,ID,VG,GD,FR,PR,IDEAL"' \\
    --form 'polish="EX,ID,VG,GD,FR,PR"' \\
    --form 'symmetry="EX,ID,VG,GD,FR,PR"' \\
    --form 'fluorescence="NON,STG,FNT,MED,SLT,VST,VSLT"' \\
    --form 'lab="GIA,HRD,IGI,AGS,GCAL"' \\
    --form 'total_price_from="0"' \\
    --form 'total_price_to="10000"' \\
    --form 'depthmin="0"' \\
    --form 'depthmax="70"' \\
    --form 'tablemin="0"' \\
    --form 'tablemax="70"' \\
    --form 'ratiomin="1.00"' \\
    --form 'ratiomax="2.00"' \\
    --form 'sort_field="carat"' \\
    --form 'sort_order="desc"'

`;

const LabDiamondResponse = `
{
    "success": true,
    "message": "DATA FOUND",
    "currentPage": 1,
    "perPage": 100,
    "data": [
        {
   
            "STOCK_ID": 814489600,
            "Shipping_Days": 6,
            "SHAPE": "OVAL",
            "CARAT": 2,
            "COLOR": "E",
            "CLARITY": "VS1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "CERTIFICATE_LINK": "https://www.igi.org/viewpdf.php?r=613371816",
            "WIDTH": 7.04,
            "LENGTH": 9.92,
            "DEPTH": 4.29,
            "DEPTH_PER": 60.9,
            "TABLE_PER": 61,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "613371816",
            "PRICE_PER_CTS": 251,
            "TOTAL_PRICE": 501,
            "Growth_Type": "UNKNOWN",
            "TREATMENT": "",
            "SHADE": "none",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.41,
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/labgrown.php?refno=613371816"
        },
        {
            "STOCK_ID": 814700032,
            "Shipping_Days": 6,
            "SHAPE": "PEAR",
            "CARAT": 2,
            "COLOR": "F",
            "CLARITY": "VVS1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "CERTIFICATE_LINK": "https://www.igi.org/viewpdf.php?r=620443075",
            "WIDTH": 7.04,
            "LENGTH": 10.49,
            "DEPTH": 4.37,
            "DEPTH_PER": 62.1,
            "TABLE_PER": 61,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "620443075",
            "PRICE_PER_CTS": 513,
            "TOTAL_PRICE": 1027,
            "Growth_Type": "UNKNOWN",
            "TREATMENT": "",
            "SHADE": "none",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "Yes",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.49,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/620443075.jpg",
            "VIDEO": "https://pro360video.com/labgrown.php?refno=620443075"
        },
    ]
}

`;

const LabFancyColorInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/labgrowndiamonds ' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkXxxxxxxX3BsYW4iOiJCYXNpYyIsImlhdCI6MTY5NzAxNTkxMn0.BhI6DtuneGxf4xEPMTDatvTXpyeurRKB1g83NsdQUAM' \\
    --form 'page="1"' \\
    --form 'shape="Round,Radiant,Princess,Pear,Oval,Marquise,Heart,Emerald,Cushion,Asscher"' \\
    --form 'min_carat="0.18"' \\
    --form 'max_carat="10.00"' \\
    --form 'color="D,E,F,G,H,I,J,K,L,M,N,OP,QR,ST,UV,WX,YZ"' \\
    --form 'clarity="FL,IF,VVS1,VVS2,VS1,VS2,SI1,SI2,SI3,I1,I2,I3"' \\
    --form 'cut="EX,ID,VG,GD,FR,PR,IDEAL"' \\
    --form 'polish="EX,ID,VG,GD,FR,PR"' \\
    --form 'symmetry="EX,ID,VG,GD,FR,PR"' \\
    --form 'fluorescence="NON,STG,FNT,MED,SLT,VST,VSLT"' \\
    --form 'lab="GIA,HRD,IGI,AGS,GCAL"' \\
    --form 'total_price_from="0"' \\
    --form 'total_price_to="999999"' \\
    --form 'depthmin="0"' \\
    --form 'depthmax="100"' \\
    --form 'tablemin="0"' \\
    --form 'tablemax="100"' \\
    --form 'ratiomin="1.00"' \\
    --form 'ratiomax="2.00"' \\
    --form 'fancy_color_diamond="yes"' \\
    --form 'fancy_intensity="faint,very light,light,fancy,fancy vivid,fancy deep"' \\
    --form 'fancy_overtone="none,yellow,yellowish,pink,pinkish,blue,blueish,red,reddish,green,greenish,purple,purplish,champagne,cognac,chameleon,white"' \\
    --form 'fancy_color="yellow,pink,blue,red,champagne,cognac,chameleon,white"' \\
    --form 'sort_field="carat"' \\
    --form 'sort_order="desc"'


`;

const LabFancyColorResponse = `

    "success": true,
    "message": "DATA FOUND",
    "currentPage": 1,
    "perPage": 100,
    "data": [
        {
            "STOCK_ID": 814213751,
            "Shipping_Days": 6,
            "SHAPE": "CUSHION",
            "CARAT": 6.53,
            "CLARITY": "VS1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "NON",
            "LAB": "IGI",
            "CERTIFICATE_LINK": "https://www.igi.org/viewpdf.php?r=605307960",
            "WIDTH": 9.29,
            "LENGTH": 10.96,
            "DEPTH": 6.64,
            "DEPTH_PER": 71.5,
            "TABLE_PER": 65,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "605307960",
            "PRICE_PER_CTS": 1449,
            "TOTAL_PRICE": 9460,
            "Growth_Type": "CVD",
            "TREATMENT": "Treated",
            "SHADE": "",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "7.8",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.18,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/605307960.jpg",
            "VIDEO": "https://pro360video.com/labgrown.php?refno=605307960",
            "FANCY_INTENSITY": "FANCY INTENSE",
            "FANCY_OVERTONE": "BROWNISH",
            "FANCY_COLOR": "YELLOW"
        },
        {
            "STOCK_ID": 814584748,
            "Shipping_Days": 6,
            "SHAPE": "PEAR",
            "CARAT": 6.07,
            "CLARITY": "VS1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "SLT",
            "LAB": "IGI",
            "CERTIFICATE_LINK": "https://www.igi.org/viewpdf.php?r=602390590",
            "WIDTH": 10.16,
            "LENGTH": 15.42,
            "DEPTH": 6.1,
            "DEPTH_PER": 60,
            "TABLE_PER": 64,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "602390590",
            "PRICE_PER_CTS": 308,
            "TOTAL_PRICE": 1867,
            "Growth_Type": "UNKNOWN",
            "TREATMENT": "",
            "SHADE": "none",
            "MILKY": "NO MILKY",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.52,
            "IMAGE": "",
            "VIDEO": "https://pro360video.com/labgrown.php?refno=602390590",
            "FANCY_INTENSITY": "FANCY INTENSE",
            "FANCY_OVERTONE": "BROWNISH",
            "FANCY_COLOR": "PINK"
        },
        {
            "STOCK_ID": 814817126,
            "Shipping_Days": 6,
            "SHAPE": "CUSHION",
            "CARAT": 6.01,
            "CLARITY": "VS1",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "EX",
            "FLUORESCENCE": "VSLT",
            "LAB": "IGI",
            "CERTIFICATE_LINK": "https://www.igi.org/viewpdf.php?r=620459740",
            "WIDTH": 10.18,
            "LENGTH": 10.32,
            "DEPTH": 6.51,
            "DEPTH_PER": 63.9,
            "TABLE_PER": 57,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "620459740",
            "PRICE_PER_CTS": 920,
            "TOTAL_PRICE": 5527,
            "Growth_Type": "UNKNOWN",
            "TREATMENT": "",
            "SHADE": "none",
            "MILKY": "",
            "EYE_CLEAN": "YES",
            "COUNTRY": "INDIA",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.01,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/620459740.jpg",
            "VIDEO": "https://pro360video.com/labgrown.php?refno=620459740",
            "FANCY_INTENSITY": "FANCY VIVID",
            "FANCY_OVERTONE": "BROWNISH",
            "FANCY_COLOR": "YELLOW"
        },
    ]
}


`;

const diamondDetailsInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/diamonddetail' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRvYzAyamFtZXNAZ21haWwuY29tIiwidXNlcl9pZCI6Mjc0OSwiYXBpX2lxxxxxxxxxiQmFzaWMiLCJpYXQiOjE2OTY4NTYxNjd9.c9aPicErBJoZXtNUwtDqEhTmp3FAiQWSHWRIOyJhhGU' \\
    --data '
    {
        "Certi_NO":"5222910018"
                OR
        "StockID":"11509059"
    }'

`;

const diamondDetailsResponse = `
    {
    "success": true,
    "data": 
        {
            "STOCK_ID": 11509059,
            "Shipping_Days": 4,
            "SHAPE": "PEAR",
            "CARAT": 0.19,
            "CLARITY": "VVS2",
            "CUT": "",
            "POLISH": "EX",
            "SYMMETRY": "GD",
            "FLUORESCENCE": "MED",
            "LAB": "GIA",
            "CERTIFICATE_LINK": "https://www.gia.edu/report-check?reportno=5222910018",
            "WIDTH": 3.07,
            "LENGTH": 4.36,
            "DEPTH": 1.94,
            "DEPTH_PER": 63.3,
            "TABLE_PER": 62,
            "CROWNANGLE": "",
            "CROWNHEIGHT": "",
            "PAVILIONHEIGHT": "",
            "PAVILIONANGLE": "",
            "CERTIFICATE_NO": "5222910018",
            "PRICE_PER_CTS": 25553,
            "TOTAL_PRICE": 4855,
            "ORIGIN": "Other",
            "TREATMENT": "NONE",
            "BRAND": "Other",
            "SHADE": "",
            "MILKY": "",
            "EYE_CLEAN": "Yes",
            "COUNTRY": "HONGKONG",
            "CURRENCY": "EUR",
            "CULET": "",
            "GIRDLE": "",
            "GIRDLE_CONDITION": "",
            "KEY_TO_SYMBOL": "",
            "RATIO": 1.42,
            "IMAGE": "https://s3.us-east-2.amazonaws.com/docstone/5222910018.jpg",
            "VIDEO": "https://pro360video.com/video.php?refno=5222910018",
            "FANCY_INTENSITY": "FANCY",
            "FANCY_OVERTONE": "ORANGY",
            "FANCY_COLOR": "PINK"
        }
    }


`;

const CheckAvailabilityInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/checkavailability ' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.xxxxxxxxxxxxxxxxxxxxx.c9aPicErBJoZXtNUwtDqEhTmp3FAiQWSHWRIOyJhhGU' \\
    --data '{
        "Certi_NO":"584350325,1465112390",
                OR
        "StockID":"123456777, 123456778"
    }

`;

const CheckAvailabilityResponse = `
    {
        "success": true,
        "data": [
            {
                "DIAMOND_TYPE": "Lab Grown",
                "STATUS": "Available",
                "STOCK_ID": 813138212,
                "CARAT": 0.18,
                "CERTIFICATE_NO": "584350325",
                "PRICE_PER_CTS": 617,
                "TOTAL_PRICE": 111,
                "CURRENCY": "EUR",
                "CURRENCY_RATE": 0.92
            },
            {
                "DIAMOND_TYPE": "Natural",
                "STATUS": "Available",
                "STOCK_ID": 11059084,
                "CARAT": 0.19,
                "CERTIFICATE_NO": "1465112390",
                "PRICE_PER_CTS": 1430,
                "TOTAL_PRICE": 272,
                "CURRENCY": "EUR",
                "CURRENCY_RATE": 0.92
            }
        ]
    }

`;

const HoldInput = `
    curl --location ' https://api.eu.diamondsoncall.com/feed/holddiamonds ' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.xxxxxxxxxxxxxxxxasdafcafavcacascascav.xxxxxxxxxxxxxxxxxxx-XK29lZyhA' \\
    --data '{
        "Certi_NO":"5463355668,1345454333",
                OR
        "StockID":"123456777, 123456778"
    }'

`;

const HoldResponse = `
{
    "Message": "Diamond(s) added in Hold Diamond List.",
    "CERTIFICATE": "5463355668"
},
{
    "Message": "Diamond(s) added in Hold Diamond List.",
    "CERTIFICATE": "1345454333"
}

`;

const ConfirmInput = `
    curl --location 'https://api.eu.diamondsoncall.com/feed/confirmdiamonds ' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.xxxxxxxxxxxxxxxxasdafcafavcacascascav.DX4x7QFaHOtr2hyMpwVXMRM5Z9hagvPra-XK29lZyhA' \\
    --data '{
        "Certi_NO":"5463355668,1345454333",
                OR
        "StockID":"123456777, 123456778"
    }

`;

const ConfirmResponse = `
{
    "Success": true,
    "Data": [
        {
           "Message": " Diamond(s) added in Confirm Diamond List.",
           "CERTIFICATE": "5463355668"
        },
        {
            "Message": "Diamond(s) added in Confirm Diamond List.",
            "CERTIFICATE": "1345454333"
        }
    ]
}

`;

const CountApiInput = `
    curl --location 'https://api.eu.diamondsoncall.com/feed/diamondcount' \\
    --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJxxxxxxxxxlbWFpbCxxI6ImRvYzAyaxxxxmFtZXNAZ21haWwuY29tIiwidXNlcl9pZCI6Mjc0OSwssi' \\
    --form 'diamond_type="N"' \\
    --form 'page="1"' \\
    --form 'shape="Round,Radiant,Princess,Pear,Oval,Marquise,Heart"' \\
    -form 'min_carat="0.18"' \\
    --form 'max_carat="20.00"' \\
    --form 'color="D,E,F,G,H,I,J,K,L,M,N,OP,QR,ST,UV,WX,YZ"' \\
    --form 'clarity="FL,IF,VVS1,VVS2,VS1,VS2,SI1,SI2,SI3,I1,I2,I3"' \\
    --form 'cut="EX,ID,VG,GD,FR,PR,IDEAL"' \\
    --form 'polish="EX,ID,VG,GD,FR,PR"' \\
    --form 'symmetry="EX,ID,VG,GD,FR,PR"' \\
    --form 'fluorescence="NON,VST,STG,MED,FNT,SLT,VSLT"' \\
    --form 'lab="IGI,GIA,HRD,GCAL,OTHER"' \\
    --form 'total_price_from="0"' \\
    --form 'total_price_to="99999999"' \\
    --form 'depthmin="0"' \\
    --form 'depthmax="100"' \\

`;

const CountApiResponse = `
    { 
        "success": true,
        "diamondcount": 186766,
        "diamond_type": "N"
    }

`;