import React from "react";
import { Tooltip,OverlayTrigger } from "react-bootstrap";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const searchResultColumnWithId = (language,conversionrate,symbol) => [
    {
        Header: language.PARCEL_DIAMONDS_PARCEL_ID,
        accessor: (d) => {
            return (
                <>
                   {d.diamond_type}-{d.parcel_id}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: language.PARCEL_DIAMONDS_TYPE,
        accessor: "diamond_type",
        className: "text-center align-middle text-nowrap",
        Cell: (row) => {
            return <>{row.value ? <div className={`${row.row.original.diamond_type === "PL" ? "PL_diamond" : "PN_diamond"}`}>{row.value}</div> : "-"}</>;
        }
    },
    {
        Header: language.SEARCHLIST_SHAPE,
        accessor: "c_Shape",
        className: "text-center align-middle text-nowrap",
        Cell: (row) => {
            return <>{row.value || row.row.original.C_Shape || "-"}</>;
        },
    },
    {
        Header: language.PARCEL_DIAMONDS_SIZE_MM,
        accessor: (d) => {
            return (
                <>
                    <div className="font-weight-bolder text-primary">{parseFloat(d.mm_from).toFixed(2)} to {parseFloat(d.mm_to).toFixed(2)} MM</div>
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    // {
    //     Header: "Size(MM)",
    //     accessor: (d) => {
    //         return (
    //             <>
    //                 +{d.mm_from} to -{d.mm_to}
    //             </>
    //         );
    //     },
    //     className: "text-center align-middle  text-nowrap",
    //     // Cell: (row) => {
    //     //     return <>{row.value ? row.value : "-"}</>;
    //     // },
    // },
    {
        Header: language.SEARCHLIST_COLOR,
        accessor: "color",
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: language.SEARCHLIST_CLARITY,
        accessor: "clarities",
        className: "text-center align-middle  text-nowrap",
    },
    {
        Header: language.PARCEL_DIAMONDS_CARAT_PCS,
        accessor: (d) => {
            return (
                <>
                {d.type === "carat" ? 
                   <>
                        <div className="font-weight-bolder text-primary text-nowrap">{parseFloat(d.carat).toFixed(2)} Ct.</div>
                        <div className="text-muted small">{d.pcs} Pcs *</div>
                   </>
                   :
                   <>
                        <div className="font-weight-bolder text-primary text-nowrap">{d.pcs} Pcs</div>
                        <div className="text-muted small">{parseFloat(d.carat).toFixed(2)} Ct. *</div>
                   </>
                   }
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    // {
    //     Header: "Pcs",
    //     accessor:"pcs",
    //     className: "text-center align-middle pr-5 text-nowrap",
    //     Cell: (row) => {
    //         return <>{row.value} Pcs.</>;
    //     }
    // },
    // {
    //     Header: "$/Ct",
    //     accessor:"dollarPerCarat",
    //     className: "text-center align-middle pr-5 text-nowrap",
    //     Cell: (row) => {
    //         return <>${parseFloat(row.value).toFixed(2).toLocaleString()}</>;
    //     }
    // },
    {
        Header: `${language.PARCEL_DIAMONDS_TOTAL_PRICE} & $/Ct`,
        // accessor:"totalPrice",
        accessor: (d) => (
            <>
                
                {
                        conversionrate !== 1 ? <>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(parseFloat(d.totalPrice))}<br/>
                                        $/CT {numberWithCommas(parseFloat(d.dollarPerCarat))}
                                    </Tooltip>
                                }
                            >
                                <div className="d-flex justify-content-center flex-column">
                                    <div className="font-weight-bolder text-primary">
                                        {symbol}{numberWithCommas(parseFloat(d.totalPrice * conversionrate))}
                                    </div>{" "}
                                    <div className="text-muted small">
                                        {symbol}/CT {numberWithCommas(parseFloat(d.dollarPerCarat * conversionrate))}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </> :
                         <div className="d-flex justify-content-center flex-column">
                            <div className="font-weight-bolder text-primary">
                                {symbol}{numberWithCommas(parseFloat(d.totalPrice * conversionrate))}
                            </div>{" "}
                            <div className="text-muted small">
                                {symbol}/CT {numberWithCommas(parseFloat(d.dollarPerCarat * conversionrate))}
                            </div>
                        </div>
                    }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: language.PARCEL_DIAMONDS_ORDER_BY,
        accessor:"type",
        className: "text-center align-middle pr-5 text-nowrap text-capitalize",
    },
];
