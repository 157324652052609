import React from 'react';
import { useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FormControl, InputLabel } from '@material-ui/core';

const PhoneInputFieldFull = ({ label, name,required, ...props }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    // console.log(errors.name)
    const handleChange = value => {
        setFieldValue(name, value); // Manually set Formik field value as a string
    };
    return (
            <FormControl fullWidth variant="outlined" className={`${touched[name] && errors[name] ? 'phoninputerror' : ''} rounded-circle`}>
            <InputLabel
                htmlFor="shipping_email"
                className="bg-white"
                shrink={values[name] || touched[name]}
                error={touched[name] && Boolean(errors[name])}
            >
                Telephone *
            </InputLabel>
          
                <PhoneInput
                    country={'in'} // Set default country if necessary
                    value={values[name] || ''} // Ensure value is always a string
                    onChange={handleChange}
                    inputProps={{
                        name,
                        onBlur: () => {}, // Implement onBlur if necessary
                        className: `form-control rounded-circle ${touched[name] && errors[name] ? 'is-invalid' : ''}`
                    }}
                    {...props}
                />
                {touched[name] && errors[name] ? (
                    <div className="invalid-feedback d-block">{errors[name]}</div>
                ) : null}
            </FormControl>
    );
};

export default PhoneInputFieldFull;
