import React, { useState } from "react";
import { FaWhatsapp, FaFacebook, FaTwitter, FaEnvelope } from "react-icons/fa";
// import './SharingComponent.css';
import { IoLinkOutline, IoSend } from "react-icons/io5";
import { GoArrowLeft } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { TextField } from "@material-ui/core";

const SharingComponent = ({ onBack, close }) => {
    const [selectedOption, setSelectedOption] = useState("");

    // Handle Copy Link
    const handleCopy = () => {
        navigator.clipboard.writeText("https://diamondsoncall.com");
        alert("Link copied to clipboard!");
    };

    // Set selected platform (only one can be selected at a time)
    const selectOption = (platform) => {
        setSelectedOption(platform === selectedOption ? "" : platform);
    };

    // Social Media Sharing URLs
    const shareUrls = {
        whatsapp: `https://api.whatsapp.com/send?text=Check this out: https://diamondsoncall.com`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=https://diamondsoncall.com`,
        twitter: `https://twitter.com/intent/tweet?text=Check this out!&url=https://diamondsoncall.com`,
        email: `mailto:?subject=Check this out&body=Visit this link: https://diamondsoncall.com`,
    };

    // Handle Send Button
    const handleSend = () => {
        if (selectedOption) {
            const shareUrl = shareUrls[selectedOption];
            if (shareUrl) {
                window.open(shareUrl, "_blank");
            }
        } else {
            alert("Please select a sharing option.");
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="font-size-h4 d-flex align-items-center">
                    <button
                        className="btn btn-sm btn-clear btn-hover-bg-light font-size-h4 mr-2"
                        onClick={onBack}
                    >
                        <GoArrowLeft />
                    </button>
                    <div className="d-flex flex-column">
                        <span>Share</span>
                        <span className="small">Lorem ipsum share popup</span>
                    </div>
                </div>
                <button
                    className="btn btn-sm btn-clear btn-hover-bg-light font-size-h4"
                    onClick={close}
                >
                    <AiOutlineClose />
                </button>
            </div>
            <div className="row mt-5">
                <div className="col-12 text-center">
                    {/* <div className="share-sharing-options py-2 border rounded">
            <div className="share-social-icon-group">
              <span
                className={`share-social-icon ${selectedOption === 'whatsapp' ? 'selected' : ''}`}
                onClick={() => selectOption('whatsapp')}
              >
                <FaWhatsapp />
              </span>
              <span
                className={`share-social-icon ${selectedOption === 'facebook' ? 'selected' : ''}`}
                onClick={() => selectOption('facebook')}
              >
                <FaFacebook />
              </span>
              <span
                className={`share-social-icon ${selectedOption === 'twitter' ? 'selected' : ''}`}
                onClick={() => selectOption('twitter')}
              >
                <FaTwitter />
              </span>
              <span
                className={`share-social-icon ${selectedOption === 'email' ? 'selected' : ''}`}
                onClick={() => selectOption('email')}
              >
                <FaEnvelope />
              </span>
            </div>
                    </div> */}
                    <TextField
                        type='email'
                        label="Enter Email address"
                        id="email"
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email address"
                        fullWidth
                    />
                </div>
                <div className="col-12 mt-5">
                    <div className="share-static-content border d-flex p-5 rounded">
                        <img
                            src="https://via.placeholder.com/150"
                            alt="Content"
                            className="share-content-image mr-5"
                        />
                        <div>
                            <p className="share-content-description">
                                <div className=" pl-2 p-0 text-left">
                                    <div className={`mb-1`}>
                                        <span className={`W_diamond mr-3 small`} > W </span>
                                        <span className="mr-3">N-123456</span>
                                        <span className="mr-3">
                                            GIA - 123456
                                        </span>
                                    </div>

                                    <div className="text-dark font-size-h5">
                                        ROUND 0.34 L SI1 EX EX EX MED
                                    </div>
                                    <div className="mt-4 font-weight-bold">
                                        <div className="small">
                                            $/CT 123.67
                                        </div>
                                        <div className="font-size-h4">
                                            $123,45.67
                                        </div>
                                    </div>
                                </div>
                            </p>
                            {/* <a href="https://diamondsoncall.com" className="share-page-link">https://diamondsoncall.com</a> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 d-flex justify-content-between">
                    <div>
                        <span
                            className={`share-social-icon ${ selectedOption === "whatsapp" ? "selected" : "" }`}
                            onClick={() => selectOption("whatsapp")}
                        >
                            <FaWhatsapp />
                        </span>
                        <span
                            className={`share-social-icon ${ selectedOption === "facebook" ? "selected" : "" }`}
                            onClick={() => selectOption("facebook")}
                        >
                            <FaFacebook />
                        </span>
                        <span
                            className={`share-social-icon ${ selectedOption === "twitter" ? "selected" : "" }`}
                            onClick={() => selectOption("twitter")}
                        >
                            <FaTwitter />
                        </span>
                        <span
                            className={`share-social-icon ${ selectedOption === "email" ? "selected" : "" }`}
                            onClick={() => selectOption("email")}
                        >
                            <FaEnvelope />
                        </span>
                    </div>
                    <div>
                        <button
                            className="btn btn-outline-secondary bg-primary-o-20 btn-sm px-5 mr-2"
                            onClick={handleCopy}
                        >
                            <IoLinkOutline className="mr-2 font-size-h5" />
                            Copy link
                        </button>
                        <button
                            className="btn btn-primary btn-sm px-5 mr-1"
                            onClick={handleSend}
                        >
                            <IoSend className="mr-2" />
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SharingComponent;
