import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import { FaInfoCircle} from "react-icons/fa";
import moment from "moment-timezone";
import CertificateLink from "../CertificateLink";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// function formattedDate(x) {
//     const originalTimestamp = new Date(x);
//     return originalTimestamp
// }

const shadeMappings = {
    "none": { label: "No.SH", tooltip: "Shade: NONE" },
    "brown": { label: "BR", tooltip: "Shade: Brown" },
    "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
    "green": { label: "GR", tooltip: "Shade: Green" },
    "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
    "grey": { label: "GREY", tooltip: "Shade: Grey" },
    "black": { label: "BLK", tooltip: "Shade: Black" },
    "pink": { label: "PINK", tooltip: "Shade: Pink" },
    "blue": { label: "BLUE", tooltip: "Shade: Blue" },
    "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
};

const renderShadeLabel = (shade) => {
    const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
    if (!mapping) return null;

    return (
        <span>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
            >
                <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
            </OverlayTrigger>
        </span>
    );
};


export const MockDocTableColumn = (conversionrate,symbol,history,language,userinfo) => [
    {
        Header: language.HOLD_DIAMOND_REQUESTNUMBER,
        className: "align-middle text-center",
        accessor: (row) => {
            return (
                <>
                    <span>{row.conformGoodId}</span>
                    <p className="m-0 small text-muted">
                        { row.created_date ? moment.tz(row.created_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"} 
                    </p>
                </>
            );
        },
    },
    {
        Header: language.HOLD_DIAMOND_DIAMONDDETAILS,
        accessor: (d) => (
            <>
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={d.image}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.shape ? d.shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                                
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={toAbsoluteUrl(`/media/shape/${d.shape.toLowerCase()}-no.png`)}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span
                                className={`${
                                    d.diamondType === "N"
                                        ? d.isfancy
                                            ? "F"
                                            : "W"
                                        : d.isfancy
                                        ? "LF"
                                        : "L"
                                }_diamond mr-3 small`}
                            >
                                {d.diamondType === "N"
                                    ? d.isfancy
                                        ? "F"
                                        : "N"
                                    : d.isfancy
                                    ? "LF"
                                    : "L"}
                            </span>
                            <a href={`/details/hc-${d.conformGoodId}`} target="_blank" rel="noreferrer" className={` cursor-pointer small ml-2`} >
                                {d.diamondType === "N" ? "N" : "L"}-{d.stockID}
                                {d.hold_comments?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {d.hold_comments}
                                            </Tooltip>
                                        }
                                    >
                                        <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                    </OverlayTrigger>
                                :""}
                            </a>
                            <CertificateLink Certificate_link={d.Certi_link} lab={d.lab} certificate={d.certificate} className={`mx-2 small`} />
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.shape}{" "}
                            {d.carat}{" "}
                            {d.color}{" "}
                            {d.clarity}{" "}{d.cut}{" "}{d.polish}{" "}{d.symmetry}{" "}
                            {d.fluorescence}
                        </span>
                    </div>
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: "Advanced Tags",
        accessor: (d) => (
            <div className="advTag" style={{ maxWidth: "150px" }}>
                {/* shade */}
                {renderShadeLabel(d.shade)}
                
                { d.milky && d.milky.toLowerCase() === "no milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    NO MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "luster low" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Luster Low
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "light milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    LIGHT MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                {/* eyeclean */}
                { d.eyeClean && d.eyeClean.toLowerCase() === "yes" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    100% Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.eyeClean && d.eyeClean.toLowerCase() === "no" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    No Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                {/* growthtype */}
                { d.diamondType === "L" && (d.growthType && d.growthType === "CVD") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    CVD
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.diamondType === "L" && (d.growthType && d.growthType === "HPHT") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    HPHT
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                
                { (d.diamondType === "L" ) && (d.treatment && d.treatment.toLowerCase() === "as grown") ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    As Grown
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
                {/* natural treated */}
                { (d.diamondType === "N" || d.diamondType === "W") && (d.treatment && d.treatment.toLowerCase() !== "none")  ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Treated
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
            </div>
        ),
        className: "align-middle",
    },
    {
        Header: language.HOLD_DIAMOND_HOLDTIME,
        accessor: (d) => (
            <>
              {d.hold_time} Hours
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.HOLD_DIAMOND_STATUS,
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <div>
                    <div
                        className={`label ${
                                  d.confirm_status === "Pending" ? "label-warning"
                                : d.confirm_status === "Rejected" ? "label-danger" 
                                : d.confirm_status === "Approved" ? "label-success" 
                                : ""
                        } label-inline  mr-2`}
                    >
                        {d.confirm_status}
                    </div>
                </div>
            );
        },
    },
    {
        Header: language.HOLD_DIAMOND_DISC,
        accessor: (d) => Number(d["discount"]).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        },
    },
    {
        Header: language.HOLD_DIAMOND_OURPRICEUSD,
        accessor: (d) => (
            <>
                <span className="font-weight-bold">
                    ${numberWithCommas(parseFloat(d["ourPriceExchange"]))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                $/CT {numberWithCommas(parseFloat(d["ourRateExchange"]))}
                </span>
            </>
        ),
        className: "text-center align-middle",
    },
];
