import React, { useState ,useEffect} from 'react';
import { Grid, Typography,InputLabel } from '@material-ui/core';
import { InputField, CheckboxField, SelectField,PhoneInput } from '../FormFields';
import axios from 'axios';
import Cookies from "js-cookie"
import { BASE_URL } from "./../../../../../env_config"

import MuiUploader from '../FormFields/MuiUploader';
import { NODE_API_URL } from '../../../../../env_config';
const work_profile_data = [
  
  {
    value: 'CEO',
    label: 'CEO'
  },
  {
    value: 'Sales-Manager',
    label: 'Sales-Manager'
  },
  {
    value: 'Purchase-Manager',
    label: 'Purchase-Manager'
  },
  {
    value: 'account-manager',
    label: 'account-manager'
  },
  {
    value: 'secretary',
    label: 'secretary'
  },
  {
    value: 'business-development-executives',
    label: 'business-development-executives'
  },
  {
    value: 'other',
    label: 'other'
  }
];

export default function PersonalInfoForm(props) {
  const {
    formField: {
      firstName,
      lastName,
      email,
      contanct_no,
      work_profile,
      passport_id,
      passport_id_file,
    //   useAddressForPaymentDetails
    },
    setFirstFile,
    setFieldValue,
    setPassportFileName,
    FirstFile,
    values,
    
  } = props;
  useEffect(() => {
    let apiurl = "/INTL/noKycViewData"
    if(props.userinfo.kyc_status === "KYC_PEND" || props.userinfo.kyc_status === "KYC_REJ"  || props.userinfo.kyc_status === "KYC_EDITED"){
      apiurl = "/INTL/getKycPendingData"
    }
    axios({
        method: "GET",
        url: `${NODE_API_URL}${apiurl}`,
        headers: { 
          "Content-Type": "application/json" ,
          "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
        }
    }).then(res => {
        if(res && res.data){
            console.log(res.data,"res.data")
            let apires = res.data.data
           const GetWorkProfile = (workprofile) => {
            let id = null
            if(workprofile){
              work_profile_data.map(value => {
                if(value.label.toLowerCase() === workprofile.toLowerCase()){
                  id = value.value
                }
              })
            }
            // console.log(id,"idididid")
            return id
           }
           function replaceAll(string, search, replace) {
            return string.split(search).join(replace);
          }
          function removeTags(str) {
            if ((str===null) || (str===''))
                return false;
            else
                str = str.toString().replaceAll("&nbsp;"," ");
                  
            // Regular expression to identify HTML tags in
            // the input string. Replacing the identified
            // HTML tag with a null string.
            return str.replace( /(<([^>]+)>)/ig, '');
        }
          setFieldValue("firstName",apires.fname)
          setFieldValue("lastName",apires.lname)
          setFieldValue("email",apires.mail)
          setFieldValue("contanct_no",apires.mobile)
          setFieldValue("passport_id",apires.passport_id)
          setFieldValue("passport_id_file",apires.passport_file)
          setFieldValue("work_profile",GetWorkProfile(apires.work_profile))
          setFieldValue("company_name",apires.cname)
          setFieldValue("billing_company_name",apires.company_name_official)
          setFieldValue("address1",apires.address)
          setFieldValue("address2",apires.secaddress)
          setFieldValue("address3",apires.addressline3)
          setFieldValue("city",apires.city)
          setFieldValue("state",apires.state)
          setFieldValue("zipcode",apires.com_zip)
          setFieldValue("country",apires.country)
          setFieldValue("telephone",apires.contact2)
          setFieldValue("fax",apires.fax)
          setFieldValue("invoice_email",apires.invoiceemail)
          setFieldValue("website",apires.website)
          setFieldValue("comp_reg_no",apires.com_reg_no)
          setFieldValue("comp_reg_no_file",apires.com_reg_doc)
          setFieldValue("nature_of_business",apires.nature_buss)
          setFieldValue("business_est_date",apires.buss_esta_date)
          setFieldValue("name_of_partner",apires.director_name)
          setFieldValue("contact_of_partner",apires.directory_contact)
          // setFieldValue("atten_name",apires.name_atten)
          // setFieldValue("shipping_address1",apires.shipping_address_1)
          // setFieldValue("shipping_address2",apires.shipping_address_2)
          // setFieldValue("shipping_address3",apires.shipping_address_3)
          // setFieldValue("shipping_city",apires.shipping_city)
          // setFieldValue("shipping_phone",apires.shipping_phone)
          // setFieldValue("shipping_state",apires.shipping_state)
          // setFieldValue("shipping_zipcode",apires.shipping_zip_code)
          // setFieldValue("port_of_discharge",apires.port_of_discharge)
          // setFieldValue("shipping_state",shipstate)
          // setFieldValue("shipping_zipcode",shipzip)
          // setFieldValue("shipping_country",apires.country)
          // setFieldValue("shipping_telephone",apires.shipping_phone)
          // setFieldValue("shipping_email",apires.shipping_email)
          setFieldValue("tax_id",apires.company_tax)
          // setFieldValue("port_of_discharge",apires.port_of_discharge)
        }
    }).
    catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  },[])
  // console.log(values,"PERSONALINFOR FORM")
  return (
    <React.Fragment>
      <Typography className=" text-center font-weight-bolder pb-5" variant="h5">
        {props.language.PROFILEVIEW_USER_INFORMATION}
      </Typography>
      <hr className='my-auto w-50 pb-10' />
      <Grid container alignItems="center" justify='center' spacing={2}>
        <Grid item xs={12} sm={7}>
          <InputField name={firstName.name} label={props.language.PROFILE_FIRST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={lastName.name} label={props.language.PROFILE_LAST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={email.name} label={props.language.PROFILE_EMAIL} fullWidth disabled={true}/>
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* <InputField name={contanct_no.name} label={contanct_no.label} fullWidth /> */}
          {/* <InputLabel className="font-size-h6 font-weight-bold">
            {props.language.PROFILE_CONTACT_NUMBER}
          </InputLabel> */}
          <PhoneInput name={contanct_no.name} label={props.language.PROFILE_CONTACT_NUMBER} fullWidth onChange={(value) => setFieldValue("contanct_no",value)} />
        </Grid>
        
        <Grid item xs={12} sm={7}>
          <SelectField
            name={work_profile.name}
            label={props.language.PROFILE_WORK_PROFILE}
            data={work_profile_data}
            fullWidth
          />
        </Grid>
        
        {/* <Grid item xs={12} sm={7}>
          <CheckboxField
            name={useAddressForPaymentDetails.name}
            label={useAddressForPaymentDetails.label}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
