/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { GetCookies } from "../../_helpers/Cookies";
import { FaCog, FaRegUser } from 'react-icons/fa';
import { toAbsoluteUrl } from "../../_helpers";
import { CCModeDataTable } from "./CCModeTable/CCModeDataTable";
import { useHistory } from "react-router-dom";
import {
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import { NODE_API_URL,BASE_URL } from "../../../env_config";
  import Cookies from "js-cookie";
  import axios from "axios";
  import { TextField} from '@material-ui/core';
  import { TaxPopUp } from "../Popup/TaxPopUp";
  import {
    FaRegEdit,
} from "react-icons/fa";
import { SwalWarn } from "../Popup";

  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const CcmodeRule = (props) => {
    return (
        <>
        <div className="card shadow-none card-custom">
              <div className="card-body bg-white">  
                <CCModeDataTable userinfo={props.userinfo} language={props.language} ccmoderules={props.ccmoderules} setUpdateRules={props.setUpdateRules} updaterule={props.updaterule} loader={props.loader}/>
              </div>
            </div>
        </>
    );
};
