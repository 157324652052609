import React, { useMemo, useEffect,useState } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { TrackListColumn } from "./TrackListColumn";
import { toAbsoluteUrl } from '../../../../_helpers';
import { CheckBox } from '../../../tableFilter/CheckBox';
import { FaSortDown,FaSortUp} from "react-icons/fa";
import { Skeleton } from "@material-ui/lab";
import PerfectScrollbar from "react-perfect-scrollbar";
import Cookies from "js-cookie"
import axios from "axios"
import { NODE_API_URL,BASE_URL } from "../../../../../env_config";
// import { useSubheader } from "../../layout";
// import { useLocation } from "react-router";
// import { GlobleFilter, } from "../../../tableFilter";
// import { Dropdown, Modal } from 'react-bootstrap';
// import DummyData from "./searchresult.json";
// import { BsLightningFill} from "react-icons/bs";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};


export const ParcelTrackList = ({userinfo,setTrackListData,temp,dimondType,language,conversionrate,symbol,recomDataLenth}) => {
    const [stoneData, setStoneData] = useState([]);
    const [loading , setLoading] = useState(true)
 
    useEffect(() => {

        if (userinfo.id && dimondType){

            const finalData = {
                "user_id":userinfo.id,
                "diamond_type":dimondType,
                "cx_country":userinfo.country
            }
            axios({
                method: "get",
                url: `${NODE_API_URL}/INTL/fetchParcelTrackListDiamond`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params: finalData,
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                       setStoneData([res.data.data][0]) 
                       setTimeout(() => {
                        setLoading(false) 
                    }, 1000);  
                    }
                })
                .catch(error => {
                    if(error?.response?.data?.message === "UnAuthorized!"){
                      var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                      if(cookies[i] && !cookies[i].includes("view")){
                        var equals = cookies[i].indexOf("=");
                        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                      }
                    }
                    
                    window.location.replace(`${BASE_URL}/logout`);
                  }
                  })
        }
    }, [userinfo,temp,dimondType]);

    
    const columns = useMemo(()=> TrackListColumn(userinfo,language,conversionrate,symbol),[] )
  
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data:stoneData,
            initialState: { 
                pageSize: 100,
                sortBy: [
                    {
                        id: 'parcel_id',
                        desc: true
                    }
                ]
             }
            
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    ...columns,
                ];
            });
        }
    );
    useEffect(() => {
        setTrackListData(selectedFlatRows)
    },[selectedFlatRows])

    return (
        <>
            <div className="card shadow-none card-custom">
                <div className="card-header" style={{minHeight:"50px"}}>
                    <div className="card-title font-weight-bold font-size-h5 text-primary m-0">
                        {language.PARCEL_DIAMONDS_TRACKLIST} 
                    </div>
                </div>
                {stoneData.length !== 0 ? 
                    <div className="card-body pt-0 px-5 pb-0">
                        <div className="table-responsive rounded custom-scrollbar w-100" >
                            <div
                                className="custom-scrollbar border-bottom"
                                style={{ maxHeight: recomDataLenth !== 0 ? recomDataLenth === 2 ? "25vh" : "36vh" : "55vh", position: "relative" }}
                            >
                                <table className="table table-hover parcel_table m-0" {...getTableProps()} >
                                    <thead className="custom_datatable_head sticky-top" style={{backgroundColor:"#fff4e0"}}>
                                        {headerGroups.map((headerGroup, i) => (
                                            <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                                {headerGroup.headers.map(
                                                    (column, i) => (
                                                        <th key={i} className="text-center align-middle text-nowrap" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render("Header")}
                                                            <span className="text-secondary">
                                                                {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                            </span>
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    {!loading ?
                                        <tbody {...getTableBodyProps()}>
                                            {stoneData.length !== 0 ? page.map((row) => {
                                                prepareRow(row)
                                                const isSelected = selectedRowIds[row.id];
                                                return(
                                                    <React.Fragment key={row.id}>
                                                        <tr {...row.getRowProps()} key={row.id} className={`${isSelected ? 'bg-success-o-30' : 'bg-white'}`}>
                                                            {row.cells.map((cell,i) => {
                                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                            })}
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }) :
                                            <tr>
                                                <td className="text-center align-middle"colSpan="100%">
                                                    No data found
                                                </td>
                                            </tr>}
                                        </tbody>
                                    :
                                        <tbody>
                                            {Array.from(Array(5), (e, i) => {
                                                return (
                                                    <tr key={i} className="bg-white">
                                                        <td><Skeleton  className="mx-auto" variant="text" width={15} /></td>
                                                        {Array.from(Array(3), (e, i) => {
                                                            return (
                                                                <td key={i}><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                                            );
                                                        })}
                                                        <td>
                                                                <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                                                <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                                            </td>
                                                        {Array.from(Array(2), (e, i) => {
                                                            return (
                                                                <td key={i}><Skeleton  className="mx-auto" variant="text" width={40} /></td>
                                                            );
                                                        })}
                                                        {Array.from(Array(2), (e, i) => {
                                                            return (
                                                                <td key={i}>
                                                                    <Skeleton className="mx-auto" variant="text" width={80} height={20} />
                                                                    <Skeleton className="mx-auto" variant="text" width={80} height={10} />
                                                                </td>
                                                            );
                                                        })}
                                                        {Array.from(Array(2), (e, i) => {
                                                            return (
                                                                <td key={i}><Skeleton  className="mx-auto" variant="text" width={80} /></td>
                                                            );
                                                        })}
                                                        
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {recomDataLenth !==0  ? 
                                <>
                                    { stoneData.length > 3 ? <div className="text-primary text-right">Scroll for explore more</div> : ""}
                                </>
                            :
                                <>
                                    { stoneData.length > 5 ? <div className="text-primary text-right">Scroll for explore more</div> : ""}
                                </>
                            }
                            
                        </div>
                    </div>
                :
                    <div className="card-body d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={toAbsoluteUrl("/media/General/tracklist-no.png")} className="img-fluid" alt="tracklist-no" style={{height:"205px"}}/>
                            </div>
                            <div className="col-12 mt-3 text-center text-primary font-weight-bold font-size-h5">
                                Oops! No Stones in Tracklist
                            </div>
                        </div>                          
                    </div>
                }
            </div>
        </>
    );
};
