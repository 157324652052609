/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useEffect,useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
// import { ImPrinter } from 'react-icons/im';
import { useLocation,useHistory} from "react-router-dom";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

export const PaymentFailed = (props) => {
    const params = useLocation()
    const history = useHistory()
    const [transactiondetails,setTransactionDetails] = useState()
    useEffect(() => {
        if(params && params.search){
            let sessionid = params.search.split("?session_id=")[1]
            axios({
                method: "POST",
                url: `${NODE_API_URL}/INTL/getTransaction`,
                headers: { "Content-Type": "application/json" },
                data:{
                    user_id:props.userinfo.id,
                    checkout_session_id:sessionid
                }
            })
            .then(async function(res) {
                if(res && res.data && res.data.data){
                    setTransactionDetails(res.data.data)
                }
            })
            .catch(function(res) {
                
            });
        }
    },[])
    return (
        <>
            <div className="card bg-white custom-card">
                <div className="card-body" style={{height:'82vh'}}>
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">{props.language.PAYMENT_FAILED_PAR1}</div>
                            <p className="text-dark mt-4 font-weight-bold mb-0">{props.language.PAYMENT_FAILED_PAR2}</p>
                            <p className="text-dark mt-4 font-weight-bold">{props.language.PAYMENT_FAILED_TRANSACTION_NO}:  {transactiondetails?transactiondetails.checkout_session_id:""}</p>
                            <p className="text-muted mt-4 font-weight-bold">{props.language.PAYMENT_FAILED_TIME} : {new Date().toLocaleTimeString()} </p>
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <p className="small">{props.language.PAYMENT_FAILED_PAR3}</p>
                        </div>
                        <div className="col-md-8 mt-md-10 mt-5 text-center">
                            <button className="btn btn-primary px-10" onClick={() => history.push('/confirmgoods')}>{props.language.PAYMENT_FAILED_TRYAGAIN}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
