import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SharingComponent from "./SharingComponent";
import ShareSetting from "./ShareSetting";

export const ShareStoneDetails = (props) => {
    const [openShareLink, setOpenShareLink] = useState(false);
    const [markupValue, setMarkupValue] = useState("");
    const [selectedValue, setSelectedValue] = React.useState('current_price');
    const [markupError, setMarkupError] = useState(false);
    const handleBack = () => {
        setOpenShareLink(false);
    };
    const handleNext =()=>{
        if (selectedValue === "markup_price" && !markupValue){
            setOpenShareLink(false)
            setMarkupError("Markup value is Required")
        }else{
            setOpenShareLink(true)
        }
    }
    useEffect(() => {
        if(markupValue){setMarkupError(false)}
    },[markupValue])

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                // backdrop="static"
                // keyboard={false}
                centered
            >
                <Modal.Body >
                    {!openShareLink ?
                        <div className="row">
                            <div className="col-12">
                                <ShareSetting close={props.onClose} setMarkupValue={setMarkupValue} markupValue={markupValue} selectedValue={selectedValue} setSelectedValue={setSelectedValue} markupError={markupError}/>
                            </div>
                            <div className="col-12 mt-5 text-right">
                                <button className="btn btn-outline-secondary btn-sm px-8" onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    :
                        <div className="row">
                            <div className="col-12">
                                <SharingComponent onBack={handleBack} close={props.onClose} />
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};
