import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Carat } from "./BasicLab/Carat";
import {Shape} from "./BasicLab/Shape"
import {Color} from "./BasicLab/Color"
import { Clarity } from "./BasicLab/Clarity";
import { Lab } from "./BasicLab/Lab";
import { Shortcut } from "./BasicLab/Shortcut";
import { Finish } from "./BasicLab/Finish";
import { Flour } from "./BasicLab/Flour";
import { Location } from "./BasicLab/Location";

export const BasicLab = ({option,formik,language}) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle className="sticky-top" style={{top:"64px"}}  as={Card.Header} eventKey="1" key="1">
                        {language.BASIC_BASIC}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Shape language={language}/>
                            <Carat formik={formik} language={language}/>
                            <Color formik={formik} option={option} language={language}/>
                            <Clarity language={language}/>
                            <Lab language={language}/>
                            <Shortcut language={language} formik={formik}/>
                            <Finish language={language}/>
                            <Flour language={language}/>
                            <Location language={language} formik={formik} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
