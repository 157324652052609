import React from "react";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";
// import { BASE_URL } from "./../../../../../env_config"

export default function PersonalInfo({userinfo,setEdit,contactbookuserinfo,language}) {
    return (
        <>
            <div className="row">
                <div className="col-12 border-bottom d-flex justify-content-between pb-3 mb-5">
                    <h4>{language.PROFILEVIEW_USER_INFORMATION}</h4>
                    {/* <button className="btn btn-sm btn-secondary" onClick={() => setEdit(true)}>{language.PROFILE_EDIT}</button> */}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILEVIEW_FIRST_NAME}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.fname}</div>
                    </div>
                    {contactbookuserinfo.fname !== userinfo.fname?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILEVIEW_LAST_NAME}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.lname}</div>
                    </div>
                    {contactbookuserinfo.lname !== userinfo.lname?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                   <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILEVIEW_EMAIL}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.mail}</div>
                   </div>
                    {contactbookuserinfo.mail !== userinfo.mail?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                   <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILEVIEW_CONTACTNO}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.mobile}</div>
                   </div>
                    {contactbookuserinfo.mobile !== userinfo.mobile?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILEVIEW_WORK_PROFILE}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.work_profile}</div>
                    </div>
                    {contactbookuserinfo.work_profile !== userinfo.work_profile?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
            </div>
        </>
    );
}
