import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsSortDown } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export default function Sorting(props) {
    const options = [
        { label: "Carat", id: "C_Weight", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Stock ID", id: "Stock ID", ascLabel: "Asc", descLabel: "Desc" },
        { label: "Shape", id: "Shape", ascLabel: "A to Z", descLabel: "Z to A" },
        { label: "Color", id: "Color", ascLabel: "D to Z", descLabel: "Z to D" },
        { label: "Clarity", id: "C_Clarity", ascLabel: "I3 to FL", descLabel: "FL to I3" },
        { label: "Cut", id: "C_Cut", ascLabel: "PR to ID", descLabel: "ID to PR" },
        { label: "Fluorescence", id: "C_Fluorescence", ascLabel: "NON to VST", descLabel: "VST to NON" },
        { label: "Lab", id: "Lab", ascLabel: "A to Z", descLabel: "Z to A" },
        { label: "Price per Carat", id: "OurRate", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Total Price", id: "OurPrice", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Measurement", id: "Meas.", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Table %", id: "C_TableP", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Depth %", id: "C_DepthP", ascLabel: "Low to High", descLabel: "High to Low" },
        { label: "Certificate", id: "Certificate", ascLabel: "Asc", descLabel: "Desc" },
        { label: "Ratio", id: "Ratio", ascLabel: "Low to High", descLabel: "High to Low" },
    ];

    const getSelectedOption = () => {
        const selectedOption = options.find(
            option => option.id === props.sortobj.id
        );
        if (selectedOption) {
            return `${selectedOption.label} (${props.sortobj.sortorder === "asc" ? selectedOption.ascLabel : selectedOption.descLabel})`;
        }
        return "Sort";
    };

    const handleSelect = (id, sortorder) => {
        props.setSortObj({ id, sortorder });
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" size='sm' className="mr-2">
                <BsSortDown /> {getSelectedOption()} <RiArrowDropDownLine />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll"
                    style={{ maxHeight: "400px", position: "relative" }}
                >
                    {options.map(option => (
                        <React.Fragment key={option.id}>
                            <Dropdown.ItemText className='border-bottom' style={{ padding: "0.25rem 1.25rem" }}>
                                <span className='small text-muted font-weight-bold'>{option.label}</span>
                            </Dropdown.ItemText>
                            <Dropdown.Item
                                style={{ padding: "0.15rem 1.65rem" }}
                                onClick={() => handleSelect(option.id, "desc")}
                                active={props.sortobj.id === option.id && props.sortobj.sortorder === "desc"}
                            >
                                {option.descLabel}
                            </Dropdown.Item>
                            <Dropdown.Item
                                style={{ padding: "0.15rem 1.65rem" }}
                                onClick={() => handleSelect(option.id, "asc")}
                                active={props.sortobj.id === option.id && props.sortobj.sortorder === "asc"}
                            >
                                {option.ascLabel}
                            </Dropdown.Item>
                        </React.Fragment>
                    ))}
                </PerfectScrollbar>
            </Dropdown.Menu>
        </Dropdown>
    );
}
