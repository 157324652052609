import React ,{useEffect,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import {Button} from '@material-ui/core';
import { FaEdit } from 'react-icons/fa';
import {DropzoneDialog} from 'material-ui-dropzone'
import axios from "axios"
import { NODE_API_URL } from "../../../../../env_config";
export default function UploadLogo(props){
    const [open,setOpen] = useState(false)
    function handleSave(files) {
    const userData = JSON.parse(localStorage.getItem("userData"))
    const formData = new FormData();
    formData.append("company_logo", files[0])
    formData.append("user_id", userData.CustomerId)
    axios({
        method: "POST",
        url: `${NODE_API_URL}/INTL/UpdateCompanyLogo`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      }).then(response => {
        // console.log(response,"response")
        window.location.reload(true)
      })
    
    }
    return (
        <div>
           <OverlayTrigger
              placement="bottom"
              className="text-capitalize"
              overlay={<Tooltip id="tooltip">Click to change Image</Tooltip>}
            >
              <span className="logoUpploadBtn"  onClick={() => setOpen(true)}>
                <FaEdit />
              </span>
            </OverlayTrigger>
            <span className="ml-3">{props.filefield === "passport_id_file" && props.values && props.values.passport_id_file?props.values.passport_id_file:""}</span >
            <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['image/jpeg','image/jpg', 'image/png', 'image/bmp']}
                showPreviews={false}
                maxFileSize={2100000}
                onClose={() => setOpen(false)}
                filesLimit={1}
                showPreviewsInDropzone={true}
                previewText={true}
                dropzoneText={<span>Drag & Drop <br /> <span className="small font-italic text-muted">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</span ></span>}
            />
        </div>
    );
}