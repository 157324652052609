import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { MockShortListColumn } from './ApiV3SupColumns'; 
import { GlobleFilter } from '../../tableFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";
// import { CheckBox } from './CheckBox';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
// import  {ExpandedDetails} from "./Expand/ExpandedDetails"
import axios from 'axios';
import { NODE_API_URL } from '../../../../env_config';
import ApiDataSkeleton from './ApiDataSkeleton'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SwalWarn } from '../../Popup';
export const ApiV3DataLabSup = (props) => {
    const [swalopen,setSwalOpen] = useState(false)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    // const renderRowSubComponent = React.useCallback(
    //     (row) => (
    //         <>
    //             <ExpandedDetails row={row}/>
    //         </>
    //     ),[]
    //   )
    const [update,setUpdate] = useState(false)
    const [loading,setLoading] = useState(false)
    const [erpireddate] = useState([])
    const [data,setData] = useState([])
    useEffect(() => {
        setLoading(true)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/fetchRuleByName`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id,
                rule_name:props.values.rulename
            },
        }).then(ruleres => {
            if(ruleres && ruleres.data && ruleres.data.success){
                let diamondtype = "N"
                let naturaldiamond = 0
                let naturalfancydiamond = 0
                let lgwhitediam = 0
                let lgfancydiam = 0
                if(props.values.naturaldiamond){
                  diamondtype = "N"
                  naturaldiamond = 1
                }
                if(props.values.naturalfancydiamond){
                  diamondtype = "N"
                  naturalfancydiamond = 1
                }
                if(props.values.lgwhitediam){
                  diamondtype = "L"
                  lgwhitediam = 1
                }
                if(props.values.lgfancydiam){
                  diamondtype = "L"
                  lgfancydiam = 1
                }
                let FinalObject = {
                    "user_id":props.userinfo.id,
                    "api_id":props.userinfo.api_id,
                  diamond_type:diamondtype,
                  naturaldiamond:naturaldiamond,
                  naturalfancydiamond:naturalfancydiamond,
                  labdiamond:lgwhitediam,
                  labfancydiamond:lgfancydiam,
                  rule_name:props.values.rulename,
                  user_id:props.userinfo.id,
                  suppliers:props.values.suppliers,
                  api_currency:props.userinfo.api_currency,
                  "rule_id":ruleres.data.data.rule_id,
                }
                if(props.values.markupoption === "Dollar"){
                  FinalObject["markupdollar"] = parseFloat(props.values.markup)
                }
                else{
                  FinalObject["markupperc"] = parseFloat(props.values.markup)
                  FinalObject["currency"] = props.values.multi_currency
                }
                if(diamondtype === "N"){
                    FinalObject["shape"] = props.values.diamondshape
                    FinalObject["cut"] = props.values.cuts
                    FinalObject["clarity"] = props.values.diamondclarity
                    FinalObject["min_carat"] = parseFloat(props.values.carat_from)
                    FinalObject["max_carat"] = parseFloat(props.values.carat_to)
                    FinalObject["lab"] = props.values.lab_val
                    FinalObject["symmetry"] = props.values.symmetries
                    FinalObject["fluorescence"] = props.values.diamondflour
                    FinalObject["polish"] = props.values.polishes
                    FinalObject["milky"] = props.values.milky
                    FinalObject["eyeclean"] = props.values.eyeclean
                    FinalObject["shade"] = props.values.diamondshade
                    FinalObject["min_dollarperct"] = parseFloat(props.values.price_from)
                    FinalObject["max_dollarperct"] = parseFloat(props.values.price_to)
                    FinalObject["total_price_from"] = parseFloat(props.values.total_price_from)
                    FinalObject["total_price_to"] = parseFloat(props.values.total_price_to)
                    FinalObject["media"] = props.values.media
                    FinalObject["minlength"] = parseFloat(props.values.measurementlengthfrom)
                    FinalObject["maxlength"] = parseFloat(props.values.measurementlengthto)
                    FinalObject["minwidth"] = parseFloat(props.values.measurementwidthfrom)
                    FinalObject["maxwidth"] = parseFloat(props.values.measurementwidthto)
                    FinalObject["minheight"] = parseFloat(props.values.measurementheightfrom)
                    FinalObject["maxheight"] = parseFloat(props.values.measurementheightto)
                    FinalObject["tablemin"] = parseFloat(props.values.tablefrom)
                    FinalObject["tablemax"] = parseFloat(props.values.tableto)
                    FinalObject["depthmin"] = parseFloat(props.values.depthfrom)
                    FinalObject["depthmax"] = parseFloat(props.values.depthto)
                    FinalObject["ratiomin"] = parseFloat(props.values.ratiofrom)
                    FinalObject["ratiomax"] = parseFloat(props.values.ratioto)
                    FinalObject["crheightmin"] = parseFloat(props.values.crownheightfrom)
                    FinalObject["crheightmax"] = parseFloat(props.values.crownheightto)
                    FinalObject["cranglemin"] = parseFloat(props.values.crownanglefrom)
                    FinalObject["cranglemax"] = parseFloat(props.values.crownangleto)
                    FinalObject["pavheightmin"] = parseFloat(props.values.pavheightfrom)
                    FinalObject["pavheightmax"] = parseFloat(props.values.pavheightto)
                    FinalObject["pavanglemin"] = parseFloat(props.values.pavanglefrom)
                    FinalObject["pavanglemax"] = parseFloat(props.values.pavangleto)
                    FinalObject["brand"] = props.values.brand
                    FinalObject["origin"] = props.values.origin
                    FinalObject["treatment"] = props.values.treatment
                    FinalObject["keytosymbol"] = props.values.keytosymbol
                    if(naturaldiamond){
                      FinalObject["color"] = props.values.diamondcolor
                    }
                    else{
                      FinalObject["fancy_color"] = props.values.diamondfancy_color
                      FinalObject["fancy_intensity"] = props.values.diamondfancy_intensity
                      FinalObject["fancy_overtone"] = props.values.diamondfancy_overtone
                    }
                }
                else{
                  FinalObject["shape"] = props.values.lab_shape
                  FinalObject["cut"] = props.values.lab_cuts
                  FinalObject["clarity"] = props.values.lab_clarity
                  FinalObject["min_carat"] = parseFloat(props.values.lab_carat_from)
                  FinalObject["max_carat"] = parseFloat(props.values.lab_carat_to)
                  FinalObject["lab"] = props.values.lab_lab
                  FinalObject["symmetry"] = props.values.lab_symmetries
                  FinalObject["fluorescence"] = props.values.lab_flourselect
                  FinalObject["polish"] = props.values.lab_polishes
                  FinalObject["milky"] = props.values.lab_milky
                  FinalObject["eyeclean"] = props.values.lab_eyeclean
                  FinalObject["shade"] = props.values.lab_shadeselect
                  FinalObject["min_dollarperct"] = parseFloat(props.values.lab_min_dollarperct)
                  FinalObject["max_dollarperct"] = parseFloat(props.values.lab_max_dollarperct)
                  FinalObject["total_price_from"] = parseFloat(props.values.lab_total_price_from)
                  FinalObject["total_price_to"] = parseFloat(props.values.lab_total_price_to)
                  FinalObject["media"] = props.values.lab_all_media
                  FinalObject["minlength"] = parseFloat(props.values.labmeasurementlengthfrom)
                    FinalObject["maxlength"] = parseFloat(props.values.labmeasurementlengthto)
                    FinalObject["minwidth"] = parseFloat(props.values.labmeasurementwidthfrom)
                    FinalObject["maxwidth"] = parseFloat(props.values.labmeasurementwidthto)
                    FinalObject["minheight"] = parseFloat(props.values.labmeasurementheightfrom)
                    FinalObject["maxheight"] = parseFloat(props.values.labmeasurementheightto)
                    FinalObject["tablemin"] = parseFloat(props.values.labtablefrom)
                    FinalObject["tablemax"] = parseFloat(props.values.labtableto)
                    FinalObject["depthmin"] = parseFloat(props.values.labdepthfrom)
                    FinalObject["depthmax"] = parseFloat(props.values.labdepthto)
                    FinalObject["ratiomin"] = parseFloat(props.values.labratiofrom)
                    FinalObject["ratiomax"] = parseFloat(props.values.labratioto)
                    FinalObject["crheightmin"] = parseFloat(props.values.labcrownheightfrom)
                    FinalObject["crheightmax"] = parseFloat(props.values.labcrownheightto)
                    FinalObject["cranglemin"] = parseFloat(props.values.labcrownanglefrom)
                    FinalObject["cranglemax"] = parseFloat(props.values.labcrownangleto)
                    FinalObject["pavheightmin"] = parseFloat(props.values.labpavheightfrom)
                    FinalObject["pavheightmax"] = parseFloat(props.values.labpavheightto)
                    FinalObject["pavanglemin"] = parseFloat(props.values.labpavanglefrom)
                    FinalObject["pavanglemax"] = parseFloat(props.values.labpavangleto)
                    FinalObject["brand"] = props.values.brand
                    FinalObject["origin"] = props.values.laborigin
                    FinalObject["labtreatment"] = props.values.labtreatment
                    FinalObject["keytosymbol"] = props.values.labkeytosymbol
                  if(lgwhitediam){
                    FinalObject["color"] = props.values.lab_color
                  }
                  else{
                    FinalObject["fancy_color"] = props.values.lab_fancy_color
                    FinalObject["fancy_intensity"] = props.values.lab_fancy_intensity
                    FinalObject["fancy_overtone"] = props.values.lab_fancy_overtone
                  }
                }
                console.log(FinalObject,"FinalObject")
                // const FinalObject = {
                //     "user_id":props.userinfo.id,
                //     "api_id":props.userinfo.api_id,
                //     "diamond_type":diamondtype
                // }
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/INTL/fetchSupplierRules`,
                    headers: { "Content-Type": "application/json" },
                    data: FinalObject,
                }).then(res => {
                    console.log(res.data,"res.data")
                    if(res && res.data && res.data === "Please Turn On Suppliers"){
                        setLoading(false)
        
                    }
                    if(res && res.data && res.data.data){
                        // setData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/INTL/fetchSupplierRuleRequest`,
                            headers: { "Content-Type": "application/json" },
                            data: {
                                "user_id":props.userinfo.id,
                                "rule_id":ruleres.data.data.rule_id
                            },
                        }).then(supreq => {
                            setLoading(false)
                            if(supreq && supreq.data && supreq.data.data){
                                // setData(res.data.data)
                                let merged = [];
        
                                // for (let i = 0; i < supreq.data.data.length; i++) {
                                //     merged.push({
                                //         ...supreq.data.data[i],
                                //         ...(res.data.data.find((itmInner) => itmInner.id === supreq.data.data[i].supplier_id))
                                //     }
                                //     );
                                // }
                                // let count = 0
                                for (let i = 0; i < res.data.data.length; i++) {
                                    // if(res.data.data[i].whitetotal){
                                    //     count += res.data.data[i].whitetotal
                                    // }
                                    // if(res.data.data[i].fancytotal){
                                    //     count += res.data.data[i].fancytotal
                                    // }
                                    const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_name === res.data.data[i].supplier_name))} 
                                    console.log(fetcheddata,"fetcheddata")
                                    merged.push({
                                        ...res.data.data[i],
                                        ...fetcheddata
                                    }
                                    );
                                    // let mergedobj = {
                                    //     ...res.data.data[i],
                                    //     ...fetcheddata
                                    // }
                                    // if((props.values.naturaldiamond || props.values.lgwhitediam) && mergedobj.whitetotal && mergedobj.api_on_off === 1){
                                    //     count += mergedobj.whitetotal
                                    // }
                                    // if((props.values.naturalfancydiamond || props.values.lgfancydiam) && mergedobj.fancytotal && mergedobj.api_on_off === 1){
                                    //     count += mergedobj.fancytotal
                                    // }
                                }
                                // console.log(merged,"merged")
                                merged = merged.filter(value => {
                                    if(value.supplier_name){
                                        return value
                                    }
                                })
                                setData(merged,"merged")
                                // setTotalCount(count)
                            }
                        })
                    }
                })
            }
        })   
    },[props.updateapi,update])
    // console.log(data,"CCMODEDATAAA")
    const [totalcount,setTotalCount] = useState(0)

    useEffect(() => {
        let finaloutput = []
        let promises = []
        let count = 0
        for (let i = 0; i < data.length; i++) {
            
            if((props.values.naturaldiamond || props.values.lgwhitediam) && data[i].whitetotal && data[i].on_off === 1){
                count += data[i].whitetotal
            }
            if((props.values.naturalfancydiamond || props.values.lgfancydiam) && data[i].fancytotal && data[i].on_off === 1){
                count += data[i].fancytotal
            } 
        }
        setTotalCount(count)
        props.setAllSupplierData(data)
    }, [data])
    // console.log(erpireddate,"erpireddate")
    console.log(data,"DATATATAAT")
    const columns = useMemo(()=> MockShortListColumn(setUpdate,update,erpireddate,toast,props.language,setData,data,props),[erpireddate,data] )
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    // Cell: ({ row }) => (
                    //     <span  {...row.getToggleRowExpandedProps()}>
                    //         {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  row.original.ShortListedStones?<FaChevronRight className="text-primary" title="Expand"/>:""}
                    //     </span>
                    // ),
                    // },
                    ...columns,
                ]
            })
        }
    )
    const [apistatus,setApiStatus] = useState()
    const TurnOnorOffAll = (api_on_off) => {
        setSwalOpen(true)
        setApiStatus(api_on_off)
        // axios({
        //     method: "POST",
        //     url: `${NODE_API_URL}/INTL/turnOnorOffAll`,
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //         user_id:props.userinfo.id,
        //         api_id:props.userinfo.api_id,
        //         type:"Natural",
        //         api_on_off:api_on_off
        //     },
        // }).then(supreq => {
        //     if(supreq && supreq.data){
        //         if(supreq.data){
        //         setUpdate(supreq.data)
        //         if(api_on_off === 1){
        //             toast("Your All Feed Turn on",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }else{
        //             toast("Your All Feed Turn Off",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }
        //         }
        //     }
        // })
    }
    const FinalOk = (api_on_off) => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/fetchRuleByName`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id,
                rule_name:props.values.rulename
            },
        }).then(ruleres => {
            if(ruleres && ruleres.data && ruleres.data.success){
                let supplierson = data.map(val => val.supplier_name)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/turnAllOnorOffSupplierRule`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id,
                on_off:api_on_off,
                suppliers:supplierson,
                rule_id:ruleres.data.data.rule_id
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data){
                setUpdate(!update)
                setSwalOpen(false)

                if(api_on_off === 1){
                    toast.success("Your All Suppliers Turned on")
                }else{
                    toast.error("Your All Suppliers Turned Off")
                }
                }
            }
        })
            }
        })
    }
    return (
        <>
        {loading?<ApiDataSkeleton /> :!data.length > 0 ? <h6 className="mt-25 text-center text-danger">Please turn On Suppliers from Supplier Management Page</h6> : 
        <div>
          <SwalWarn show={swalopen} message={apistatus === 1?"are you sure you want to turn on all?":"are you sure you want to turn off all?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => FinalOk(apistatus)}/> 
            <div className="row">
            <div className="col-12 py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div><GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} /></div>
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                    <div  className="p-3" >
                                        <label className="checkbox my-2 border p-2">
                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                            <span className="mr-2"></span>
                                            Show All
                                        </label> 
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    <div>
                    <h6 className="mt-10 mr-40">
                    Total Count : {totalcount}
                    </h6>
                    </div>
                    <div className="search_result_dropdown">
                        <Dropdown >
                            <Dropdown.Toggle size="sm" variant="clear">
                                <button type="button" className='btn btn-icon btn-light-primary p-0'><FaEllipsisV /></button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => TurnOnorOffAll(1)}>Turn On All</Dropdown.Item>
                                <Dropdown.Item onClick={() => TurnOnorOffAll(2)}>Turn Off All</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                {/* <div className="col-12">
                    <h6 className="text-center">
                    Total Count : {totalcount}
                    </h6>
                </div> */}
                <div className="col-12 pt-7 pb-0 mt-n3">
                    <div className="table-responsive w-100">
                        <table className="table  table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row,i) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={i}>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {/* {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null} */}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button type="button" className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}