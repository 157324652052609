/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState,useEffect } from "react";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import moment from "moment-timezone";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEdit,FaSave} from "react-icons/fa";
    import { SwalWarn } from "../Popup";
import { ImCancelCircle } from "react-icons/im";

export function TimeZone({ userinfo }) {
    const [swalWarnOpenTz,setSwalWarnOpenTz] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [loading, setLoading] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    // const [timezone, setTimezone] = useState('');
    const [tz, setTz] = useState(userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [tzLable,setTzLable]=useState(null)
    const [datetime, setDatetime] = useState(moment());
    const [timezoneView, setTimezoneView] = useState(true)

    useEffect(() => {
        const offsetMinutes = moment.tz(tz.value ?? tz).utcOffset();
        setTzLable(offsetMinutes);
    }, [tz]);

    // console.log(datetime)
    const formatOffsetLabel = (offset) => {
        const offsetHours = Math.floor(Math.abs(offset) / 60);
        const offsetMinutes = Math.abs(offset) % 60;
        const sign = offset >= 0 ? '+' : '-';
        return `UTC ${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
    };

    const saveTimeZone = (tz) => {
        setLoading(true);
        const finalData = {
            timezone: tz.value,
            id: userinfo.id,
        };

        axios({
            method: "POST",
            url: NODE_API_URL + "/INTL/insertTimeZOne",
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                // console.log("res", res);
                setLoading(false);
                setTimezoneView(true)
                if (res && res.data) {
                    setsuccessMsg(res.data.msg)
                }

                setSwalWarnOpenTz(false)
                const timeId = setTimeout(() => {
                    setsuccessMsg()
                }, 5000)
            
                return () => {
                    clearTimeout(timeId)
                }
            })
            .catch((res) => {
                setLoading(false);
            });
    };

    const swalWarnOpenfnTz = (tz) => {
        setSwalWarnOpenTz(true)
        setAlertMessage(`Are you sure want to change timezone to (${formatOffsetLabel(tzLable)}) ${tz.value || tz} ?`)
    }

    useMemo(() => {
        const tzValue = tz.value ?? tz;
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);

    return (
        <>
            <div className="row">
                <div className="col-12 mb-4 setting-title">TIMEZONE SETTING</div>
                    <div className="col-12" style={{minHeight:"258px"}}>
                        {timezoneView ? 
                            <div className="row mt-10" >
                                {successMsg ? (
                                    <div className="mb-5 col-12 alert alert-custom alert-light-success py-3 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            {successMsg}
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                                <div className="col-md-12 font-size-h6 font-weight-bolder">
                                    Selected Time-zone : 
                                    <span className="font-weight-bold ml-3">{tzLable !== null && <>({formatOffsetLabel(tzLable)})</>} {tz.value ? tz.value : tz ? tz : userinfo.timezone} </span>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip > Click to Edit Timezone </Tooltip>} >
                                        <FaEdit className="font-size-h6 text-primary text-hover-warning ml-2 cursor-pointer" onClick={()=> setTimezoneView(false)}/>
                                    </OverlayTrigger>
                                </div>
                                {/* <div className="col-12 mt-5">
                                    Current Date / Time in{" "}
                                    {tz.value
                                        ? tz.value.split("/")[1]
                                        : tz.split("/")[1]}
                                    :{" "}
                                    <pre>
                                        {datetime.format("YYYY-MM-DD HH:mm:ss")}
                                    </pre>
                                </div> */}
                            </div>
                        :
                            <div className="row mt-10">
                                {successMsg ? (
                                    <div className="mb-5 col-12 alert alert-custom alert-light-success py-3 alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            {successMsg}
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                                <div className="col-md-7">
                                    <div className="timezone--wrapper">
                                        <TimezoneSelect
                                            value={tz.value ? tz.value : tz ? tz : userinfo.timezone}
                                            displayValue={"UTC"}
                                            onChange={setTz}
                                            timezones={{
                                                ...allTimezones,
                                                "America/Lima": "Pittsburgh",
                                                "Europe/Berlin": "Frankfurt",
                                            }}
                                        />
                                    </div>
                                    
                                    <div className="mt-5">
                                        <button className="btn btn-light text-dark mr-2" disabled={loading} onClick={() => swalWarnOpenfnTz(tz)}>
                                            <FaSave className="font-size-lg mr-2" /> Save
                                            {loading && (
                                                <span className="ml-3 spinner spinner-white"></span>
                                            )}
                                        </button>
                                        <button className="btn btn-light text-dark" disabled={loading} onClick={() => setTimezoneView(true)}>
                                        <ImCancelCircle className="font-size-lg mr-2"/> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                         {/* <div className="output-wrapper mt-5">
                            <div>
                                Current Date / Time in{" "}
                                {tz.value
                                    ? tz.value.split("/")[1]
                                    : tz.split("/")[1]}
                                :{" "}
                                <pre>
                                    {datetime.format("YYYY-MM-DD HH:mm:ss")}
                                </pre>
                            </div>
                            <div>
                                <div>Selected Timezone:</div>
                                <pre>{JSON.stringify(tz, null, 2)}</pre>
                            </div>
                            {tzLable !== null && (
                                <p>
                                Timezone Offset: {tzLable} minutes 
                                </p>
                            )}
                        </div> */}
                </div>
            </div>
            <SwalWarn show={swalWarnOpenTz} message={alertMessage} onHide={() => setSwalWarnOpenTz(false)} onClose={() => setSwalWarnOpenTz(false)} responseOnOk={()=> saveTimeZone(tz)}/> 
        </>
    );
}
