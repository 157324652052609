import React, { useState,useEffect } from "react";
import {FormHelperText,Checkbox,InputLabel,ListItemIcon,ListItemText,MenuItem,FormControl,Select,makeStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
// import { options } from "./utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};


function MultiSelectEdit(props) {
  // console.log(props.name,props.values[props.name],"MultiSelect")
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  // console.log(meta.error?meta.error:"","errorMultiSelect")
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    data.length > 0 && selected.length === data.length;

  const handleChange = (event) => {
    const value = event.target.value;
    // console.log("value",selected)
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === data.length ? [] : data);
      return;
    }
    setSelected(value);
    props.setFieldValue(props.name,value)
  };
  const SelectAllFields = (event) => {
    // console.log("CHecking")
    props.setFieldValue(`${props.name}checked`,!props.values[`${props.name}checked`])
    if(props.values[`${props.name}checked`]){
        props.setFieldValue(props.name,[])
        props.setFieldValue(`${props.name}checked`,false)
    }
    // console.log(props.values[`${props.name}checked`],"LetsCHeckedInside",props.name)
    // console.log(event.target.checked,"event")
    // if(event.target.checked){
    //   let allfields = data.map(item => {
    //     return item.value
    //   })
    //   props.setFieldValue(props.name,allfields)
    // }
    // else{
    //   props.setFieldValue(props.name,[])
    // }

  }
  useEffect(() => {
    if(props.selectallfields){
        let allfields = data.map(item => {
          return item.value
        })
        props.setFieldValue(props.name,allfields)
        props.setFieldValue(`${props.name}checked`,true)
        props.setSelectAllFields(false)
      }
      else if(props.values[`${props.name}checked`]){
        let allfields = data.map(item => {
          return item.value
        })
        props.setFieldValue(props.name,allfields)
      }
      else{
      }
  },[props.values[`${props.name}checked`],props.selectallfields])
  useEffect(() => {
    if(props.values[props.name].length === data.length){
        props.setFieldValue(`${props.name}checked`,true)
    }else{
        props.setFieldValue(`${props.name}checked`,false)

    }
  },[props.values[props.name]])
  // console.log(props.values[`${props.name}checked`],"LetsCHeckedOutside",props.name)
  return (
    <FormControl {...rest} error={isError} className={classes.formControl}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
      {...field}
        labelId={label}
        multiple
        value={props.values[props.name]}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: props.values[`${props.name}checked`] ? classes.selectedAll : ""
          }}
          checked={props.values[`${props.name}checked`]}
            onClick={SelectAllFields}
        >
          {/* <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={props.values[`${props.name}checked`]}
              indeterminate={
                selected.length > 0 && selected.length < data.length
              }
              onClick={SelectAllFields}
            />
          </ListItemIcon> */}
          <ListItemText
            // classes={{ primary: classes.selectAllText }}
            primary="Select All"
            // checked={props.values[`${props.name}checked`]}
            // onClick={SelectAllFields}
          />
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <ListItemIcon>
              <Checkbox checked={props.values[props.name].indexOf(item.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={item.value} />
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

MultiSelectEdit.defaultProps = {
  data: []
};

MultiSelectEdit.propTypes = {
  data: PropTypes.array.isRequired
};

export default MultiSelectEdit;
