import React, { useState } from "react";
import axios from "axios"
import { NODE_API_URL } from "../../../env_config";
export default function UpgradeSubscriptionPage(props){
	const [loader,setLoader] = useState(false)
	const RequestForAPI = (plan) => {
		const finalData = {
			"user_id":props.userinfo.id,
			"feed_type":"API",
			"api_plan":plan
		}
		axios({
			method: "POST",
			url: `${NODE_API_URL}/INTL/updateApiPlan`,
			headers: { "Content-Type": "application/json" },
			data: finalData,
		}).then(res => {
			if(res && res.data){
			// console.log(res.data,"RESPONSEDATA")
			window.location.reload(true)
			}
		}).catch(err => {
			setLoader(false)
		})
	}
    return (
        <div class="container mt-25">
            <div className="text-center text-danger font-weight-bold mb-25">
                Disclaimer: After Upgrading Plan your Current Plan will be deactivated and you need to regenerate JWT Token.  
            </div>
<div class="row gutters">
	<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
		<div class="pricing-plan">
			<div class="pricing-header">
				<h4 class="pricing-title">Basic</h4>
				<div class="pricing-cost">$129.00</div>
				{/* <div class="pricing-save">Save $29.00</div> */}
			</div>
			<ul class="pricing-features">
				<li>cannot see supplier name, certificate</li>
				<li>only limited stock can access</li>
				<li>hits 50</li>
				<li>Video limit</li>
				{/* <li class="text-muted"><del>Plan Details Here .....</del></li>
				<li class="text-muted"><del>Plan Details Here .....</del></li> */}
			</ul>
			<div class="pricing-footer">
				{props.userinfo.api_plan === "Basic"?<button class="btn btn-primary btn-lg" disabled={true}>Subscribed</button>:<button class="btn btn-primary btn-lg" onClick={() => RequestForAPI("Basic")}>Select Plan</button>}
			</div>
		</div>
	</div>
	<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
		<div class="pricing-plan">
			<div class="pricing-header red">
				<h4 class="pricing-title">Standard</h4>
				<div class="pricing-cost">$229.00</div>
				{/* <div class="pricing-save">Save $49.00</div> */}
			</div>
			<ul class="pricing-features">
            <li>can see 20 supplier names</li>
				<li></li>
				<li></li>
				<li></li>
				{/* <li class="text-muted"></li>
				<li class="text-muted"></li> */}
			</ul>
			<div class="pricing-footer">
				{props.userinfo.api_plan === "Standard"?<button class="btn btn-danger btn-lg" disabled={true}>Subscribed</button>:<button class="btn btn-danger btn-lg" onClick={() => RequestForAPI("Standard")}>Select Plan</button>}
			</div>
		</div>
	</div>
	<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
		<div class="pricing-plan">
			<div class="pricing-header secondary">
				<h4 class="pricing-title">Enterprise</h4>
				<div class="pricing-cost">$329.00</div>
				{/* <div class="pricing-save">Save $99.00</div> */}
			</div>
			<ul class="pricing-features">
            <li>Unlimited Access</li>
				<li></li>
				<li></li>
				<li></li>
				{/* <li class="text-muted"></li> */}
				{/* <li class="text-muted"></li> */}
			</ul>
			<div class="pricing-footer">
				{props.userinfo.api_plan === "Enterprise"?<button class="btn btn-success btn-lg" disabled={true}>Subscribed</button>:<button class="btn btn-success btn-lg" onClick={() => RequestForAPI("Enterprise")}>Select Plan</button>}
			</div>
		</div>
	</div>
</div>
</div>
    )
}