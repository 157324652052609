import React from 'react';
import { useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputFieldFull = ({ label, name,required, ...props }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    // console.log(errors.name)
    const handleChange = value => {
        setFieldValue(name, value); // Manually set Formik field value as a string
    };
    return (
        <div className="form-group mb-5 align-items-center row">
            <div className={`col-md-${name==='shipping_phone' ?"8":"7"} ${touched[name] && errors[name] ? 'phoninputerror' : ''}`}>
                <PhoneInput
                    country={'in'} // Set default country if necessary
                    value={values[name] || ''} // Ensure value is always a string
                    onChange={handleChange}
                    inputProps={{
                        name,
                        onBlur: () => {}, // Implement onBlur if necessary
                        className: `form-control ${touched[name] && errors[name] ? 'is-invalid' : ''}`
                    }}
                    {...props}
                />
                {touched[name] && errors[name] ? (
                    <div className="invalid-feedback d-block">{errors[name]}</div>
                ) : null}
            </div>
        </div>
    );
};

export default PhoneInputFieldFull;
