/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useEffect,useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
// import { ImPrinter } from 'react-icons/im';
import { useLocation,useHistory} from "react-router-dom";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";

export const PaymentFailedNew = (props) => {
    const history = useHistory()
    return (
        <>
            <div className="card bg-white custom-card">
                <div className="card-body" style={{height:'82vh'}}>
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">{props.language.PAYMENT_FAILED_PAR1}</div>
                            <p className="text-dark mt-4 font-weight-bold mb-0">{props.language.PAYMENT_FAILED_PAR2}</p>
                            <p className="text-muted mt-4 font-weight-bold">{props.language.PAYMENT_FAILED_TIME} : {new Date().toLocaleTimeString()} </p>
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <p className="small">{props.language.PAYMENT_FAILED_PAR3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
