import React, { useState,useEffect } from 'react';
import { Grid, Typography,InputLabel } from '@material-ui/core';
import { InputField, CheckboxField, SelectField,PhoneInput } from '../FormFields';
import MuiUploader from '../FormFields/MuiUploader';
import axios from 'axios';
import Cookies from "js-cookie"
import { NODE_API_URL ,BASE_URL} from '../../../../../env_config';
const work_profile_data = [
  
  {
    value: 'CEO',
    label: 'CEO'
  },
  {
    value: 'Sales-Manager',
    label: 'Sales-Manager'
  },
  {
    value: 'Purchase-Manager',
    label: 'Purchase-Manager'
  },
  {
    value: 'account-manager',
    label: 'account-manager'
  },
  {
    value: 'secretary',
    label: 'secretary'
  },
  {
    value: 'business-development-executives',
    label: 'business-development-executives'
  },
  {
    value: 'other',
    label: 'other'
  }
];

export default function PersonalInfoForm(props) {
  const {
    formField: {
      firstName,
      lastName,
      email,
      contanct_no,
      work_profile,
      passport_id,
      passport_id_file,
    //   useAddressForPaymentDetails
    },
    setFieldValue,
    prev,
    setPassportFileName,
    FirstFile,
    setFirstFile,
    values,
    setPrev,
   } = props;
  useEffect(() => {
    if(!prev){
      if(props.userinfo.kyc_status === "NO_KYC" || props.userinfo.kyc_status === "KYC_APPR"){
        axios({
          method: "GET",
          url: `${NODE_API_URL}/INTL/noKycViewData`,
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
          }
      }).then(res => {
          if(res && res.data && res.data.data){
              setPrev(false)
             const GetWorkProfile = (workprofile) => {
              let id = null
              if(workprofile){
                work_profile_data.map(value => {
                  if(value.label.toLowerCase() === workprofile){
                    id = value.value
                  }
                })
              }
              // console.log(id,"idididid")
              return id
             }
            setFieldValue("firstName",res.data.data.fname)
            setFieldValue("lastName",res.data.data.lname)
            setFieldValue("email",res.data.data.mail)
            setFieldValue("contanct_no",res.data.data.mobile)
            setFieldValue("passport_id",res.data.data.passport_id)
            setFieldValue("work_profile",GetWorkProfile(res.data.data.work_profile))
            setFieldValue("company_name",res.data.data.cname)
            setFieldValue("address1",res.data.data.address)
            setFieldValue("address2",res.data.data.secaddress)
            setFieldValue("city",res.data.data.city)
            setFieldValue("state",res.data.data.state)
            setFieldValue("zipcode",res.data.data.com_zip)
            setFieldValue("country",res.data.data.country)
            setFieldValue("telephone",res.data.data.contact2)
            setFieldValue("fax",res.data.data.fax)
            setFieldValue("invoice_email",res.data.data.invoiceemail)
            setFieldValue("website",res.data.data.website)
            setFieldValue("comp_reg_no",res.data.data.com_reg_no)
            setFieldValue("nature_of_business",res.data.data.nature_buss)
            setFieldValue("business_est_date",res.data.data.buss_esta_date)
            setFieldValue("name_of_partner",res.data.data.director_name)
            setFieldValue("contact_of_partner",res.data.data.directory_contact)
            setFieldValue("shipping_address1",res.data.data.shipping_address)
            setFieldValue("shipping_city",res.data.data.city)
            setFieldValue("shipping_state",res.data.data.state)
            setFieldValue("shipping_zipcode",res.data.data.com_zip)
            setFieldValue("shipping_country",res.data.data.country)
            setFieldValue("shipping_telephone",res.data.data.shipping_phone)
            setFieldValue("shipping_email",res.data.data.shipping_email)
            setFieldValue("tax_id",res.data.data.company_tax)
            setFieldValue("port_of_discharge",res.data.data.port_of_discharge)
          }
      }).
      catch(error => {
        if(error?.response?.data?.message === "UnAuthorized!"){
          var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          if(cookies[i] && !cookies[i].includes("view")){
            var equals = cookies[i].indexOf("=");
            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        
        window.location.replace(`${BASE_URL}/logout`);
      }
      })
      }
      else{
        axios({
          method: "GET",
          url: NODE_API_URL + "/INTL/getKycPendingData",
          headers: { 
              "Content-Type": "application/json",
              "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
          }
        }).then((res) => {
          if(res && res.data && res.data.data){
            setPrev(false)
           const GetWorkProfile = (workprofile) => {
            let id = null
            if(workprofile){
              work_profile_data.map(value => {
                if(value.label.toLowerCase() === workprofile?.toLowerCase()){
                  id = value.value
                }
              })
            }
            // console.log(id,"idididid")
            return id
           }
           console.log(GetWorkProfile(res.data.data.work_profile),"WOERKDOAJDADJI")
           setFieldValue("firstName",res.data.data.fname)
           setFieldValue("lastName",res.data.data.lname)
           setFieldValue("email",res.data.data.mail)
           setFieldValue("contanct_no",res.data.data.mobile)
           setFieldValue("passport_id",res.data.data.passport_id)
           setFieldValue("passport_id_file",res.data.data.passport_file)
           setFieldValue("work_profile",GetWorkProfile(res.data.data.work_profile))
           setFieldValue("company_name",res.data.data.cname)
           setFieldValue("billing_company_name",res.data.data.company_name_official)
           setFieldValue("address1",res.data.data.address)
           setFieldValue("address2",res.data.data.secaddress)
           setFieldValue("address3",res.data.data.addressline3)
           setFieldValue("city",res.data.data.city)
           setFieldValue("state",res.data.data.state)
           setFieldValue("zipcode",res.data.data.com_zip)
           setFieldValue("country",res.data.data.country)
           setFieldValue("telephone",res.data.data.contact2)
           setFieldValue("fax",res.data.data.fax)
           setFieldValue("invoice_email",res.data.data.invoiceemail)
           setFieldValue("website",res.data.data.website)
           setFieldValue("comp_reg_no",res.data.data.com_reg_no)
           setFieldValue("comp_reg_no_file",res.data.data.com_reg_doc)
           setFieldValue("nature_of_business",res.data.data.nature_buss)
           setFieldValue("business_est_date",res.data.data.buss_esta_date)
           setFieldValue("name_of_partner",res.data.data.director_name)
           setFieldValue("contact_of_partner",res.data.data.directory_contact)
          setFieldValue("atten_name",res.data.data.name_atten)
          setFieldValue("shipping_address1",res.data.data.shipping_address_1)
          setFieldValue("shipping_address2",res.data.data.shipping_address_2)
          setFieldValue("shipping_address3",res.data.data.shipping_address_3)
          setFieldValue("shipping_city",res.data.data.shipping_city)
          setFieldValue("shipping_phone",res.data.data.shipping_phone)
          setFieldValue("shipping_state",res.data.data.shipping_state)
          setFieldValue("shipping_zipcode",res.data.data.shipping_zip_code)
          setFieldValue("port_of_discharge",res.data.data.port_of_discharge)
          setFieldValue("shipping_state",res.data.data.shipping_state)
          setFieldValue("shipping_zipcode",res.data.data.shipping_zip_code)
          setFieldValue("shipping_country",res.data.data.country)
          setFieldValue("shipping_telephone",res.data.data.shipping_phone)
          setFieldValue("shipping_email",res.data.data.shipping_email)
          setFieldValue("tax_id",res.data.data.company_tax)
          setFieldValue("shipping_country",res.data.data.shipping_country)
          setFieldValue("fedex_ac_no",res.data.data.fedex_ac_no)
        }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
      }
    }
  },[])
  return (
    <React.Fragment>
      <Typography className="mb-10" variant="h4" align="center">
        {props.language.PROFILEVIEW_USER_INFORMATION}
      </Typography>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={7}>
          <InputField name={firstName.name} label={props.language.PROFILE_FIRST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={lastName.name} label={props.language.PROFILE_LAST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={email.name} label={props.language.PROFILE_EMAIL} fullWidth disabled={true}/>
        </Grid>
        <Grid item xs={12} sm={7}>
        {/* <InputLabel className="font-size-h6 font-weight-bold">
            {props.language.PROFILE_CONTACT_NUMBER}
          </InputLabel> */}
          <PhoneInput name={contanct_no.name} label={props.language.PROFILE_CONTACT_NUMBER} fullWidth onChange={(value) => setFieldValue("contanct_no",value)}/>
          
        </Grid>
        <Grid item xs={12} sm={7}>
          <SelectField
            name={work_profile.name}
            label={props.language.PROFILE_WORK_PROFILE}
            placeholder={props.language.PROFILE_WORK_PROFILE}
            data={work_profile_data}
            fullWidth
          />
           {/* <MuiUploader /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
