import React, {  useState } from "react";
// import {
//     Modal,
//     Button,
// } from "react-bootstrap";
import { IoVideocamOutline } from "react-icons/io5";
import VideoIframe from "./VideoIframe";
export const VideoPopup = (props) => {
    const [show, setShow] = useState(false);
   
    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        // console.log(props.WLDiamondType,"props.WLDiamondType")
        if (event.ctrlKey || event.metaKey) {
            window.open(props.WLDiamondType === "N" || props.WLDiamondType === "W" ? `https://pro360video.com/video.php?refno=${props.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${props.Certi_No}`, '_blank')
          } else {
            setShow(true)
          }
    };

    return (
        <>
            <span className="font-size-h5"><IoVideocamOutline className="cursor-pointer text-primary mx-2 text-center"  onClick={(e) => handleShow(e)}/></span>
            {/* <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <iframe title="Video" src={props.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${props.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${props.Certi_No}` } height='450px' width='100%' frameborder='0'></iframe>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <VideoIframe
            showVideo={show}
            handleClose={handleClose}
            src={props.WLDiamondType === "N" || props.WLDiamondType === "W" ? `https://pro360video.com/video.php?refno=${props.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${props.Certi_No}` }            
            // src={props.src || ""}
            data={props.data}
            />
        </>
    );
};
