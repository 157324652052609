import React, { useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = ({language,formik,shapeData}) => {
    // console.log(shapeData)
    // const [shape] = useState( ShapeData.length !== 0 && ShapeData !== null ? ShapeData : [] );
    
    // const newShapeData =shape.length!==0?shape.split(',').map((value,index) => {
    //     return {
    //         id: index,
    //         value,
    //         multilingualshape:language[`${value.toUpperCase()}`]
    //     }
    // }):null
    // const ShapeData = ([
    //     {
    //         id: 1,
    //         shape:"round",
    //         value:"ROUND",
    //         checked:true
    //     }
    // ]);
    React.useEffect(() => {
        formik.setFieldValue("shape", "ROUND");
        // formik.setFieldValue("minSize", minSize);
        // formik.setFieldValue("maxSieve", maxSize);
        // console.log("123");
    }, []);
    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-12">
                    <p className="font-size-lg font-weight-bold mb-0">{language.SHAPE_SHAPE}</p>
                </div>
                <div className="col-lg-12 flex-wrap mt-3 d-flex">
                    {shapeData.length !==0 ? shapeData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="radio" name="shape" id={`${item.shape}_shape`} value={item.value}  checked={item.shape?.toLowerCase() === "round"}/>
                            {/* <span></span> */}
                            <div className="shapeBox selectBox">
                                <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${(item.shape).toLowerCase()}.svg`)} />
                                <br/>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" >{item.shape}</Tooltip>} >
                                    <span className="text-uppercase text-truncate">{item.shape}</span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
};
