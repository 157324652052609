import React, { useState, useEffect } from "react";
import { IoCloseOutline, IoShareOutline, IoVideocamOutline } from "react-icons/io5";
import { Modal, OverlayTrigger,Tooltip } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { ImageSharePopup } from "./ImageSharePopup";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
export const VideoNewPage = ({userinfo,language}) => {
    const location = useLocation();
    const history = useHistory()
    const [show, setShow] = useState(false);
    const [data] = useState(location.state || null)
    const handleClose = () => setShow(false);
 
console.log(location.state)
    const [frameloaded, setFrameLoaded] = useState(false);
    const [showimage, setShowImage] = useState(false);
    const [shareurl, setShareURL] = useState("");

    const FrameFunction = () => {
        setFrameLoaded(true);
    };

    const copyurl = (diamonddata) => {
        if (diamonddata.ext_status === "Success") {
            axios({
                method: "POST",
                url: `https://api.dia360.cloud/api/admin/revert-public-url`,
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": "26eca0a8-1981-11ee-be56-0242ac120002",
                },
                data: {
                    ext_video_id: diamonddata.ext_video_id.toString(),
                    client_id: userinfo.id.toString(),
                },
            }).then(function(response) {
                if (response && response.data && response.data.url) {
                    setShareURL(response.data.url);
                    navigator.clipboard.writeText(response.data.url);
                    setShowImage(true);
                }
            });
        } else if (diamonddata.video) {
            const videoUrl =
                data && data.diamond_type === "N" || data && data.diamond_type === "W"
                    ? `https://pro360video.com/video.php?refno=${data && data.Certi_NO}`
                    : `https://pro360video.com/labgrown.php?refno=${data && data.Certi_NO}`;
            setShareURL(videoUrl);
            navigator.clipboard.writeText(videoUrl);
            setShowImage(true);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && show) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show]);

    const GetVideoUrl = (data) => {
        if (data.ext_status === "Success") {
            return data.m_video_path;
        } else if (data.video) {
            return data && data.diamond_type === "N" || data && data.diamond_type === "W"
                ? `https://pro360video.com/video.php?refno=${data && data.Certi_NO}`
                : `https://pro360video.com/labgrown.php?refno=${data && data.Certi_NO}`;
        }
        return "";
    };

    return (
        <>
            {GetVideoUrl(data && data) ? (
                <div>
                    <ImageSharePopup
                        show={showimage}
                        onClose={() => setShowImage(false)}
                        src={shareurl}
                    />
                    <div className="card card-custom bg-white">
                       
                        {/* <Modal.Header closeButton className="modal-header-custom">
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Share </Tooltip> } >
                                <IoShareOutline  className="modal-icon" onClick={() => copyurl(data && data)} />
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Close </Tooltip> } >
                                <IoCloseOutline  className="modal-icon" onClick={handleClose} />
                            </OverlayTrigger>
                        </Modal.Header> */}
                        <div className="custom-scrollbar text-center card-body"style={{height:"75vh"}}>
                            {!frameloaded && (
                                <div className="loader-container">
                                    <CircularProgress />
                                    <h6>Please Wait...</h6>
                                </div>
                            )}
                            {GetVideoUrl(data && data) && (
                                <>
                                    <div className="mb-5">
                                        <span className="text-dark font-size-h4 font-weight-bold d-block">
                                            {data.C_Shape}{" "}
                                            {data.C_Weight.toFixed(2)}{" "}
                                            {data.C_Color ===
                                            "fancy" ? (
                                                <>
                                                    {data.f_intensity}{" "}
                                                    {data.f_overtone}{" "}
                                                    {data.f_color}{" "}
                                                </>
                                            ) : (
                                                <>
                                                    {data.C_Color}{" "}
                                                </>
                                            )}
                                            {data.C_Clarity}{" "}
                                            {data.C_Cut}{" "}
                                            {data.C_Polish}{" "}
                                            {data.C_Symmetry}{" "}
                                            {data.C_Fluorescence}{" "}
                                        </span>
                                    </div>
                                    <iframe
                                            id="iframedata"
                                            title="Video"
                                            src={GetVideoUrl(data && data)}
                                            width="100%"
                                            height="100%"
                                            frameBorder="0"
                                            onLoad={FrameFunction}
                                            style={{ display: frameloaded ? "block" : "none" ,height:"90%",width:"40%", margin:"auto"}}
                                        ></iframe>
                                </>
                            )}
                        </div>
                        <div className="card-footer text-right p-5">
                            <button className="btn btn-primary" onClick={() => copyurl(data && data)}>share</button>
                            <button className="btn btn-secondary ml-2" onClick={() => history.goBack()}>Close</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="bg-white" style={{ height: "60vh" }}>
                    No video found
                </div>
            )}
        </>
    );
};
