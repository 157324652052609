/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    rulename,

    naturaldiamond,
    naturalfancydiamond,
    lgwhitediam,
    lgfancydiam,
    diamondshape,
    carat_from,
    carat_to,
    diamondcolor,
    diamondfancy_color,
    diamondfancy_intensity,
    diamondfancy_overtone,
    diamondclarity,
    lab_val,
    cuts,
    diamondflour,
    polishes,
    milky,
    eyeclean,
    symmetries,
    diamondshade,
    price_from,
    price_to,
    total_price_from,
    total_price_to,
    media,

    lab_shape,
    lab_carat_from,
    lab_carat_to,
    lab_color,
    lab_fancy_color,
    lab_fancy_intensity,
    lab_fancy_overtone,
    lab_clarity,
    lab_lab,
    lab_cuts,
    lab_polishes,
    lab_symmetries,
    lab_flourselect,
    lab_shadeselect,
    lab_milky,
    lab_eyeclean,
    lab_min_dollarperct,
    lab_max_dollarperct,
    lab_total_price_from,
    lab_total_price_to,
    lab_all_media,

    primarycolor,
    secondary,
    fontcolor,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    website_url,
    inquiry_email,
    inquiry_cc_email,
    markupvalue,
    markupoption,
    suppliers,
    caratpricemarkups
  }
} = kycFormModel;

const numbersonly =/^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/
const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
const range = /^(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)$/
const pricerange = /^(100599999\.99|\d{1,8}(\.\d{1,2})?)$/
// export default [
//   Yup.object().shape({
//     [rulename.name]: Yup.string().required(`${rulename.requiredErrorMsg}`),
//     // [naturalfancydiamond.name]: Yup.string(),
//     // [lgwhitediam.name]: Yup.string(),
//     // [lgfancydiam.name]: Yup.string(),
//   }), 
//   Yup.object().shape({
//     [naturaldiamond.name]: Yup.string(),
//     [naturalfancydiamond.name]: Yup.string(),
//     [lgwhitediam.name]: Yup.string(),
//     [lgfancydiam.name]: Yup.string(),
//   }) .test(
//     'naturaldiamond',
//     null,
//     (obj) => {
//       console.log(obj,"OBJJSJSJSSJ")
//       if ( obj.naturaldiamond || obj.naturalfancydiamond || obj.lgwhitediam || obj.lgfancydiam ) {
//         return true; // everything is fine
//       }
//       const keys = ['naturaldiamond', 'naturalfancydiamond','lgwhitediam','lgfancydiam']

//       const errors = keys.map((key) => {
  
//           return new Yup.ValidationError(
//             `${key} is empty`,
//             null,
//             key
//           )
//       }).filter(Boolean)
//       console.log(errors,"ValidationError")
//       console.log(obj.naturaldiamond,"naturaldiamond")
//       console.log(obj.naturalfancydiamond,"naturalfancydiamond")
//       console.log(obj.lgwhitediam,"lgwhitediam")
//       console.log(obj.lgfancydiam,"lgfancydiam")
//       return new Yup.ValidationError(errors);
//     }
//   ),
//   Yup.object().shape({
//     // [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
//     // [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
//     // [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
//     // // [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
//     // // [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
//     // // [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
//     // // [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
//     // [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
//     // [lab_val.name]: Yup.array().min(1,`${lab_val.requiredErrorMsg}`).required(`${lab_val.requiredErrorMsg}`),
//     // // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
//     // [symmetries.name]: Yup.array().min(1,`${symmetries.requiredErrorMsg}`).required(`${symmetries.requiredErrorMsg}`),
//     // [diamondflour.name]: Yup.array().min(1,`${diamondflour.requiredErrorMsg}`).required(`${diamondflour.requiredErrorMsg}`),
//     // [polishes.name]: Yup.array().min(1,`${polishes.requiredErrorMsg}`).required(`${polishes.requiredErrorMsg}`),
//     // // [milky.name]: Yup.array().min(1,`${milky.requiredErrorMsg}`).required(`${milky.requiredErrorMsg}`),
//     // // [eyeclean.name]: Yup.array().min(1,`${eyeclean.requiredErrorMsg}`).required(`${eyeclean.requiredErrorMsg}`),
//     // // [diamondshade.name]: Yup.array().min(1,`${diamondshade.requiredErrorMsg}`).required(`${diamondshade.requiredErrorMsg}`),
//     // [price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [total_price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [total_price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
//     // [media.name]: Yup.string().required(`${media.requiredErrorMsg}`),  


//   }),
//   Yup.object().shape({
//     // [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
//     // [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
//     // [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
//     // // [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
//     // // [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
//     // // [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
//     // // [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
//     // [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
//     // [lab_lab.name]: Yup.array().min(1,`${lab_lab.requiredErrorMsg}`).required(`${lab_lab.requiredErrorMsg}`),
//     // // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
//     // [lab_polishes.name]: Yup.array().min(1,`${lab_polishes.requiredErrorMsg}`).required(`${lab_polishes.requiredErrorMsg}`),
//     // [lab_symmetries.name]: Yup.array().min(1,`${lab_symmetries.requiredErrorMsg}`).required(`${lab_symmetries.requiredErrorMsg}`),
//     // [lab_flourselect.name]: Yup.array().min(1,`${lab_flourselect.requiredErrorMsg}`).required(`${lab_flourselect.requiredErrorMsg}`),
//     // // [lab_shadeselect.name]: Yup.array().min(1,`${lab_shadeselect.requiredErrorMsg}`).required(`${lab_shadeselect.requiredErrorMsg}`),
//     // // [lab_milky.name]: Yup.array().min(1,`${lab_milky.requiredErrorMsg}`).required(`${lab_milky.requiredErrorMsg}`),
//     // // [lab_eyeclean.name]: Yup.array().min(1,`${lab_eyeclean.requiredErrorMsg}`).required(`${lab_eyeclean.requiredErrorMsg}`),
//     // [lab_min_dollarperct.name]: Yup.string().required(`${lab_min_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_max_dollarperct.name]: Yup.string().required(`${lab_max_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
//     // [lab_all_media.name]: Yup.string().required(`${lab_all_media.requiredErrorMsg}`),  


//   }),
//   Yup.object().shape({
//     // [markupvalue.name]: Yup.string().required(`${markupvalue.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     [markupoption.name]: Yup.string().required(`${markupoption.requiredErrorMsg}`),
//     // [suppliers.name]: Yup.array().min(1,`${suppliers.requiredErrorMsg}`).required(`${suppliers.requiredErrorMsg}`)
//   }),
// ];

export default function ValidationSchema(values,customerapi,allrules){
  let validationarray = [
    Yup.object().shape({
      [rulename.name]: Yup.string().required(`${rulename.requiredErrorMsg}`),
      // [naturalfancydiamond.name]: Yup.string(),
      // [lgwhitediam.name]: Yup.string(),
      // [lgfancydiam.name]: Yup.string(),
    }).test(
          'rulename',
          null,
          (obj) => {
            const ValidateRule = (string,rules) => {
              let ruleexist = false
              for(let i = 0; i < rules.length; i++){
                console.log(rules[i],"rules[i].rulename")
                if(rules[i].rule_name === string){
                  ruleexist = true
                }
              }
              return ruleexist
            }
            const validaterule = ValidateRule(obj.rulename,allrules)
            console.log(validaterule,"validaterule")
            if(validaterule){
              return new Yup.ValidationError("Rule Name Already Exists!",true,"rulename");
            }
          }
        ), 
    Yup.object().shape({
      [naturaldiamond.name]: Yup.string(),
      [naturalfancydiamond.name]: Yup.string(),
      [lgwhitediam.name]: Yup.string(),
      [lgfancydiam.name]: Yup.string(),
    }) .test(
      'naturaldiamond',
      null,
      (obj) => {
        console.log(obj,"OBJJSJSJSSJ")
        if ( obj.naturaldiamond || obj.naturalfancydiamond || obj.lgwhitediam || obj.lgfancydiam ) {
          return true; // everything is fine
        }
        const keys = ['naturaldiamond', 'naturalfancydiamond','lgwhitediam','lgfancydiam']
  
        const errors = keys.map((key) => {
    
            return new Yup.ValidationError(
              `${key} is empty`,
              null,
              key
            )
        }).filter(Boolean)
        console.log(errors,"ValidationError")
        console.log(obj.naturaldiamond,"naturaldiamond")
        console.log(obj.naturalfancydiamond,"naturalfancydiamond")
        console.log(obj.lgwhitediam,"lgwhitediam")
        console.log(obj.lgfancydiam,"lgfancydiam")
        return new Yup.ValidationError(errors);
      }
    ),
    Yup.object().shape({
      // [markupvalue.name]: Yup.string().required(`${markupvalue.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      [markupoption.name]: Yup.string().required(`${markupoption.requiredErrorMsg}`),
      [caratpricemarkups.name]: Yup.array().min(1,`${caratpricemarkups.requiredErrorMsg}`).required(`${caratpricemarkups.requiredErrorMsg}`)
    }),
    Yup.object().shape({
      // [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      // [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
      // [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
      // // [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
      // // [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
      // // [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
      // // [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
      // [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_lab.name]: Yup.array().min(1,`${lab_lab.requiredErrorMsg}`).required(`${lab_lab.requiredErrorMsg}`),
      // // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      // [lab_polishes.name]: Yup.array().min(1,`${lab_polishes.requiredErrorMsg}`).required(`${lab_polishes.requiredErrorMsg}`),
      // [lab_symmetries.name]: Yup.array().min(1,`${lab_symmetries.requiredErrorMsg}`).required(`${lab_symmetries.requiredErrorMsg}`),
      // [lab_flourselect.name]: Yup.array().min(1,`${lab_flourselect.requiredErrorMsg}`).required(`${lab_flourselect.requiredErrorMsg}`),
      // // [lab_shadeselect.name]: Yup.array().min(1,`${lab_shadeselect.requiredErrorMsg}`).required(`${lab_shadeselect.requiredErrorMsg}`),
      // // [lab_milky.name]: Yup.array().min(1,`${lab_milky.requiredErrorMsg}`).required(`${lab_milky.requiredErrorMsg}`),
      // // [lab_eyeclean.name]: Yup.array().min(1,`${lab_eyeclean.requiredErrorMsg}`).required(`${lab_eyeclean.requiredErrorMsg}`),
      // [lab_min_dollarperct.name]: Yup.string().required(`${lab_min_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_max_dollarperct.name]: Yup.string().required(`${lab_max_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
      // [lab_all_media.name]: Yup.string().required(`${lab_all_media.requiredErrorMsg}`),  
  
  
    }),
  ];
  let diamondparamsvalidation = Yup.object().shape({
    // [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
    // [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
    // [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100'),
    // // [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
    // // [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
    // // [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
    // // [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
    // [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
    // [lab_val.name]: Yup.array().min(1,`${lab_val.requiredErrorMsg}`).required(`${lab_val.requiredErrorMsg}`),
    // // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
    // [symmetries.name]: Yup.array().min(1,`${symmetries.requiredErrorMsg}`).required(`${symmetries.requiredErrorMsg}`),
    // [diamondflour.name]: Yup.array().min(1,`${diamondflour.requiredErrorMsg}`).required(`${diamondflour.requiredErrorMsg}`),
    // [polishes.name]: Yup.array().min(1,`${polishes.requiredErrorMsg}`).required(`${polishes.requiredErrorMsg}`),
    // // [milky.name]: Yup.array().min(1,`${milky.requiredErrorMsg}`).required(`${milky.requiredErrorMsg}`),
    // // [eyeclean.name]: Yup.array().min(1,`${eyeclean.requiredErrorMsg}`).required(`${eyeclean.requiredErrorMsg}`),
    // // [diamondshade.name]: Yup.array().min(1,`${diamondshade.requiredErrorMsg}`).required(`${diamondshade.requiredErrorMsg}`),
    // [price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [total_price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [total_price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
    // [media.name]: Yup.string().required(`${media.requiredErrorMsg}`),  


  })
  if(values.naturaldiamond){
    console.log(customerapi,"customerapicustomerapi")
    // customerapi = customerapi.filter(val => val.naturaldiamond && val.naturaldiamond.toString() === values.naturaldiamond.toString())
    diamondparamsvalidation = Yup.object().shape({
      [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
      [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
      [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
      // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
      [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
      [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
      [total_price_from.name]: Yup.string().required(`${total_price_from.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
      [total_price_to.name]: Yup.string().required(`${total_price_to.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
    }).test(
      'overlap',
      null,
      (obj) => {
        if(obj.perctinclusive){
          if(!obj.price_from){
            console.log("Here is validation error")
            return new Yup.ValidationError(price_from.requiredErrorMsg,true,"price_from");
          }
          if(!obj.price_to){
            return new Yup.ValidationError(price_to.requiredErrorMsg,true,"price_to");
          }
          if(!pricerange.test(obj.total_price_from)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"price_from");
          }
          if(!pricerange.test(obj.total_price_to)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"price_to");
          }
        }
        if(parseFloat(obj.carat_from) >= parseFloat(obj.carat_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"carat_from");
        }
        if(parseFloat(obj.total_price_from) >= parseFloat(obj.total_price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"total_price_from");
        }
        if(parseFloat(obj.price_from) >= parseFloat(obj.price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"price_from");
        }
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["color"] = obj.diamondfancy_color.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab"] = obj.lab_lab.toString()
          }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["color"] = obj.lab_fancy_color.toString()
            }
          }
        }
        function checkOverlap(rule1, rule2) {
          // Helper function to check if two ranges overlap
          function rangeOverlap(min1, max1, min2, max2) {
              // Ranges overlap if the start of one is less than or equal to the end of the other
              return !(min1 > max2 || max1 < min2);
          }
      
          // Compare shapes - overlap if any shape matches between rule1 and rule2
          const shapeOverlap = rule1.shape.some(shape => rule2.shape.includes(shape));
          
          let rangecaratoverlap = false
          // Compare carat ranges (min_carat and max_carat)
          if (rangeOverlap(rule1.min_carat, rule1.max_carat, rule2.min_carat, rule2.max_carat)) {
              rangecaratoverlap = true; // Carat ranges overlap
          }
      
          // Compare carat (color) - assuming this field is carat (not color)
          const colorOverlap = rule1.color.some(color => rule2.color.includes(color));
      
          // Compare clarity
          const clarityOverlap = rule1.clarity.some(clarity => rule2.clarity.includes(clarity));
      
          // Compare lab
          const labOverlap = rule1.lab.some(lab => rule2.lab.includes(lab));
      
          return shapeOverlap &&  rangecaratoverlap && colorOverlap && clarityOverlap && labOverlap
      }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = checkOverlap(customerexisting,customercurrent)
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.naturalfancydiamond){
    // customerapi = customerapi.filter(val => val.naturalfancydiamond && val.naturalfancydiamond.toString() === values.naturalfancydiamond.toString())
    diamondparamsvalidation = Yup.object().shape({
      [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
      [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
    [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
    // [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
      [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
      // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
      [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [total_price_from.name]: Yup.string().required(`${total_price_from.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
      [total_price_to.name]: Yup.string().required(`${total_price_to.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
    }).test(
      'overlap',
      null,
      (obj) => {
        if(obj.perctinclusive){
          if(!obj.price_from){
            console.log("Here is validation error")
            return new Yup.ValidationError(price_from.requiredErrorMsg,true,"price_from");
          }
          if(!obj.price_to){
            return new Yup.ValidationError(price_to.requiredErrorMsg,true,"price_to");
          }
          if(!pricerange.test(obj.total_price_from)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"price_from");
          }
          if(!pricerange.test(obj.total_price_to)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"price_to");
          }
        }
        if(parseFloat(obj.carat_from) >= parseFloat(obj.carat_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"carat_from");
        }
        if(parseFloat(obj.total_price_from) >= parseFloat(obj.total_price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"total_price_from");
        }
        if(parseFloat(obj.price_from) >= parseFloat(obj.price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"price_from");
        }
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["color"] = obj.diamondfancy_color.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab"] = obj.lab_lab.toString()
          }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["color"] = obj.lab_fancy_color.toString()
            }
          }
        }
        function checkOverlap(rule1, rule2) {
          // Helper function to check if two ranges overlap
          function rangeOverlap(min1, max1, min2, max2) {
              // Ranges overlap if the start of one is less than or equal to the end of the other
              return !(min1 > max2 || max1 < min2);
          }
      
          // Compare shapes - overlap if any shape matches between rule1 and rule2
          const shapeOverlap = rule1.shape.some(shape => rule2.shape.includes(shape));
          
          let rangecaratoverlap = false
          // Compare carat ranges (min_carat and max_carat)
          if (rangeOverlap(rule1.min_carat, rule1.max_carat, rule2.min_carat, rule2.max_carat)) {
              rangecaratoverlap = true; // Carat ranges overlap
          }
      
          // Compare carat (color) - assuming this field is carat (not color)
          const colorOverlap = rule1.color.some(color => rule2.color.includes(color));
      
          // Compare clarity
          const clarityOverlap = rule1.clarity.some(clarity => rule2.clarity.includes(clarity));
      
          // Compare lab
          const labOverlap = rule1.lab.some(lab => rule2.lab.includes(lab));
      
          return shapeOverlap &&  rangecaratoverlap && colorOverlap && clarityOverlap && labOverlap
      }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = checkOverlap(customerexisting,customercurrent)
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.lgwhitediam){
    // customerapi = customerapi.filter(val => val.labdiamond && val.labdiamond.toString() === values.lgwhitediam.toString())
    diamondparamsvalidation = Yup.object().shape({
      [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
      [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
      [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
    }).test(
      'overlap',
      null,
      (obj) => {
        if(obj.perctinclusive){
          if(!obj.lab_min_dollarperct){
            console.log("Here is validation error")
            return new Yup.ValidationError(lab_min_dollarperct.requiredErrorMsg,true,"lab_min_dollarperct");
          }
          if(!obj.lab_max_dollarperct){
            return new Yup.ValidationError(lab_max_dollarperct.requiredErrorMsg,true,"lab_max_dollarperct");
          }
          if(!pricerange.test(obj.lab_total_price_from)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"lab_min_dollarperct");
          }
          if(!pricerange.test(obj.lab_total_price_to)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"lab_max_dollarperct");
          }
        }
        if(parseFloat(obj.lab_carat_from) >= parseFloat(obj.lab_carat_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_carat_from");
        }
        if(parseFloat(obj.lab_total_price_from) >= parseFloat(obj.lab_total_price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_total_price_from");
        }
        if(parseFloat(obj.lab_min_dollarperct) >= parseFloat(obj.lab_max_dollarperct)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_min_dollarperct");
        }
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["color"] = obj.diamondfancy_color.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab"] = obj.lab_lab.toString()
          }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["color"] = obj.lab_fancy_color.toString()
            }
          }
        }
        function checkOverlap(rule1, rule2) {
          // Helper function to check if two ranges overlap
          function rangeOverlap(min1, max1, min2, max2) {
              // Ranges overlap if the start of one is less than or equal to the end of the other
              return !(min1 > max2 || max1 < min2);
          }
      
          // Compare shapes - overlap if any shape matches between rule1 and rule2
          const shapeOverlap = rule1.shape.some(shape => rule2.shape.includes(shape));
          
          let rangecaratoverlap = false
          // Compare carat ranges (min_carat and max_carat)
          if (rangeOverlap(rule1.min_carat, rule1.max_carat, rule2.min_carat, rule2.max_carat)) {
              rangecaratoverlap = true; // Carat ranges overlap
          }
      
          // Compare carat (color) - assuming this field is carat (not color)
          const colorOverlap = rule1.color.some(color => rule2.color.includes(color));
      
          // Compare clarity
          const clarityOverlap = rule1.clarity.some(clarity => rule2.clarity.includes(clarity));
      
          // Compare lab
          const labOverlap = rule1.lab.some(lab => rule2.lab.includes(lab));
      
          return shapeOverlap &&  rangecaratoverlap && colorOverlap && clarityOverlap && labOverlap
      }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = checkOverlap(customerexisting,customercurrent)
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.lgfancydiam){
    // customerapi = customerapi.filter(val => val.labfancydiamond && val.labfancydiamond.toString() === values.lgfancydiam.toString())
    diamondparamsvalidation = Yup.object().shape({
      [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
      [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
      // [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
      [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 100').nullable(),
    [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
      [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(pricerange, 'Please reduce your price range'),
    }).test(
      'overlap',
      null,
      (obj) => {
        if(obj.perctinclusive){
          if(!obj.lab_min_dollarperct){
            console.log("Here is validation error")
            return new Yup.ValidationError(lab_min_dollarperct.requiredErrorMsg,true,"lab_min_dollarperct");
          }
          if(!obj.lab_max_dollarperct){
            return new Yup.ValidationError(lab_max_dollarperct.requiredErrorMsg,true,"lab_max_dollarperct");
          }
          if(!pricerange.test(obj.lab_total_price_from)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"lab_min_dollarperct");
          }
          if(!pricerange.test(obj.lab_total_price_to)){
            return new Yup.ValidationError(`Please reduce your price range`,true,"lab_max_dollarperct");
          }
        }
        if(parseFloat(obj.lab_carat_from) >= parseFloat(obj.lab_carat_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_carat_from");
        }
        if(parseFloat(obj.lab_total_price_from) >= parseFloat(obj.lab_total_price_to)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_total_price_from");
        }
        if(parseFloat(obj.lab_min_dollarperct) >= parseFloat(obj.lab_max_dollarperct)){
          return new Yup.ValidationError(`Min Range Should be Less than Max Range`,true,"lab_min_dollarperct");
        }
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["color"] = obj.diamondfancy_color.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab"] = obj.lab_lab.toString()
          }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["color"] = obj.lab_fancy_color.toString()
            }
          }
        }
        function checkOverlap(rule1, rule2) {
          // Helper function to check if two ranges overlap
          function rangeOverlap(min1, max1, min2, max2) {
              // Ranges overlap if the start of one is less than or equal to the end of the other
              return !(min1 > max2 || max1 < min2);
          }
      
          // Compare shapes - overlap if any shape matches between rule1 and rule2
          const shapeOverlap = rule1.shape.some(shape => rule2.shape.includes(shape));
          
          let rangecaratoverlap = false
          // Compare carat ranges (min_carat and max_carat)
          if (rangeOverlap(rule1.min_carat, rule1.max_carat, rule2.min_carat, rule2.max_carat)) {
              rangecaratoverlap = true; // Carat ranges overlap
          }
      
          // Compare carat (color) - assuming this field is carat (not color)
          const colorOverlap = rule1.color.some(color => rule2.color.includes(color));
      
          // Compare clarity
          const clarityOverlap = rule1.clarity.some(clarity => rule2.clarity.includes(clarity));
      
          // Compare lab
          const labOverlap = rule1.lab.some(lab => rule2.lab.includes(lab));
      
          return shapeOverlap &&  rangecaratoverlap && colorOverlap && clarityOverlap && labOverlap
      }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = checkOverlap(customerexisting,customercurrent)
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  validationarray.splice(2,0,diamondparamsvalidation)
  console.log(validationarray,"validationarray")
  console.log(validationarray.length,"validationarray.length")
  return validationarray;
};