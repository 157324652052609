import React, { useEffect } from "react";
import {
    IncompleteProfileTile,
    ItemsInCartTile,
    TotalOrderPlaceTiles,
    TotalInvoiceTile,
    DiamondRequestTile,
    DashboardSearchTab,
    DashboardTracklist,
    RecentDiamondTile,
    SavedSearchtile,
    SalesExecutiveTile,
} from "../widgets";
import { Alert } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
// import { GetCookies } from "../../_helpers/Cookies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { FETCH_DASHBOARD_DATA,NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
import DashboardSkeleton from "./DashboardSkeleton"
import { Searchbarchurned } from "../../layout/components/extras/dropdowns/search/SearchbarChurned";
import { SearchBarTile } from "../widgets/tiles/SearchBarTile";
import { TotalOrderPlaceTileChurned } from "../widgets/tiles/TotalOrderPlaceTileChurned";
import { TotalInvoiceChurnedTile } from "../widgets/tiles/TotalInvoiceChurnedTile";
export const DashboardChurned = ({
    conversionrate,
    symbol,
    language,
    isprofilecomplete,
    userinfo,
}) => {
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    // console.log("userinfo",userinfo)
    const [dashData, setDashData] = React.useState([]);
    const [salesData, setSalesData] = React.useState([]);
    const [convinienceFee, setConvinienceFee] = React.useState([]);

    useEffect(() => {
        let isMounted = true;
        const userCountry = {
            country: `${userinfo.country}`,
        };
        axios({
            method: "GET",
            url: `${NODE_API_URL}/INTL/fetchDashboardData`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
             }
        }).then((res) => {
            if (res && res.data && res.data.data) {
                setDashData(res.data.data);
                setSalesData(res.data.data.salesExecutive);
                // console.log("data", res);
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        axios({
            method: "GET",
            url: `${NODE_API_URL}/INTL/get-conviniencefee`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params: userCountry,
        }).then((res) => {
            if (res && res.data) {
                setConvinienceFee(res.data.data);
                // console.log("data", res);
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        return () => {
            isMounted = false;
        };
    }, [user.CustomerId,userinfo.country]);

    return (
        <> {userinfo.id ?
            <>
                
                <div className="row m-0 mb-3 rounded p-4 shadow-sm bg-white">
                    <div className="col-12 justify-content-md-between d-flex align-items-center">
                        <h1 className="text-capitalize text-primary mb-0">
                            Welcome {userinfo ? userinfo.cname : ""}{" "}
                            <SVG
                                src={toAbsoluteUrl("/media/General/ic_fest.svg")}
                            />
                        </h1>
                    </div>
                    {user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                        <div className="col-12 mt-2">
                            <Alert variant="danger">
                                Your account is under Lite Mode, to get further
                                access please contact your sales representative.
                                Thank you
                            </Alert>
                        </div>
                    ) : (
                        ""
                    )}
                    {userinfo.kyc_status === "KYC_PEND" ? (
                        <div className="col-12 mt-2">
                            <Alert variant="warning">
                                Your updated KYC is under review, please wait for
                                approval.
                            </Alert>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="row">
                    {userinfo.kyc_status === "NO_KYC" ? (
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-12">
                                    <IncompleteProfileTile />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
    
                    <div
                        className={`${
                            userinfo.kyc_status === "NO_KYC"
                                ? "col-md-8 profileIncompleteDashboard"
                                : "col-md-12 profileIncompleteDashboard"
                        }`}
                    >
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <TotalOrderPlaceTileChurned
                                    totalOrderCount={
                                        dashData && dashData.totalOrders
                                    }
                                    language={language}
                                    userinfo={userinfo}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <TotalInvoiceChurnedTile
                                    totalInvoiceCount={
                                        dashData && dashData.totalInvoices
                                    }
                                    language={language}
                                    userinfo={userinfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {userinfo.kyc_status === "KYC_APPR" || userinfo.kyc_status === "KYC_EDITED"?<div className="col-md-4">
                        <div className="row">
                        {/* <div className="col-12">
                        {userinfo.v_customer === "12"?<Searchbarchurned  language={language} userinfo={userinfo}/>:""}
                            </div> */}
                            <div className="col-12">
                                <SearchBarTile
                                    language={language}
                                    userinfo={userinfo}
                                    salesData={salesData && salesData}
                                />
                            </div>
                        </div>
                    </div>:""}
                    <div className="col-md-4">
                        <div className="row">
                        {/* <div className="col-12">
                        {userinfo.v_customer === "12"?<Searchbarchurned  language={language} userinfo={userinfo}/>:""}
                            </div> */}
                            <div className="col-12">
                                <SalesExecutiveTile
                                    language={language}
                                    salesData={salesData && salesData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </> : <DashboardSkeleton /> }
        </> 
    );
};
